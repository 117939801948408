/**
 * Filkhedma API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface ContactUsRequest { 
    /**
     * Enquiry Type
     */
    enquiryType?: ContactUsRequest.EnquiryTypeEnum;
    /**
     * Name
     */
    name?: string;
    /**
     * Mobile
     */
    mobile?: string;
    /**
     * Contact Us Message
     */
    msg?: string;
}
export namespace ContactUsRequest {
    export type EnquiryTypeEnum = 'enquiry' | 'complaint' | 'suggestion';
    export const EnquiryTypeEnum = {
        Enquiry: 'enquiry' as EnquiryTypeEnum,
        Complaint: 'complaint' as EnquiryTypeEnum,
        Suggestion: 'suggestion' as EnquiryTypeEnum
    };
}
