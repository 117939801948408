/**
 * holds all application routes that could be replaced in future without cracking
 * the app
 */
export let routeConfig = {
  'home': {
    'name': 'home/',
    'route': '/home/',
  },
  'about_us': {
    'name': 'aboutus',
    'route': '/aboutus'

  },
  'contact_us': {
    'name': 'contactus',
    'route': '/contactus'
  },
  'help': {
    'name': 'help',
    'route': '/help'
  },
  'terms': {
    'name': 'terms',
    'route': '/terms'
  },
  'prices': {
    'name': 'prices',
    'route': '/prices'
  },
  'testomonials': {
    'name': 'testomonials',
    'route': '/testomonials'
  },
  'login': {
    'name': 'login',
    'route': '/login'
  },
  'register': {
    'name': 'register',
    'route': '/register'
  },
  'forget_password': {
    'name': 'forgetpassword',
    'route': '/forgetpassword'
  },
  'verify_mobile': {
    'name': 'verifymobile',
    'route': '/verifymobile'
  },
  'edit_profile': {
    'name': 'editprofile',
    'route': '/editprofile'
  },
  'email_verify': {
    'name': 'email/verify/:token',
    'route': '/verify/'
  },
  'reset_passowrd': {
    'name': 'email/reset-password/:token',
    'route': '/reset-password/'
  },
  'services': {
    'name': 'home/services',
    'route': 'home/services',
    'service': {
      'name': 'home/services/:id',
      'route': 'home/services/'
    },
  },
  'order': {
    'name': 'home/orders',
    'route': 'home/orders',
    'opened': {
      'name': 'opened',
      'route': 'home/orders/opened'
    },
    'rescheduled': {
      'name': 'rescheduled',
      'route': 'home/orders/rescheduled'
    },
    'completed': {
      'name': 'completed',
      'route': 'home/orders/completed'
    }
  },
  'create_order': {
    'name': 'home/createorder',
    'route': 'home/createorder',
    'first_step': {
      'name': 'firstStep',
      'route': 'home/createorder/firstStep'
    },
    'second_step': {
      'name': 'secondStep',
      'route': 'home/createorder/secondStep'
    },
    'order_created': {
      'name': 'orderCreated',
      'route': 'home/createorder/orderCreated'
    }
  },
  'add_address': {
    'name': 'home/addaddress',
    'route': 'home/addaddress'
  },
  'order_details': {
    'name': 'home/orderdetails/:id',
    'route': 'home/orderdetails/'
  },
  'order_category': {
    'name': 'home/chooseservices/:categoryId/:orderId',
    'route': 'home/chooseservices/'
  },
  'category': {
    'name': 'home/chooseservices/:categoryId',
    'route': 'home/chooseservices/'
  },
  'edit_address': {
    'name': 'home/editaddress/:id',
    'route': 'home/editaddress/'
  },
  'change_visit_time': {
    'name': 'home/changevisittime/:id',
    'route': 'home/changevisittime/'
  },
  'login_by_token': {
    'name': 'login-magic/:token',
    'route': 'login-magic/'
  },
  'rating': {
    'name': 'order/rate/:token',
    'route': 'order/rate/'
  },
  'select_area': {
    'name': 'home/select-location',
    'route': 'home/select-location'
  },
  'landing': {
    'name': 'home/landing',
    'route': 'home/landing'
  },
};
