/**
 * Filkhedma API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Material } from './material';
import { TechnicianTool } from './technicianTool';


export interface CreateServiceRequest { 
    nameEn?: string;
    nameAr?: string;
    customerNameEn?: string;
    customerNameAr?: string;
    parentCategoryId?: string;
    rootCategoryId?: string;
    price?: number;
    estimatedTime?: number;
    assistants?: number;
    level?: CreateServiceRequest.LevelEnum;
    guaranteeInWeeks?: number;
    materials?: Array<Material>;
    tools?: Array<TechnicianTool>;
    isActive?: boolean;
    descriptionAr?: string;
    descriptionEn?: string;
}
export namespace CreateServiceRequest {
    export type LevelEnum = 'A' | 'B';
    export const LevelEnum = {
        A: 'A' as LevelEnum,
        B: 'B' as LevelEnum
    };
}
