import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BrandingLandingRoutingModule } from './branding-landing-routing.module';
import { BrandingLandingComponent } from './branding-landing/branding-landing.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HelpersModule } from '../helpers/helpers.module';
import { TranslateModule } from '@ngx-translate/core';
import { SharedUiModule } from '../sharedui/sharedui.module';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared';
import { ModalModule } from 'ngx-bootstrap';

@NgModule({
  declarations: [BrandingLandingComponent],
  imports: [
    CommonModule,
    BrandingLandingRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    HelpersModule,
    TranslateModule,
    SharedModule,
    SharedUiModule,
    ModalModule
  ]
})
export class BrandingLandingModule { }
