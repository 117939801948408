/**
 * Filkhedma API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface StatusResponse { 
    /**
     * Technician Status
     */
    status?: StatusResponse.StatusEnum;
    /**
     * Technician Account Status
     */
    accountStatus?: StatusResponse.AccountStatusEnum;
    /**
     * Display Technician Status Message Flag
     */
    shouldDislayStatusMsg?: boolean;
    /**
     * Technician Type
     */
    type?: StatusResponse.TypeEnum;
}
export namespace StatusResponse {
    export type StatusEnum = 'online' | 'offline';
    export const StatusEnum = {
        Online: 'online' as StatusEnum,
        Offline: 'offline' as StatusEnum
    };
    export type AccountStatusEnum = 'active' | 'suspended' | 'blocked' | 'pending';
    export const AccountStatusEnum = {
        Active: 'active' as AccountStatusEnum,
        Suspended: 'suspended' as AccountStatusEnum,
        Blocked: 'blocked' as AccountStatusEnum,
        Pending: 'pending' as AccountStatusEnum
    };
    export type TypeEnum = 'inhouse' | 'marketplace';
    export const TypeEnum = {
        Inhouse: 'inhouse' as TypeEnum,
        Marketplace: 'marketplace' as TypeEnum
    };
}
