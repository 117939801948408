import { ActivatedRoute } from '@angular/router';
import { CustomerVerifyEmailRequest } from './../../swagger/model/customerVerifyEmailRequest';
import { Component, OnInit } from '@angular/core';
import { CustomerService } from '../../swagger/api/customer.service';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.css']
})
export class VerifyEmailComponent implements OnInit {

  verifyLoading = true;
  error = false;

  constructor(
    private customerService: CustomerService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.route.params.subscribe(
      params => {
        if (params['token']) {
          this.verifyEmail(params['token']);
        }
      }
    );
  }

  verifyEmail(token) {
    const customerVerifyEmailReq: CustomerVerifyEmailRequest = {
      token: token,
    };
    this.customerService.verifyEmail(customerVerifyEmailReq).subscribe(
      res => {
        if (res) {
          this.verifyLoading = false;
          this.error = false;
        }
      },
      err => {
        this.verifyLoading = false;
        this.error = true;
      },
    );
  }

}
