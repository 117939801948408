import { Component, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { LanguageService } from "../../../helpers/translate service/language.service";

@Component({
  selector: "app-prices",
  templateUrl: "./prices.component.html",
  styleUrls: ["./prices.component.scss"]
})
export class PricesComponent implements OnInit {
  listCollapsed = true;
  sparePrices;
  searchText = "";

  constructor(private http: HttpClient,
    public languageService: LanguageService) {
    this.getJSON().subscribe(
      data => {
        this.sparePrices = this.objToArr(data);
        for (let i = 0; i < this.sparePrices.length; i++) {
          this.sparePrices[i].value = this.objToArr(this.sparePrices[i].value);
        }
      },
      error => {
        console.error(error);
      }
    );
  }

  public objToArr(value) {
    const keys = [];
    for (const key in value) {
      if (key) {
        keys.push({ key: key, value: value[key] });
      }
    }
    return keys;
  }

  ngOnInit() { }

  public getJSON(): Observable<any> {
    return this.http.get("../../../../assets/json/sparesprices.json");
  }
}
