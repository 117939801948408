import { environment } from './../../../../environments/environment';
import {
  Injectable,
  Inject,
  ErrorHandler,
  InjectionToken
} from '@angular/core';
import * as Rollbar from 'rollbar';

/**
 * Rollbar Configuration Object
 */
export const Config = {
  accessToken: '652770803e144defa4f2dde87626ad73',
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: environment.envName
  }
};
/**
 * to define the rollbar object and inject it in our angular context
 */
export const RollbarService = new InjectionToken<Rollbar>('rollbar');



@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  /**
   *
   * @param rollbar
   */
  constructor(
    @Inject(RollbarService) private rollbar: Rollbar,
  ) { }

  /**
   * Handling Errors that will occur
   * @param err the error that will occur from angular or from our code
   */
  handleError(err: any): void {
    if (environment.applyRollbar) {
      this.rollbar.configure({
        payload: {
          environment: environment.envName,
          person: {
            username: localStorage.getItem('user'),
            email: localStorage.getItem('email'),
            language: localStorage.getItem('lang'),
            mobileMobile: localStorage.getItem('mobile-number'),
            token: localStorage.getItem('token')

          },
          request: {
            requestData: err.request
          },
          error: {
            error: err.error
          }
        }
      });
      this.rollbar.error(err.originalError || err);
    } else {
      console.error(err);
    }
  }
}
