/**
 * Filkhedma API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface VerifyCustomerRequest { 
    firstName?: string;
    lastName?: string;
    mobile?: string;
    email?: string;
    password?: string;
    confirmPassword?: string;
    gender?: VerifyCustomerRequest.GenderEnum;
    facebookAccessToken?: string;
}
export namespace VerifyCustomerRequest {
    export type GenderEnum = 'male' | 'female';
    export const GenderEnum = {
        Male: 'male' as GenderEnum,
        Female: 'female' as GenderEnum
    };
}
