import { AuthenticationService } from './../services/authenication/authentication.service';
import { Injectable } from '@angular/core';
import { CanActivate, Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { routeConfig } from '../utilities/pages-config';

@Injectable()
export class RouterGuard implements CanActivate {

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        this.authenticationService.tokenExpired$.subscribe(e => {
            if (e) {
                console.log(this.router.navigate(['/'], { queryParams: { returnUrl: state.url } }));
                this.router.navigate(['/'], { queryParams: { returnUrl: state.url } });
                this.router.navigateByUrl(state.url);
                return true;
            }
        });
        return true;
    }
}
