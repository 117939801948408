/**
 * Filkhedma API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface RejectOrderRequest { 
    /**
     * Order ID
     */
    orderId: string;
    /**
     * Matching Type
     */
    matchingType?: RejectOrderRequest.MatchingTypeEnum;
}
export namespace RejectOrderRequest {
    export type MatchingTypeEnum = 'online' | 'offline';
    export const MatchingTypeEnum = {
        Online: 'online' as MatchingTypeEnum,
        Offline: 'offline' as MatchingTypeEnum
    };
}
