import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HelpersModule } from '../helpers/helpers.module';
import { AccordionModule, ModalModule } from 'ngx-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { AuthenticationRoutingModule } from './authentication-routing.module';
import { LoginByTokenComponent } from './login-by-token/login-by-token.component';
import { SharedModule } from '../shared';
import { SharedUiModule } from '../sharedui/sharedui.module';
import { LoginComponent } from './login/login.component';
import { RegistrationComponent } from './registration/registration.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { ChangePasswordByEmailComponent } from './change-password-by-email/change-password-by-email.component';
import { VerifyEmailComponent } from './verify-email/verify-email.component';
import { VerifyMobileComponent } from './verify-mobile/verify-mobile.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        HelpersModule,
        AccordionModule,
        TranslateModule,
        AuthenticationRoutingModule,
        SharedModule,
        SharedUiModule,
        ModalModule
    ],
    providers: [
    ],
    declarations: [
        LoginByTokenComponent,
        LoginComponent,
        RegistrationComponent,
        ForgetPasswordComponent,
        ChangePasswordByEmailComponent,
        VerifyEmailComponent,
        VerifyMobileComponent
    ],
    exports: [

    ],
})
export class AuthenticationModule { }
