/**
 * Filkhedma API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { AcceptOrderRequest } from '../model/acceptOrderRequest';
import { AcceptRescheduledOrderRequest } from '../model/acceptRescheduledOrderRequest';
import { AddTechnicianBusySlotRequest } from '../model/addTechnicianBusySlotRequest';
import { BusySlotReasonsResponse } from '../model/busySlotReasonsResponse';
import { CalculateVisitExpectedPaidAmountRequest } from '../model/calculateVisitExpectedPaidAmountRequest';
import { CalculateVisitExpectedPaidAmountResponse } from '../model/calculateVisitExpectedPaidAmountResponse';
import { CancelOrderRequest } from '../model/cancelOrderRequest';
import { Category } from '../model/category';
import { ConfirmOrderRequest } from '../model/confirmOrderRequest';
import { Credentials } from '../model/credentials';
import { FinalizeOrderRequest } from '../model/finalizeOrderRequest';
import { FinancialTransaction } from '../model/financialTransaction';
import { ForgotPasswordRequest } from '../model/forgotPasswordRequest';
import { IgnoreOrderRequest } from '../model/ignoreOrderRequest';
import { LoginResponse } from '../model/loginResponse';
import { NewTechnicianInfo } from '../model/newTechnicianInfo';
import { NewVisitRequest } from '../model/newVisitRequest';
import { Order } from '../model/order';
import { OrderTimeConflictsResponse } from '../model/orderTimeConflictsResponse';
import { OrdersResponse } from '../model/ordersResponse';
import { ProfileResponse } from '../model/profileResponse';
import { PushNotificationRegistrationID } from '../model/pushNotificationRegistrationID';
import { RateCustomerRequest } from '../model/rateCustomerRequest';
import { ReceiveOrderRequest } from '../model/receiveOrderRequest';
import { RecommendedTechnicianInfo } from '../model/recommendedTechnicianInfo';
import { RefreshTokenResponse } from '../model/refreshTokenResponse';
import { RejectOrderRequest } from '../model/rejectOrderRequest';
import { RejectRescheduledOrderRequest } from '../model/rejectRescheduledOrderRequest';
import { RescheduleOrderRequest } from '../model/rescheduleOrderRequest';
import { ServicesResponse } from '../model/servicesResponse';
import { Settings } from '../model/settings';
import { StartOrderRequest } from '../model/startOrderRequest';
import { StatusResponse } from '../model/statusResponse';
import { SuccessResponse } from '../model/successResponse';
import { TechnicianBalance } from '../model/technicianBalance';
import { TechnicianBusySlot } from '../model/technicianBusySlot';
import { TechnicianBusySlotsResponse } from '../model/technicianBusySlotsResponse';
import { TechnicianWeeklyProfit } from '../model/technicianWeeklyProfit';
import { UpdateOrderServicesRequest } from '../model/updateOrderServicesRequest';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class TechnicianService {

    protected basePath = 'https://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Technician Accept Order
     * 
     * @param token Technician Access Token
     * @param acceptOrderRequest Accept Order Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public acceptOrder(token?: string, acceptOrderRequest?: AcceptOrderRequest, observe?: 'body', reportProgress?: boolean): Observable<SuccessResponse>;
    public acceptOrder(token?: string, acceptOrderRequest?: AcceptOrderRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessResponse>>;
    public acceptOrder(token?: string, acceptOrderRequest?: AcceptOrderRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessResponse>>;
    public acceptOrder(token?: string, acceptOrderRequest?: AcceptOrderRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<SuccessResponse>(`${this.basePath}/acceptOrder`,
            acceptOrderRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Technician Accept Rescheduled Order
     * 
     * @param token Technician Access Token
     * @param acceptRescheduledOrderRequest Accept Rescheduled Order Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public acceptRescheduledOrder(token?: string, acceptRescheduledOrderRequest?: AcceptRescheduledOrderRequest, observe?: 'body', reportProgress?: boolean): Observable<Order>;
    public acceptRescheduledOrder(token?: string, acceptRescheduledOrderRequest?: AcceptRescheduledOrderRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Order>>;
    public acceptRescheduledOrder(token?: string, acceptRescheduledOrderRequest?: AcceptRescheduledOrderRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Order>>;
    public acceptRescheduledOrder(token?: string, acceptRescheduledOrderRequest?: AcceptRescheduledOrderRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Order>(`${this.basePath}/acceptRescheduledOrder`,
            acceptRescheduledOrderRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * add Technician Busy Slot
     * 
     * @param token Technician Access Token
     * @param addTechnicianBusySlotRequest Add Technician Busy Slot Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addBusySlot(token?: string, addTechnicianBusySlotRequest?: AddTechnicianBusySlotRequest, observe?: 'body', reportProgress?: boolean): Observable<TechnicianBusySlot>;
    public addBusySlot(token?: string, addTechnicianBusySlotRequest?: AddTechnicianBusySlotRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TechnicianBusySlot>>;
    public addBusySlot(token?: string, addTechnicianBusySlotRequest?: AddTechnicianBusySlotRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TechnicianBusySlot>>;
    public addBusySlot(token?: string, addTechnicianBusySlotRequest?: AddTechnicianBusySlotRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<TechnicianBusySlot>(`${this.basePath}/busy-slot`,
            addTechnicianBusySlotRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Technician get calculated expected paid amount for current visit
     * 
     * @param token Technician Access Token
     * @param calculateVisitExpectedPaidAmountRequest Calculate Visit Expected Paid Amount Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public calculateVisitExpectedPaidAmount(token?: string, calculateVisitExpectedPaidAmountRequest?: CalculateVisitExpectedPaidAmountRequest, observe?: 'body', reportProgress?: boolean): Observable<CalculateVisitExpectedPaidAmountResponse>;
    public calculateVisitExpectedPaidAmount(token?: string, calculateVisitExpectedPaidAmountRequest?: CalculateVisitExpectedPaidAmountRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CalculateVisitExpectedPaidAmountResponse>>;
    public calculateVisitExpectedPaidAmount(token?: string, calculateVisitExpectedPaidAmountRequest?: CalculateVisitExpectedPaidAmountRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CalculateVisitExpectedPaidAmountResponse>>;
    public calculateVisitExpectedPaidAmount(token?: string, calculateVisitExpectedPaidAmountRequest?: CalculateVisitExpectedPaidAmountRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CalculateVisitExpectedPaidAmountResponse>(`${this.basePath}/calculateExpectedPaidAmount`,
            calculateVisitExpectedPaidAmountRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Technician Cancel Order
     * 
     * @param token Technician Access Token
     * @param cancelOrderRequest Cancel Order Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public cancelOrder(token?: string, cancelOrderRequest?: CancelOrderRequest, observe?: 'body', reportProgress?: boolean): Observable<SuccessResponse>;
    public cancelOrder(token?: string, cancelOrderRequest?: CancelOrderRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessResponse>>;
    public cancelOrder(token?: string, cancelOrderRequest?: CancelOrderRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessResponse>>;
    public cancelOrder(token?: string, cancelOrderRequest?: CancelOrderRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<SuccessResponse>(`${this.basePath}/cancelOrder`,
            cancelOrderRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Technician Confirm Order
     * 
     * @param token Technician Access Token
     * @param confirmOrderRequest Confirm Order Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public confirmOrder(token?: string, confirmOrderRequest?: ConfirmOrderRequest, observe?: 'body', reportProgress?: boolean): Observable<SuccessResponse>;
    public confirmOrder(token?: string, confirmOrderRequest?: ConfirmOrderRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessResponse>>;
    public confirmOrder(token?: string, confirmOrderRequest?: ConfirmOrderRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessResponse>>;
    public confirmOrder(token?: string, confirmOrderRequest?: ConfirmOrderRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<SuccessResponse>(`${this.basePath}/confirmOrder`,
            confirmOrderRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create New Visit
     * 
     * @param token Technician Access Token
     * @param newVisitRequest New Visit Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createVisit(token?: string, newVisitRequest?: NewVisitRequest, observe?: 'body', reportProgress?: boolean): Observable<Order>;
    public createVisit(token?: string, newVisitRequest?: NewVisitRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Order>>;
    public createVisit(token?: string, newVisitRequest?: NewVisitRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Order>>;
    public createVisit(token?: string, newVisitRequest?: NewVisitRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Order>(`${this.basePath}/createVisit`,
            newVisitRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete Busy Slot By ID
     * 
     * @param busySlotId Busy Slot ID
     * @param token Technician Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteMyBusySlotById(busySlotId: string, token?: string, observe?: 'body', reportProgress?: boolean): Observable<SuccessResponse>;
    public deleteMyBusySlotById(busySlotId: string, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessResponse>>;
    public deleteMyBusySlotById(busySlotId: string, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessResponse>>;
    public deleteMyBusySlotById(busySlotId: string, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (busySlotId === null || busySlotId === undefined) {
            throw new Error('Required parameter busySlotId was null or undefined when calling deleteMyBusySlotById.');
        }


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<SuccessResponse>(`${this.basePath}/busy-slot/${encodeURIComponent(String(busySlotId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Technician Finalize Order
     * 
     * @param token Technician Access Token
     * @param finalizeOrderRequest Finalize Order Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public finalizeOrder(token?: string, finalizeOrderRequest?: FinalizeOrderRequest, observe?: 'body', reportProgress?: boolean): Observable<Order>;
    public finalizeOrder(token?: string, finalizeOrderRequest?: FinalizeOrderRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Order>>;
    public finalizeOrder(token?: string, finalizeOrderRequest?: FinalizeOrderRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Order>>;
    public finalizeOrder(token?: string, finalizeOrderRequest?: FinalizeOrderRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Order>(`${this.basePath}/finalizeOrder`,
            finalizeOrderRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Send Technician Password to his mobile
     * 
     * @param forgotPasswordRequest Technician Mobile
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public forgotPassword(forgotPasswordRequest?: ForgotPasswordRequest, observe?: 'body', reportProgress?: boolean): Observable<SuccessResponse>;
    public forgotPassword(forgotPasswordRequest?: ForgotPasswordRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessResponse>>;
    public forgotPassword(forgotPasswordRequest?: ForgotPasswordRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessResponse>>;
    public forgotPassword(forgotPasswordRequest?: ForgotPasswordRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<SuccessResponse>(`${this.basePath}/forgotPassword`,
            forgotPasswordRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve Busy Slot Reasons
     * 
     * @param token Technician Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllBusySlotReasons(token?: string, observe?: 'body', reportProgress?: boolean): Observable<BusySlotReasonsResponse>;
    public getAllBusySlotReasons(token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BusySlotReasonsResponse>>;
    public getAllBusySlotReasons(token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BusySlotReasonsResponse>>;
    public getAllBusySlotReasons(token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<BusySlotReasonsResponse>(`${this.basePath}/listBusySlotReasons`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve technician balance
     * 
     * @param token Technician Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBalance(token?: string, observe?: 'body', reportProgress?: boolean): Observable<TechnicianBalance>;
    public getBalance(token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TechnicianBalance>>;
    public getBalance(token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TechnicianBalance>>;
    public getBalance(token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<TechnicianBalance>(`${this.basePath}/balance`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve Technician Busy Slots
     * 
     * @param dateFrom Date From
     * @param dateTo Date To
     * @param token Technician Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMyBusySlots(dateFrom: string, dateTo: string, token?: string, observe?: 'body', reportProgress?: boolean): Observable<TechnicianBusySlotsResponse>;
    public getMyBusySlots(dateFrom: string, dateTo: string, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TechnicianBusySlotsResponse>>;
    public getMyBusySlots(dateFrom: string, dateTo: string, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TechnicianBusySlotsResponse>>;
    public getMyBusySlots(dateFrom: string, dateTo: string, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (dateFrom === null || dateFrom === undefined) {
            throw new Error('Required parameter dateFrom was null or undefined when calling getMyBusySlots.');
        }

        if (dateTo === null || dateTo === undefined) {
            throw new Error('Required parameter dateTo was null or undefined when calling getMyBusySlots.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (dateFrom !== undefined && dateFrom !== null) {
            queryParameters = queryParameters.set('dateFrom', <any>dateFrom);
        }
        if (dateTo !== undefined && dateTo !== null) {
            queryParameters = queryParameters.set('dateTo', <any>dateTo);
        }

        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<TechnicianBusySlotsResponse>(`${this.basePath}/busy-slots`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve Order Info for Technician
     * 
     * @param orderId Order ID
     * @param token Technician Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrderById(orderId: string, token?: string, observe?: 'body', reportProgress?: boolean): Observable<Order>;
    public getOrderById(orderId: string, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Order>>;
    public getOrderById(orderId: string, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Order>>;
    public getOrderById(orderId: string, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling getOrderById.');
        }


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Order>(`${this.basePath}/order/${encodeURIComponent(String(orderId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve all active category services
     * 
     * @param orderId Order ID
     * @param token Technician Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrderServices(orderId: string, token?: string, observe?: 'body', reportProgress?: boolean): Observable<ServicesResponse>;
    public getOrderServices(orderId: string, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ServicesResponse>>;
    public getOrderServices(orderId: string, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ServicesResponse>>;
    public getOrderServices(orderId: string, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling getOrderServices.');
        }


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ServicesResponse>(`${this.basePath}/list-order-services/${encodeURIComponent(String(orderId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve Orders for Technician
     * 
     * @param status Order Status
     * @param skip Skipped Orders ( Pagination )
     * @param token Technician Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrdersByStatus(status: string, skip: number, token?: string, observe?: 'body', reportProgress?: boolean): Observable<OrdersResponse>;
    public getOrdersByStatus(status: string, skip: number, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrdersResponse>>;
    public getOrdersByStatus(status: string, skip: number, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrdersResponse>>;
    public getOrdersByStatus(status: string, skip: number, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (status === null || status === undefined) {
            throw new Error('Required parameter status was null or undefined when calling getOrdersByStatus.');
        }

        if (skip === null || skip === undefined) {
            throw new Error('Required parameter skip was null or undefined when calling getOrdersByStatus.');
        }


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<OrdersResponse>(`${this.basePath}/orders/${encodeURIComponent(String(status))}/${encodeURIComponent(String(skip))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve technician profile info
     * 
     * @param token Technician Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProfile(token?: string, observe?: 'body', reportProgress?: boolean): Observable<ProfileResponse>;
    public getProfile(token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProfileResponse>>;
    public getProfile(token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProfileResponse>>;
    public getProfile(token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ProfileResponse>(`${this.basePath}/profile`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve App Settings
     * 
     * @param platform Application Platform
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSettings(platform: string, observe?: 'body', reportProgress?: boolean): Observable<Settings>;
    public getSettings(platform: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Settings>>;
    public getSettings(platform: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Settings>>;
    public getSettings(platform: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (platform === null || platform === undefined) {
            throw new Error('Required parameter platform was null or undefined when calling getSettings.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Settings>(`${this.basePath}/settings/${encodeURIComponent(String(platform))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Technician Status
     * 
     * @param token Technician Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStatus(token?: string, observe?: 'body', reportProgress?: boolean): Observable<StatusResponse>;
    public getStatus(token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<StatusResponse>>;
    public getStatus(token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StatusResponse>>;
    public getStatus(token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<StatusResponse>(`${this.basePath}/status`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve technician transactions
     * 
     * @param skip Skipped Transactions ( Pagination )
     * @param token Technician Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTransactions(skip: number, token?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<FinancialTransaction>>;
    public getTransactions(skip: number, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<FinancialTransaction>>>;
    public getTransactions(skip: number, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<FinancialTransaction>>>;
    public getTransactions(skip: number, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (skip === null || skip === undefined) {
            throw new Error('Required parameter skip was null or undefined when calling getTransactions.');
        }


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<FinancialTransaction>>(`${this.basePath}/transactions/${encodeURIComponent(String(skip))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve technician weekly profit
     * 
     * @param token Technician Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWeeklyProfit(token?: string, observe?: 'body', reportProgress?: boolean): Observable<TechnicianWeeklyProfit>;
    public getWeeklyProfit(token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TechnicianWeeklyProfit>>;
    public getWeeklyProfit(token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TechnicianWeeklyProfit>>;
    public getWeeklyProfit(token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<TechnicianWeeklyProfit>(`${this.basePath}/weekly-profit`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Technician Go Online
     * 
     * @param token Technician Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public goOffline(token?: string, observe?: 'body', reportProgress?: boolean): Observable<SuccessResponse>;
    public goOffline(token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessResponse>>;
    public goOffline(token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessResponse>>;
    public goOffline(token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<SuccessResponse>(`${this.basePath}/goOffline`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Technician Go Online
     * 
     * @param token Technician Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public goOnline(token?: string, observe?: 'body', reportProgress?: boolean): Observable<SuccessResponse>;
    public goOnline(token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessResponse>>;
    public goOnline(token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessResponse>>;
    public goOnline(token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<SuccessResponse>(`${this.basePath}/goOnline`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Technician Ignored Order
     * 
     * @param token Technician Access Token
     * @param ignoreOrderRequest Ignore Order Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public ignoreOrder(token?: string, ignoreOrderRequest?: IgnoreOrderRequest, observe?: 'body', reportProgress?: boolean): Observable<SuccessResponse>;
    public ignoreOrder(token?: string, ignoreOrderRequest?: IgnoreOrderRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessResponse>>;
    public ignoreOrder(token?: string, ignoreOrderRequest?: IgnoreOrderRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessResponse>>;
    public ignoreOrder(token?: string, ignoreOrderRequest?: IgnoreOrderRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<SuccessResponse>(`${this.basePath}/ignoreOrder`,
            ignoreOrderRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Technician Go Online to join matching
     * 
     * @param token Technician Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public joinMatching(token?: string, observe?: 'body', reportProgress?: boolean): Observable<SuccessResponse>;
    public joinMatching(token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessResponse>>;
    public joinMatching(token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessResponse>>;
    public joinMatching(token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<SuccessResponse>(`${this.basePath}/joinMatching`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve all active categories
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listCategories(observe?: 'body', reportProgress?: boolean): Observable<Array<Category>>;
    public listCategories(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Category>>>;
    public listCategories(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Category>>>;
    public listCategories(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<Category>>(`${this.basePath}/list-categories`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Logs technician into the system
     * 
     * @param credentials Technician Credentials
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public login(credentials?: Credentials, observe?: 'body', reportProgress?: boolean): Observable<LoginResponse>;
    public login(credentials?: Credentials, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LoginResponse>>;
    public login(credentials?: Credentials, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LoginResponse>>;
    public login(credentials?: Credentials, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<LoginResponse>(`${this.basePath}/login`,
            credentials,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Purge technician records
     * 
     * @param token Technician Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public purgeOrdersAndResetBalance(token?: string, observe?: 'body', reportProgress?: boolean): Observable<SuccessResponse>;
    public purgeOrdersAndResetBalance(token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessResponse>>;
    public purgeOrdersAndResetBalance(token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessResponse>>;
    public purgeOrdersAndResetBalance(token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<SuccessResponse>(`${this.basePath}/purge`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Technician Rate Customer
     * 
     * @param token Technician Access Token
     * @param rateCustomerRequest Rate Customer Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public rateCustomer(token?: string, rateCustomerRequest?: RateCustomerRequest, observe?: 'body', reportProgress?: boolean): Observable<Order>;
    public rateCustomer(token?: string, rateCustomerRequest?: RateCustomerRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Order>>;
    public rateCustomer(token?: string, rateCustomerRequest?: RateCustomerRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Order>>;
    public rateCustomer(token?: string, rateCustomerRequest?: RateCustomerRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Order>(`${this.basePath}/rateCustomer`,
            rateCustomerRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Technician Received Request
     * 
     * @param token Technician Access Token
     * @param receiveOrderRequest Receive Order Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public receiveRequest(token?: string, receiveOrderRequest?: ReceiveOrderRequest, observe?: 'body', reportProgress?: boolean): Observable<SuccessResponse>;
    public receiveRequest(token?: string, receiveOrderRequest?: ReceiveOrderRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessResponse>>;
    public receiveRequest(token?: string, receiveOrderRequest?: ReceiveOrderRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessResponse>>;
    public receiveRequest(token?: string, receiveOrderRequest?: ReceiveOrderRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<SuccessResponse>(`${this.basePath}/receiveRequest`,
            receiveOrderRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Recommend new technician to join FilKhedma
     * 
     * @param token Technician Access Token
     * @param recommendedTechnicianInfo Technician Info
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public recommendTechnician(token?: string, recommendedTechnicianInfo?: RecommendedTechnicianInfo, observe?: 'body', reportProgress?: boolean): Observable<SuccessResponse>;
    public recommendTechnician(token?: string, recommendedTechnicianInfo?: RecommendedTechnicianInfo, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessResponse>>;
    public recommendTechnician(token?: string, recommendedTechnicianInfo?: RecommendedTechnicianInfo, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessResponse>>;
    public recommendTechnician(token?: string, recommendedTechnicianInfo?: RecommendedTechnicianInfo, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<SuccessResponse>(`${this.basePath}/recommend-technician`,
            recommendedTechnicianInfo,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Refresh Technician Access Token
     * 
     * @param token Technician Refresh Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public refreshToken(token?: string, observe?: 'body', reportProgress?: boolean): Observable<RefreshTokenResponse>;
    public refreshToken(token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RefreshTokenResponse>>;
    public refreshToken(token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RefreshTokenResponse>>;
    public refreshToken(token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<RefreshTokenResponse>(`${this.basePath}/refresh-token`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Register Technician Device to Push Notification Provider
     * 
     * @param token Technician Access Token
     * @param pushNotificationRegistrationID Push Notification Registration ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public registerPushNotification(token?: string, pushNotificationRegistrationID?: PushNotificationRegistrationID, observe?: 'body', reportProgress?: boolean): Observable<SuccessResponse>;
    public registerPushNotification(token?: string, pushNotificationRegistrationID?: PushNotificationRegistrationID, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessResponse>>;
    public registerPushNotification(token?: string, pushNotificationRegistrationID?: PushNotificationRegistrationID, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessResponse>>;
    public registerPushNotification(token?: string, pushNotificationRegistrationID?: PushNotificationRegistrationID, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<SuccessResponse>(`${this.basePath}/pushNotificationRegister`,
            pushNotificationRegistrationID,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Technician Reject Order
     * 
     * @param token Technician Access Token
     * @param rejectOrderRequest Reject Order Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public rejectOrder(token?: string, rejectOrderRequest?: RejectOrderRequest, observe?: 'body', reportProgress?: boolean): Observable<SuccessResponse>;
    public rejectOrder(token?: string, rejectOrderRequest?: RejectOrderRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessResponse>>;
    public rejectOrder(token?: string, rejectOrderRequest?: RejectOrderRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessResponse>>;
    public rejectOrder(token?: string, rejectOrderRequest?: RejectOrderRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<SuccessResponse>(`${this.basePath}/rejectOrder`,
            rejectOrderRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Technician Reject Rescheduled Order
     * 
     * @param token Technician Access Token
     * @param rejectRescheduledOrderRequest Reject Order Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public rejectRescheduledOrder(token?: string, rejectRescheduledOrderRequest?: RejectRescheduledOrderRequest, observe?: 'body', reportProgress?: boolean): Observable<SuccessResponse>;
    public rejectRescheduledOrder(token?: string, rejectRescheduledOrderRequest?: RejectRescheduledOrderRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessResponse>>;
    public rejectRescheduledOrder(token?: string, rejectRescheduledOrderRequest?: RejectRescheduledOrderRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessResponse>>;
    public rejectRescheduledOrder(token?: string, rejectRescheduledOrderRequest?: RejectRescheduledOrderRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<SuccessResponse>(`${this.basePath}/rejectRescheduledOrder`,
            rejectRescheduledOrderRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request to join FilKhedma as a technician
     * 
     * @param newTechnicianInfo Technician Info
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public requestToRegister(newTechnicianInfo?: NewTechnicianInfo, observe?: 'body', reportProgress?: boolean): Observable<SuccessResponse>;
    public requestToRegister(newTechnicianInfo?: NewTechnicianInfo, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessResponse>>;
    public requestToRegister(newTechnicianInfo?: NewTechnicianInfo, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessResponse>>;
    public requestToRegister(newTechnicianInfo?: NewTechnicianInfo, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<SuccessResponse>(`${this.basePath}/request-to-register`,
            newTechnicianInfo,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Technician Reschedule Order
     * 
     * @param token Technician Access Token
     * @param rescheduleOrderRequest Reschedule Order Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public rescheduleOrder(token?: string, rescheduleOrderRequest?: RescheduleOrderRequest, observe?: 'body', reportProgress?: boolean): Observable<Order>;
    public rescheduleOrder(token?: string, rescheduleOrderRequest?: RescheduleOrderRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Order>>;
    public rescheduleOrder(token?: string, rescheduleOrderRequest?: RescheduleOrderRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Order>>;
    public rescheduleOrder(token?: string, rescheduleOrderRequest?: RescheduleOrderRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Order>(`${this.basePath}/rescheduleOrder`,
            rescheduleOrderRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Technician Update Order Image
     * 
     * @param token Technician Access Token
     * @param orderId Order ID
     * @param type Order Image Type
     * @param image Order Image
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setOrderImage(token?: string, orderId?: string, type?: 'before' | 'after', image?: Blob, observe?: 'body', reportProgress?: boolean): Observable<SuccessResponse>;
    public setOrderImage(token?: string, orderId?: string, type?: 'before' | 'after', image?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessResponse>>;
    public setOrderImage(token?: string, orderId?: string, type?: 'before' | 'after', image?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessResponse>>;
    public setOrderImage(token?: string, orderId?: string, type?: 'before' | 'after', image?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }
        if (orderId !== undefined && orderId !== null) {
            headers = headers.set('orderId', String(orderId));
        }
        if (type !== undefined && type !== null) {
            headers = headers.set('type', String(type));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (image !== undefined) {
            // formParams = formParams.append('image', <any>image) || formParams;
        }

        return this.httpClient.post<SuccessResponse>(`${this.basePath}/setOrderImage`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Technician Start Order
     * 
     * @param token Technician Access Token
     * @param startOrderRequest Start Order Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public startOrder(token?: string, startOrderRequest?: StartOrderRequest, observe?: 'body', reportProgress?: boolean): Observable<SuccessResponse>;
    public startOrder(token?: string, startOrderRequest?: StartOrderRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessResponse>>;
    public startOrder(token?: string, startOrderRequest?: StartOrderRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessResponse>>;
    public startOrder(token?: string, startOrderRequest?: StartOrderRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<SuccessResponse>(`${this.basePath}/startOrder`,
            startOrderRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Unregister Technician Device to Push Notification Provider
     * 
     * @param token Technician Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public unregisterPushNotification(token?: string, observe?: 'body', reportProgress?: boolean): Observable<SuccessResponse>;
    public unregisterPushNotification(token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessResponse>>;
    public unregisterPushNotification(token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessResponse>>;
    public unregisterPushNotification(token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<SuccessResponse>(`${this.basePath}/pushNotificationUnregister`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Technician Update Order Services
     * 
     * @param token Technician Access Token
     * @param updateOrderServicesRequest Update Order Services Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateOrderServices(token?: string, updateOrderServicesRequest?: UpdateOrderServicesRequest, observe?: 'body', reportProgress?: boolean): Observable<Order>;
    public updateOrderServices(token?: string, updateOrderServicesRequest?: UpdateOrderServicesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Order>>;
    public updateOrderServices(token?: string, updateOrderServicesRequest?: UpdateOrderServicesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Order>>;
    public updateOrderServices(token?: string, updateOrderServicesRequest?: UpdateOrderServicesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Order>(`${this.basePath}/updateOrderServices`,
            updateOrderServicesRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
