/**
 * Filkhedma API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Area } from './area';
import { City } from './city';
import { Subarea } from './subarea';


export interface CustomerProfileAddress { 
    /**
     * Address ID
     */
    id?: string;
    /**
     * Address Text
     */
    text?: string;
    /**
     * Address Area
     */
    area?: Area;
    /**
     * Address Subarea
     */
    subarea?: Subarea;
    governorate?: string;
    city?: City;
    addressType?: CustomerProfileAddress.AddressTypeEnum;
    street?: string;
    building?: string;
    floor?: string;
    appartment?: string;
    landline?: string;
    landmark?: string;
    notes?: string;
}
export namespace CustomerProfileAddress {
    export type AddressTypeEnum = 'work' | 'home';
    export const AddressTypeEnum = {
        Work: 'work' as AddressTypeEnum,
        Home: 'home' as AddressTypeEnum
    };
}
