import { Injectable, OnInit } from '@angular/core';
import { Order } from '../../swagger/model/order';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';

@Injectable()
export class OrderService implements OnInit {

    lastOrders: Order[];
    private message: Subject<any> = new Subject<any>();

    constructor(
    ) { }

    ngOnInit() {
    }

    sendMessge(mes) {
        this.message.next({ text: mes });
    }

    getMessages(): Observable<any> {
        return this.message.asObservable();
    }
}
