/**
 * Filkhedma API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface FilterCalendarRequest { 
    date?: string;
    categoryIds?: Array<string>;
    areaIds?: Array<string>;
    subareaIds?: Array<string>;
    technicianIds?: Array<string>;
    excludedTechniciansIds?: Array<string>;
    firstTechnicianId?: string;
    technicianType?: FilterCalendarRequest.TechnicianTypeEnum;
    sort?: FilterCalendarRequest.SortEnum;
    take?: number;
    skip?: number;
}
export namespace FilterCalendarRequest {
    export type TechnicianTypeEnum = 'inhouse' | 'marketplace';
    export const TechnicianTypeEnum = {
        Inhouse: 'inhouse' as TechnicianTypeEnum,
        Marketplace: 'marketplace' as TechnicianTypeEnum
    };
    export type SortEnum = 'availability' | 'busyness';
    export const SortEnum = {
        Availability: 'availability' as SortEnum,
        Busyness: 'busyness' as SortEnum
    };
}
