import { ConfigLoaderService } from './../config-loader/config-loader.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HelperService {
  public regexList: any[] = [];
  constructor(private configLoaderService: ConfigLoaderService) {
    if (this.configLoaderService.getRegex) {
      this.configLoaderService.getRegex().subscribe(res => {
        this.regexList = res;
      });
    }
  }
  getRegexByKey(key: string): string {
    return decodeURI(this.regexList[key] || '');
  }
}
