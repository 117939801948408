import { Component, OnInit, Input, forwardRef, ElementRef, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { HelperService } from '../../services/helper/helper.service';

@Component({
  selector: 'fkd-counter',
  templateUrl: './fkd-counter.component.html',
  styleUrls: ['./fkd-counter.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FkdCounterComponent),
      multi: true
    }
  ]
})
export class FkdCounterComponent implements OnInit, ControlValueAccessor {

  /** for hold the inner value for the input */
  public innerValue: string;

  /** decrement id attribute*/
  @Input() decrement_id = '';

  /** decrement name attribute */
  @Input() decrement_name = '';

  /** increment id attribute*/
  @Input() increment_id = '';

  /** increment name attribute */
  @Input() increment_name = '';

  /** Input field */
  @ViewChild('inputField', { read: ElementRef }) inputField: ElementRef;

  /** required errot message */
  @Input() requiredErrorMessage: string;

  /** for check if the input is required or not  */
  @Input() required = false;

  /** regular expression pattern */
  @Input() patternValue = '';

  /** this variable will hold key name from regex-config.json file */
  @Input() patternKey: string = null;

  /** Max number value */
  @Input() maxNumber: number;

  /** Max number value */
  @Input() disabled: boolean;

  /** function template */
  private onTouchedCallback: () => {};

  /** function template */
  private onChangeCallback = (_: any) => { };

  /** get accessor including */
  get value(): any {
    return this.innerValue;
  }

  /** set accessor including call the onChange callback */
  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  constructor(private helperService: HelperService) { }

  ngOnInit() {
    const patternValueFromConfig = this.helperService.getRegexByKey(this.patternKey);
    this.patternValue = (patternValueFromConfig !== '') ? patternValueFromConfig : this.patternValue;
    this.innerValue = '0';
    this.onChangeCallback(this.innerValue);
  }


  preventChar(e) {
    if (e.key.match(this.patternValue) == null) {
      e.preventDefault();
    }
  }

  increment() {
    if (parseInt(this.innerValue, 0) < 99) {
      this.innerValue = (parseInt(this.innerValue, 0) + 1).toString();
      this.onChangeCallback(this.innerValue);
      this.checkMax();
    }
  }

  decrement() {
    if (+this.innerValue !== 0) {
      this.innerValue = (parseInt(this.innerValue, 0) - 1).toString();
      this.onChangeCallback(this.innerValue);
    }
  }

  /**Check if exceed max value */
  checkMax() {
    setTimeout(() => {
      if (this.inputField.nativeElement.value > this.maxNumber) {
        this.inputField.nativeElement.value = this.maxNumber;
        this.writeValue(this.maxNumber);
      }
    }, 0);
  }

  /**
   * this function form implementing ControlValueAccessor
   * responsible for Writes a new value to the element.
   * @param obj
   */
  writeValue(obj: any): void {
    this.innerValue = obj;
    this.onChangeCallback(obj);
  }

  /**
  * this function form implementing ControlValueAccessor
  * @param fn
  */
  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  /**
  * this function form implementing ControlValueAccessor
  * @param fn
  */
  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }


}
