/**
 * Filkhedma API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { GuaranteeQtyServiceVisit } from './guaranteeQtyServiceVisit';


export interface OrderService { 
    /**
     * Service ID
     */
    serviceId?: string;
    /**
     * Service Type
     */
    type?: OrderService.TypeEnum;
    /**
     * Service English Name
     */
    nameEn?: string;
    /**
     * Service Arabic Name
     */
    nameAr?: string;
    /**
     * Service Customer English Name
     */
    customerNameEn?: string;
    /**
     * Service Customer Arabic Name
     */
    customerNameAr?: string;
    /**
     * Service Quantity
     */
    qty?: number;
    /**
     * Service Done Quantity
     */
    doneQty?: number;
    /**
     * Service Remaining Quantity
     */
    remainingQty?: number;
    /**
     * Service price
     */
    price?: number;
    /**
     * Service estimated time
     */
    estimatedTime?: string;
    /**
     * Guarantee Service Quantity Per Visit
     */
    qtyVisitIds?: Array<GuaranteeQtyServiceVisit>;
}
export namespace OrderService {
    export type TypeEnum = 'guarantee';
    export const TypeEnum = {
        Guarantee: 'guarantee' as TypeEnum
    };
}
