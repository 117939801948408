import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(items: any[], field: string, searchText: string): any[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    if (!searchText.match(/[\u0600-\u06FF]/)) {
      return [];
    }

    searchText = searchText;//.toLowerCase();

    return items.filter(it => {
      // return it[field].toLowerCase().includes(searchText);
      return it[field].includes(searchText);
    });
  }

}
