/**
 * Filkhedma API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Area } from './area';
import { Category } from './category';
import { Coupon } from './coupon';
import { CurrentTechnician } from './currentTechnician';
import { Customer } from './customer';
import { FinanceResponse } from './financeResponse';
import { Material } from './material';
import { OrderActions } from './orderActions';
import { OrderBusiness } from './orderBusiness';
import { OrderHistory } from './orderHistory';
import { OrderPricing } from './orderPricing';
import { OrderRating } from './orderRating';
import { OrderService } from './orderService';
import { OrderTimeSlot } from './orderTimeSlot';
import { PaymentMethod } from './paymentMethod';
import { Subarea } from './subarea';
import { Tag } from './tag';
import { User } from './user';
import { Visit } from './visit';


export interface Order { 
    /**
     * Order ID
     */
    orderId?: string;
    /**
     * Order Number
     */
    orderNo?: number;
    /**
     * Order Type ( Business | Individual )
     */
    type?: Order.TypeEnum;
    /**
     * Business Details
     */
    business?: OrderBusiness;
    /**
     * Order createdAt
     */
    createdAt?: string;
    /**
     * Order updatedAt
     */
    updatedAt?: string;
    /**
     * Order Status
     */
    status?: string;
    /**
     * Order Customer Status
     */
    customerStatus?: string;
    /**
     * Order canConfirm Flag
     */
    canConfirm?: boolean;
    /**
     * Order canCancel Flag
     */
    canCancel?: boolean;
    isGuarantee?: boolean;
    followup?: boolean;
    /**
     * Order isAccountingAcknowledged
     */
    isAccountingAcknowledged?: boolean;
    /**
     * Order inspectionApplied Flag
     */
    inspectionApplied?: boolean;
    /**
     * Order Technician Cancellation Button Label
     */
    technicianCancellationLabel?: Order.TechnicianCancellationLabelEnum;
    /**
     * Order orderTime
     */
    orderTime?: string;
    /**
     * Order channel
     */
    channel?: string;
    /**
     * Order Next Visit Time
     */
    nextVisitTime?: string;
    /**
     * Order Time Slot
     */
    timeSlot?: OrderTimeSlot;
    currentTechnician?: CurrentTechnician;
    /**
     * Order Address
     */
    address?: string;
    /**
     * Order Customer Address ID
     */
    addressId?: string;
    /**
     * Order Client Notes
     */
    clientNotes?: string;
    customer?: Customer;
    customerRate?: number;
    customerRateComment?: string;
    customerBalance?: number;
    category?: Category;
    servicesChecksum?: string;
    services?: Array<OrderService>;
    history?: Array<OrderHistory>;
    paymentMethod?: PaymentMethod;
    area?: Area;
    subarea?: Subarea;
    visits?: Array<Visit>;
    lastVisit?: Visit;
    lastVisitId?: string;
    pricing?: OrderPricing;
    coupon?: Coupon;
    estimatedEndTime?: string;
    tags?: Array<Tag>;
    materials?: Array<Material>;
    imageBefore?: string;
    imageAfter?: string;
    finance?: FinanceResponse;
    creator?: User;
    verificationPostAction?: string;
    rating?: OrderRating;
    actions?: OrderActions;
}
export namespace Order {
    export type TypeEnum = 'individual' | 'business';
    export const TypeEnum = {
        Individual: 'individual' as TypeEnum,
        Business: 'business' as TypeEnum
    };
    export type TechnicianCancellationLabelEnum = 'request' | 'cancel';
    export const TechnicianCancellationLabelEnum = {
        Request: 'request' as TechnicianCancellationLabelEnum,
        Cancel: 'cancel' as TechnicianCancellationLabelEnum
    };
}
