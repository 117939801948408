import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LanguageService } from '../../../helpers/translate service/language.service';

export interface TestomonialModel {
  nameAr: string;
  nameEn: string;
  dateAr: string;
  dateEn: string;
  textAr: string;
  textEn: string;
}

export interface TestomonialResponseModel {
  testomonials: Array<TestomonialModel>;
}
@Component({
  selector: 'app-testomonials',
  templateUrl: './testomonials.component.html',
  styleUrls: ['./testomonials.component.css']
})
export class TestomonialsComponent implements OnInit {

  testomonials: TestomonialModel[];
  constructor(private http: HttpClient,
    public languageService: LanguageService) {
  }

  ngOnInit() {
    this.getJSON().subscribe(
      res => {
        this.testomonials = res.testomonials;
      },
      error => {
        console.error(error);
      }
    );
  }

  public getJSON(): Observable<TestomonialResponseModel> {
    return this.http.get<TestomonialResponseModel>("../../../../assets/json/testomonials.json");
  }

}
