import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { EventType, EventsService } from '../events/events.service';
@Injectable()
export class EventsInterceptor implements HttpInterceptor {
  constructor(private eventsService: EventsService) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(tap(response => {
      if (response instanceof HttpResponse) {
        const eventData = {
          request: {
            url: request.url,
            urlWithParams: request.urlWithParams,
            headers: request.headers,
            method: request.method,
            params: request.params,
            responseType: request.responseType,
            body: request.serializeBody()
          },
          response: {
            status: response.status,
            body: response.body
          }
        };
        this.eventsService.publish(EventType.APIRequest, eventData);
      }
    }));

  }
}
