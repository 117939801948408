import { Category } from './../../../swagger/model/category';
import { Component, OnInit } from '@angular/core';
import { CustomerService } from '../../../swagger/api/customer.service';

@Component({
  selector: 'app-seo',
  templateUrl: './seo.component.html',
  styleUrls: ['./seo.component.css']
})
export class SeoComponent implements OnInit {

  categories: Category[];

  constructor(
    private customerService: CustomerService,
  ) { }

  ngOnInit() {
    this.getCategories();
  }

  getCategories () {
    this.customerService.getActiveCategories().subscribe(
      res => {
        this.categories = res.categories;
      },
      err => {},
    );
  }

}
