/**
 * Filkhedma API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { AnonymousTokenResponse } from '../model/anonymousTokenResponse';
import { ApplyCouponRequest } from '../model/applyCouponRequest';
import { AreasResponse } from '../model/areasResponse';
import { AvailableSlotsResponse } from '../model/availableSlotsResponse';
import { Cart } from '../model/cart';
import { CartV2 } from '../model/cartV2';
import { CategoriesResponse } from '../model/categoriesResponse';
import { CategoryChildsResponse } from '../model/categoryChildsResponse';
import { CategoryChildsResponseV2 } from '../model/categoryChildsResponseV2';
import { ChangePasswordRequest } from '../model/changePasswordRequest';
import { CheckAvailableSlotsRequest } from '../model/checkAvailableSlotsRequest';
import { CheckoutRequest } from '../model/checkoutRequest';
import { CheckoutRequestV2 } from '../model/checkoutRequestV2';
import { CitiesResponse } from '../model/citiesResponse';
import { ContactUsRequest } from '../model/contactUsRequest';
import { Customer } from '../model/customer';
import { CustomerAddress } from '../model/customerAddress';
import { CustomerCancelOrderRequest } from '../model/customerCancelOrderRequest';
import { CustomerCancelVisitRequest } from '../model/customerCancelVisitRequest';
import { CustomerChangeVisitTimeRequestV2 } from '../model/customerChangeVisitTimeRequestV2';
import { CustomerCredentials } from '../model/customerCredentials';
import { CustomerCredentials1 } from '../model/customerCredentials1';
import { CustomerCredentials2 } from '../model/customerCredentials2';
import { CustomerFacebookAccessToken } from '../model/customerFacebookAccessToken';
import { CustomerFacebookAccessToken1 } from '../model/customerFacebookAccessToken1';
import { CustomerForgotPasswordRequest } from '../model/customerForgotPasswordRequest';
import { CustomerLoginResponse } from '../model/customerLoginResponse';
import { CustomerReferralInfo } from '../model/customerReferralInfo';
import { CustomerRefreshTokenRequest } from '../model/customerRefreshTokenRequest';
import { CustomerRefreshTokenResponse } from '../model/customerRefreshTokenResponse';
import { CustomerRegisterRequestV2 } from '../model/customerRegisterRequestV2';
import { CustomerSettings } from '../model/customerSettings';
import { CustomerVerifyEmailRequest } from '../model/customerVerifyEmailRequest';
import { ForgotPasswordByEmailRequest } from '../model/forgotPasswordByEmailRequest';
import { GetNextAvailableSlotRequest } from '../model/getNextAvailableSlotRequest';
import { LoginByMagicLinkRequest } from '../model/loginByMagicLinkRequest';
import { LoginByTokenRequest } from '../model/loginByTokenRequest';
import { MobileVerificationRequest } from '../model/mobileVerificationRequest';
import { NewCustomerDetailedAddressRequest } from '../model/newCustomerDetailedAddressRequest';
import { NewVisitRequest } from '../model/newVisitRequest';
import { NextAvailableSlotResponse } from '../model/nextAvailableSlotResponse';
import { Order } from '../model/order';
import { OrderTimeConflictsResponse } from '../model/orderTimeConflictsResponse';
import { OrdersResponse } from '../model/ordersResponse';
import { RateOrderByCustomerRequest } from '../model/rateOrderByCustomerRequest';
import { RatingViewResponse } from '../model/ratingViewResponse';
import { RegisterCustomerRequest } from '../model/registerCustomerRequest';
import { RemoveCategoryRequest } from '../model/removeCategoryRequest';
import { RemoveCouponRequest } from '../model/removeCouponRequest';
import { RemoveFromCartRequest } from '../model/removeFromCartRequest';
import { RemoveOtherMobileRequest } from '../model/removeOtherMobileRequest';
import { ResendVerificationCodeRequest } from '../model/resendVerificationCodeRequest';
import { ResetPasswordByTokenRequest } from '../model/resetPasswordByTokenRequest';
import { ResetPasswordRequest } from '../model/resetPasswordRequest';
import { ServicesResponse } from '../model/servicesResponse';
import { SuccessResponse } from '../model/successResponse';
import { UpdateCartAddressRequest } from '../model/updateCartAddressRequest';
import { UpdateCartRequest } from '../model/updateCartRequest';
import { UpdateCustomerDetailedAddressRequest } from '../model/updateCustomerDetailedAddressRequest';
import { UpdateCustomerProfileRequest } from '../model/updateCustomerProfileRequest';
import { UpdateOrderServicesRequest } from '../model/updateOrderServicesRequest';
import { VerifyCustomerRequest } from '../model/verifyCustomerRequest';
import { VerifyCustomerResponse } from '../model/verifyCustomerResponse';
import { VerifyMobileRequest } from '../model/verifyMobileRequest';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class CustomerService {

    protected basePath = 'https://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Add New Customer Address
     * 
     * @param token Customer Access Token
     * @param newCustomerAddressRequest New Customer Address Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addAddress(token?: string, newCustomerAddressRequest?: NewCustomerDetailedAddressRequest, observe?: 'body', reportProgress?: boolean): Observable<Customer>;
    public addAddress(token?: string, newCustomerAddressRequest?: NewCustomerDetailedAddressRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Customer>>;
    public addAddress(token?: string, newCustomerAddressRequest?: NewCustomerDetailedAddressRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Customer>>;
    public addAddress(token?: string, newCustomerAddressRequest?: NewCustomerDetailedAddressRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Customer>(`${this.basePath}/customer/addAddress`,
            newCustomerAddressRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add New Customer Address Version 2 Minor 1
     * 
     * @param token Customer Access Token
     * @param newCustomerAddressRequest New Customer Address Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addAddress_1(token?: string, newCustomerAddressRequest?: NewCustomerDetailedAddressRequest, observe?: 'body', reportProgress?: boolean): Observable<Customer>;
    public addAddress_1(token?: string, newCustomerAddressRequest?: NewCustomerDetailedAddressRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Customer>>;
    public addAddress_1(token?: string, newCustomerAddressRequest?: NewCustomerDetailedAddressRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Customer>>;
    public addAddress_1(token?: string, newCustomerAddressRequest?: NewCustomerDetailedAddressRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Customer>(`${this.basePath}/v2.1/customer/addAddress`,
            newCustomerAddressRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add Inspection to Cart
     * 
     * @param categoryId Category ID
     * @param token Customer Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addInspectionToCart(categoryId: string, token?: string, observe?: 'body', reportProgress?: boolean): Observable<Cart>;
    public addInspectionToCart(categoryId: string, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Cart>>;
    public addInspectionToCart(categoryId: string, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Cart>>;
    public addInspectionToCart(categoryId: string, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (categoryId === null || categoryId === undefined) {
            throw new Error('Required parameter categoryId was null or undefined when calling addInspectionToCart.');
        }


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<Cart>(`${this.basePath}/customer/cart/inspect/${encodeURIComponent(String(categoryId))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add to Cart
     * 
     * @param token Customer Access Token
     * @param updateCartRequest UpdateCartRequest
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addToCart(token?: string, updateCartRequest?: UpdateCartRequest, observe?: 'body', reportProgress?: boolean): Observable<Cart>;
    public addToCart(token?: string, updateCartRequest?: UpdateCartRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Cart>>;
    public addToCart(token?: string, updateCartRequest?: UpdateCartRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Cart>>;
    public addToCart(token?: string, updateCartRequest?: UpdateCartRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Cart>(`${this.basePath}/customer/addToCart`,
            updateCartRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * User Add To Order Services
     * 
     * @param token Customer Access Token
     * @param updateOrderServicesRequest User Update Order Services Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addToOrderServices(token?: string, updateOrderServicesRequest?: UpdateOrderServicesRequest, observe?: 'body', reportProgress?: boolean): Observable<Order>;
    public addToOrderServices(token?: string, updateOrderServicesRequest?: UpdateOrderServicesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Order>>;
    public addToOrderServices(token?: string, updateOrderServicesRequest?: UpdateOrderServicesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Order>>;
    public addToOrderServices(token?: string, updateOrderServicesRequest?: UpdateOrderServicesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Order>(`${this.basePath}/customer/addToOrderServices`,
            updateOrderServicesRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * apply coupon code
     * 
     * @param token Customer Access Token
     * @param applyCouponRequest Apply Coupon Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public applyCouponCode(token?: string, applyCouponRequest?: ApplyCouponRequest, observe?: 'body', reportProgress?: boolean): Observable<Cart>;
    public applyCouponCode(token?: string, applyCouponRequest?: ApplyCouponRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Cart>>;
    public applyCouponCode(token?: string, applyCouponRequest?: ApplyCouponRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Cart>>;
    public applyCouponCode(token?: string, applyCouponRequest?: ApplyCouponRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Cart>(`${this.basePath}/customer/coupon/apply`,
            applyCouponRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * apply coupon code
     * 
     * @param token Customer Access Token
     * @param applyCouponRequest Apply Coupon Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public applyCouponCode_2(token?: string, applyCouponRequest?: ApplyCouponRequest, observe?: 'body', reportProgress?: boolean): Observable<CartV2>;
    public applyCouponCode_2(token?: string, applyCouponRequest?: ApplyCouponRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CartV2>>;
    public applyCouponCode_2(token?: string, applyCouponRequest?: ApplyCouponRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CartV2>>;
    public applyCouponCode_2(token?: string, applyCouponRequest?: ApplyCouponRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CartV2>(`${this.basePath}/v2/customer/coupon/apply`,
            applyCouponRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Customer Cancel Visit
     * 
     * @param token Customer Access Token
     * @param customerCancelVisitRequest Cancel Visit By Customer Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public cancelVisitByCustomerWebsite(token?: string, customerCancelVisitRequest?: CustomerCancelVisitRequest, observe?: 'body', reportProgress?: boolean): Observable<SuccessResponse>;
    public cancelVisitByCustomerWebsite(token?: string, customerCancelVisitRequest?: CustomerCancelVisitRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessResponse>>;
    public cancelVisitByCustomerWebsite(token?: string, customerCancelVisitRequest?: CustomerCancelVisitRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessResponse>>;
    public cancelVisitByCustomerWebsite(token?: string, customerCancelVisitRequest?: CustomerCancelVisitRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<SuccessResponse>(`${this.basePath}/customer/cancelVisit`,
            customerCancelVisitRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Change Password
     * 
     * @param token Customer Access Token
     * @param changePasswordRequest Change Password Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public changePassword(token?: string, changePasswordRequest?: ChangePasswordRequest, observe?: 'body', reportProgress?: boolean): Observable<SuccessResponse>;
    public changePassword(token?: string, changePasswordRequest?: ChangePasswordRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessResponse>>;
    public changePassword(token?: string, changePasswordRequest?: ChangePasswordRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessResponse>>;
    public changePassword(token?: string, changePasswordRequest?: ChangePasswordRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<SuccessResponse>(`${this.basePath}/customer/password`,
            changePasswordRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve Available Slots
     * 
     * @param token User Access Token
     * @param checkAvailableSlotsRequest Check Available Slots Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public checkOrderAvailableSlots(token?: string, checkAvailableSlotsRequest?: CheckAvailableSlotsRequest, observe?: 'body', reportProgress?: boolean): Observable<AvailableSlotsResponse>;
    public checkOrderAvailableSlots(token?: string, checkAvailableSlotsRequest?: CheckAvailableSlotsRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AvailableSlotsResponse>>;
    public checkOrderAvailableSlots(token?: string, checkAvailableSlotsRequest?: CheckAvailableSlotsRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AvailableSlotsResponse>>;
    public checkOrderAvailableSlots(token?: string, checkAvailableSlotsRequest?: CheckAvailableSlotsRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<AvailableSlotsResponse>(`${this.basePath}/customer/checkAvailableSlots`,
            checkAvailableSlotsRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve Available Slots
     * 
     * @param token User Access Token
     * @param checkAvailableSlotsRequest Check Available Slots Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public checkOrderAvailableSlots_3(token?: string, checkAvailableSlotsRequest?: CheckAvailableSlotsRequest, observe?: 'body', reportProgress?: boolean): Observable<AvailableSlotsResponse>;
    public checkOrderAvailableSlots_3(token?: string, checkAvailableSlotsRequest?: CheckAvailableSlotsRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AvailableSlotsResponse>>;
    public checkOrderAvailableSlots_3(token?: string, checkAvailableSlotsRequest?: CheckAvailableSlotsRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AvailableSlotsResponse>>;
    public checkOrderAvailableSlots_3(token?: string, checkAvailableSlotsRequest?: CheckAvailableSlotsRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<AvailableSlotsResponse>(`${this.basePath}/v2/customer/timeslots`,
            checkAvailableSlotsRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Checkout
     * 
     * @param token User Access Token
     * @param checkoutRequest Checkout Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public checkout(token?: string, checkoutRequest?: CheckoutRequest, observe?: 'body', reportProgress?: boolean): Observable<OrdersResponse>;
    public checkout(token?: string, checkoutRequest?: CheckoutRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrdersResponse>>;
    public checkout(token?: string, checkoutRequest?: CheckoutRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrdersResponse>>;
    public checkout(token?: string, checkoutRequest?: CheckoutRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<OrdersResponse>(`${this.basePath}/customer/checkout`,
            checkoutRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * CheckoutV2
     * 
     * @param token User Access Token
     * @param checkoutRequest Checkout Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public checkoutV2(token?: string, checkoutRequest?: CheckoutRequestV2, observe?: 'body', reportProgress?: boolean): Observable<OrdersResponse>;
    public checkoutV2(token?: string, checkoutRequest?: CheckoutRequestV2, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrdersResponse>>;
    public checkoutV2(token?: string, checkoutRequest?: CheckoutRequestV2, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrdersResponse>>;
    public checkoutV2(token?: string, checkoutRequest?: CheckoutRequestV2, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<OrdersResponse>(`${this.basePath}/v2/customer/checkout`,
            checkoutRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Contact Us
     * 
     * @param contactUsRequest Contact Us Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public contactUs(contactUsRequest?: ContactUsRequest, observe?: 'body', reportProgress?: boolean): Observable<SuccessResponse>;
    public contactUs(contactUsRequest?: ContactUsRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessResponse>>;
    public contactUs(contactUsRequest?: ContactUsRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessResponse>>;
    public contactUs(contactUsRequest?: ContactUsRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<SuccessResponse>(`${this.basePath}/customer/contactUs`,
            contactUsRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Customer Cancel Order
     * 
     * @param token Customer Access Token
     * @param customerCancelOrderRequest Cancel Order By Customer Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public customerCancelOrder(token?: string, customerCancelOrderRequest?: CustomerCancelOrderRequest, observe?: 'body', reportProgress?: boolean): Observable<SuccessResponse>;
    public customerCancelOrder(token?: string, customerCancelOrderRequest?: CustomerCancelOrderRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessResponse>>;
    public customerCancelOrder(token?: string, customerCancelOrderRequest?: CustomerCancelOrderRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessResponse>>;
    public customerCancelOrder(token?: string, customerCancelOrderRequest?: CustomerCancelOrderRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<SuccessResponse>(`${this.basePath}/customer/cancelOrder`,
            customerCancelOrderRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Change Visit Time
     * 
     * @param token Customer Access Token
     * @param changeVisitTimeRequest Change Visit Time Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public customerChangeVisitTime(token?: string, changeVisitTimeRequest?: CustomerChangeVisitTimeRequestV2, observe?: 'body', reportProgress?: boolean): Observable<Order>;
    public customerChangeVisitTime(token?: string, changeVisitTimeRequest?: CustomerChangeVisitTimeRequestV2, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Order>>;
    public customerChangeVisitTime(token?: string, changeVisitTimeRequest?: CustomerChangeVisitTimeRequestV2, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Order>>;
    public customerChangeVisitTime(token?: string, changeVisitTimeRequest?: CustomerChangeVisitTimeRequestV2, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Order>(`${this.basePath}/v2/customer/visit/time`,
            changeVisitTimeRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create New Visit
     * 
     * @param token Customer Access Token
     * @param newVisitRequest New Visit Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public customerCreateNewVisit(token?: string, newVisitRequest?: NewVisitRequest, observe?: 'body', reportProgress?: boolean): Observable<Order>;
    public customerCreateNewVisit(token?: string, newVisitRequest?: NewVisitRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Order>>;
    public customerCreateNewVisit(token?: string, newVisitRequest?: NewVisitRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Order>>;
    public customerCreateNewVisit(token?: string, newVisitRequest?: NewVisitRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Order>(`${this.basePath}/customer/createVisit`,
            newVisitRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Logs customer into website
     * 
     * @param token Customer Anonymous Token
     * @param customerCredentials Customer Credentials
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public customerLogin(token?: string, customerCredentials?: CustomerCredentials, observe?: 'body', reportProgress?: boolean): Observable<CustomerLoginResponse>;
    public customerLogin(token?: string, customerCredentials?: CustomerCredentials, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomerLoginResponse>>;
    public customerLogin(token?: string, customerCredentials?: CustomerCredentials, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomerLoginResponse>>;
    public customerLogin(token?: string, customerCredentials?: CustomerCredentials, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CustomerLoginResponse>(`${this.basePath}/customer/login`,
            customerCredentials,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Logs customer into website
     * 
     * @param token Customer Anonymous Token
     * @param customerCredentials Customer Credentials
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public customerLoginV2(token?: string, customerCredentials?: CustomerCredentials1, observe?: 'body', reportProgress?: boolean): Observable<CustomerLoginResponse>;
    public customerLoginV2(token?: string, customerCredentials?: CustomerCredentials1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomerLoginResponse>>;
    public customerLoginV2(token?: string, customerCredentials?: CustomerCredentials1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomerLoginResponse>>;
    public customerLoginV2(token?: string, customerCredentials?: CustomerCredentials1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CustomerLoginResponse>(`${this.basePath}/v2/customer/login`,
            customerCredentials,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Logs customer into website Version 2 Minor 1
     * 
     * @param token Customer Anonymous Token
     * @param customerCredentials Customer Credentials
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public customerLoginV2_4(token?: string, customerCredentials?: CustomerCredentials2, observe?: 'body', reportProgress?: boolean): Observable<CustomerLoginResponse>;
    public customerLoginV2_4(token?: string, customerCredentials?: CustomerCredentials2, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomerLoginResponse>>;
    public customerLoginV2_4(token?: string, customerCredentials?: CustomerCredentials2, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomerLoginResponse>>;
    public customerLoginV2_4(token?: string, customerCredentials?: CustomerCredentials2, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CustomerLoginResponse>(`${this.basePath}/v2.1/customer/login`,
            customerCredentials,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Refresh Customer Access Token
     * 
     * @param token Customer Refresh Token
     * @param customerRefreshTokenRequest CustomerRefreshTokenRequest
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public customerRefreshToken(token?: string, customerRefreshTokenRequest?: CustomerRefreshTokenRequest, observe?: 'body', reportProgress?: boolean): Observable<CustomerRefreshTokenResponse>;
    public customerRefreshToken(token?: string, customerRefreshTokenRequest?: CustomerRefreshTokenRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomerRefreshTokenResponse>>;
    public customerRefreshToken(token?: string, customerRefreshTokenRequest?: CustomerRefreshTokenRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomerRefreshTokenResponse>>;
    public customerRefreshToken(token?: string, customerRefreshTokenRequest?: CustomerRefreshTokenRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CustomerRefreshTokenResponse>(`${this.basePath}/customer/refresh-token`,
            customerRefreshTokenRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * User Update Order Services
     * 
     * @param token Customer Access Token
     * @param updateOrderServicesRequest User Update Order Services Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public customerUpdateOrderServices(token?: string, updateOrderServicesRequest?: UpdateOrderServicesRequest, observe?: 'body', reportProgress?: boolean): Observable<Order>;
    public customerUpdateOrderServices(token?: string, updateOrderServicesRequest?: UpdateOrderServicesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Order>>;
    public customerUpdateOrderServices(token?: string, updateOrderServicesRequest?: UpdateOrderServicesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Order>>;
    public customerUpdateOrderServices(token?: string, updateOrderServicesRequest?: UpdateOrderServicesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Order>(`${this.basePath}/customer/updateOrderServices`,
            updateOrderServicesRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete Customer Address
     * 
     * @param addressId Address ID
     * @param token Customer Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteAddressById(addressId: string, token?: string, observe?: 'body', reportProgress?: boolean): Observable<Customer>;
    public deleteAddressById(addressId: string, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Customer>>;
    public deleteAddressById(addressId: string, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Customer>>;
    public deleteAddressById(addressId: string, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (addressId === null || addressId === undefined) {
            throw new Error('Required parameter addressId was null or undefined when calling deleteAddressById.');
        }


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<Customer>(`${this.basePath}/customer/address/${encodeURIComponent(String(addressId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete Customer Address Version 2 Minor 1
     * 
     * @param addressId Address ID
     * @param token Customer Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteAddressById_5(addressId: string, token?: string, observe?: 'body', reportProgress?: boolean): Observable<Customer>;
    public deleteAddressById_5(addressId: string, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Customer>>;
    public deleteAddressById_5(addressId: string, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Customer>>;
    public deleteAddressById_5(addressId: string, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (addressId === null || addressId === undefined) {
            throw new Error('Required parameter addressId was null or undefined when calling deleteAddressById_5.');
        }


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<Customer>(`${this.basePath}/v2.1/customer/address/${encodeURIComponent(String(addressId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Customer forgot password by mobile or email
     * 
     * @param token Customer Anonymous Token
     * @param customerForgotPasswordRequest Forgot Password By Mobile Or Email Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public forgotPassword(token?: string, customerForgotPasswordRequest?: CustomerForgotPasswordRequest, observe?: 'body', reportProgress?: boolean): Observable<SuccessResponse>;
    public forgotPassword(token?: string, customerForgotPasswordRequest?: CustomerForgotPasswordRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessResponse>>;
    public forgotPassword(token?: string, customerForgotPasswordRequest?: CustomerForgotPasswordRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessResponse>>;
    public forgotPassword(token?: string, customerForgotPasswordRequest?: CustomerForgotPasswordRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<SuccessResponse>(`${this.basePath}/customer/forgotPassword`,
            customerForgotPasswordRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Customer forgot password by email
     * 
     * @param token Customer Anonymous Token
     * @param forgotPasswordByEmailRequest Forgot Password By Email Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public forgotPasswordByEmail(token?: string, forgotPasswordByEmailRequest?: ForgotPasswordByEmailRequest, observe?: 'body', reportProgress?: boolean): Observable<SuccessResponse>;
    public forgotPasswordByEmail(token?: string, forgotPasswordByEmailRequest?: ForgotPasswordByEmailRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessResponse>>;
    public forgotPasswordByEmail(token?: string, forgotPasswordByEmailRequest?: ForgotPasswordByEmailRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessResponse>>;
    public forgotPasswordByEmail(token?: string, forgotPasswordByEmailRequest?: ForgotPasswordByEmailRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<SuccessResponse>(`${this.basePath}/customer/forgotPasswordByEmail`,
            forgotPasswordByEmailRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve Categories
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getActiveCategories(observe?: 'body', reportProgress?: boolean): Observable<CategoriesResponse>;
    public getActiveCategories(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CategoriesResponse>>;
    public getActiveCategories(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CategoriesResponse>>;
    public getActiveCategories(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CategoriesResponse>(`${this.basePath}/customer/listAllCategories`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Customer Address
     * 
     * @param addressId Address ID
     * @param token Customer Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAddressById(addressId: string, token?: string, observe?: 'body', reportProgress?: boolean): Observable<CustomerAddress>;
    public getAddressById(addressId: string, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomerAddress>>;
    public getAddressById(addressId: string, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomerAddress>>;
    public getAddressById(addressId: string, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (addressId === null || addressId === undefined) {
            throw new Error('Required parameter addressId was null or undefined when calling getAddressById.');
        }


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CustomerAddress>(`${this.basePath}/customer/address/${encodeURIComponent(String(addressId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Customer Address Version 2 Minor 1
     * 
     * @param addressId Address ID
     * @param token Customer Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAddressById_6(addressId: string, token?: string, observe?: 'body', reportProgress?: boolean): Observable<CustomerAddress>;
    public getAddressById_6(addressId: string, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomerAddress>>;
    public getAddressById_6(addressId: string, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomerAddress>>;
    public getAddressById_6(addressId: string, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (addressId === null || addressId === undefined) {
            throw new Error('Required parameter addressId was null or undefined when calling getAddressById_6.');
        }


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CustomerAddress>(`${this.basePath}/v2.1/customer/address/${encodeURIComponent(String(addressId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve Areas
     * 
     * @param token Customer Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllAreasForCustomer(token?: string, observe?: 'body', reportProgress?: boolean): Observable<AreasResponse>;
    public getAllAreasForCustomer(token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AreasResponse>>;
    public getAllAreasForCustomer(token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AreasResponse>>;
    public getAllAreasForCustomer(token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<AreasResponse>(`${this.basePath}/customer/listAllAreas`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve Customer Cart
     * 
     * @param token Customer Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCart(token?: string, observe?: 'body', reportProgress?: boolean): Observable<Cart>;
    public getCart(token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Cart>>;
    public getCart(token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Cart>>;
    public getCart(token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Cart>(`${this.basePath}/customer/cart`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve Customer Cart V2
     * 
     * @param token Customer Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCartV2(token?: string, observe?: 'body', reportProgress?: boolean): Observable<CartV2>;
    public getCartV2(token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CartV2>>;
    public getCartV2(token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CartV2>>;
    public getCartV2(token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CartV2>(`${this.basePath}/v2/customer/cart`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * List Category Services in tree format
     * 
     * @param categoryId Category ID
     * @param token Customer Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCategoryChilds(categoryId: string, token?: string, observe?: 'body', reportProgress?: boolean): Observable<CategoryChildsResponse>;
    public getCategoryChilds(categoryId: string, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CategoryChildsResponse>>;
    public getCategoryChilds(categoryId: string, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CategoryChildsResponse>>;
    public getCategoryChilds(categoryId: string, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (categoryId === null || categoryId === undefined) {
            throw new Error('Required parameter categoryId was null or undefined when calling getCategoryChilds.');
        }


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CategoryChildsResponse>(`${this.basePath}/customer/listServices/${encodeURIComponent(String(categoryId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * List Cities
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCities(observe?: 'body', reportProgress?: boolean): Observable<CitiesResponse>;
    public getCities(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CitiesResponse>>;
    public getCities(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CitiesResponse>>;
    public getCities(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CitiesResponse>(`${this.basePath}/customer/cities`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve Orders for Customer
     * 
     * @param status Order Status
     * @param take Listed Orders ( Pagination )
     * @param skip Skipped Orders ( Pagination )
     * @param token Customer Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCustomerOrdersByStatus(status: 'open' | 'rescheduled' | 'completed' | 'cancelled', take: number, skip: number, token?: string, observe?: 'body', reportProgress?: boolean): Observable<OrdersResponse>;
    public getCustomerOrdersByStatus(status: 'open' | 'rescheduled' | 'completed' | 'cancelled', take: number, skip: number, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrdersResponse>>;
    public getCustomerOrdersByStatus(status: 'open' | 'rescheduled' | 'completed' | 'cancelled', take: number, skip: number, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrdersResponse>>;
    public getCustomerOrdersByStatus(status: 'open' | 'rescheduled' | 'completed' | 'cancelled', take: number, skip: number, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (status === null || status === undefined) {
            throw new Error('Required parameter status was null or undefined when calling getCustomerOrdersByStatus.');
        }

        if (take === null || take === undefined) {
            throw new Error('Required parameter take was null or undefined when calling getCustomerOrdersByStatus.');
        }

        if (skip === null || skip === undefined) {
            throw new Error('Required parameter skip was null or undefined when calling getCustomerOrdersByStatus.');
        }


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<OrdersResponse>(`${this.basePath}/customer/orders/${encodeURIComponent(String(status))}/${encodeURIComponent(String(take))}/${encodeURIComponent(String(skip))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve Orders for Customer version 2 minor1
     * 
     * @param status Order Status
     * @param take Listed Orders ( Pagination )
     * @param skip Skipped Orders ( Pagination )
     * @param token Customer Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCustomerOrdersByStatus_7(status: 'open' | 'rescheduled' | 'completed' | 'cancelled', take: number, skip: number, token?: string, observe?: 'body', reportProgress?: boolean): Observable<OrdersResponse>;
    public getCustomerOrdersByStatus_7(status: 'open' | 'rescheduled' | 'completed' | 'cancelled', take: number, skip: number, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrdersResponse>>;
    public getCustomerOrdersByStatus_7(status: 'open' | 'rescheduled' | 'completed' | 'cancelled', take: number, skip: number, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrdersResponse>>;
    public getCustomerOrdersByStatus_7(status: 'open' | 'rescheduled' | 'completed' | 'cancelled', take: number, skip: number, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (status === null || status === undefined) {
            throw new Error('Required parameter status was null or undefined when calling getCustomerOrdersByStatus_7.');
        }

        if (take === null || take === undefined) {
            throw new Error('Required parameter take was null or undefined when calling getCustomerOrdersByStatus_7.');
        }

        if (skip === null || skip === undefined) {
            throw new Error('Required parameter skip was null or undefined when calling getCustomerOrdersByStatus_7.');
        }


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<OrdersResponse>(`${this.basePath}/v2.1/customer/orders/${encodeURIComponent(String(status))}/${encodeURIComponent(String(take))}/${encodeURIComponent(String(skip))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve Customer Info By Id
     * 
     * @param token Customer Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCustomerProfile(token?: string, observe?: 'body', reportProgress?: boolean): Observable<Customer>;
    public getCustomerProfile(token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Customer>>;
    public getCustomerProfile(token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Customer>>;
    public getCustomerProfile(token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Customer>(`${this.basePath}/customer/profile`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve Customer Info By Id v2.1
     * 
     * @param token Customer Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCustomerProfile_8(token?: string, observe?: 'body', reportProgress?: boolean): Observable<Customer>;
    public getCustomerProfile_8(token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Customer>>;
    public getCustomerProfile_8(token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Customer>>;
    public getCustomerProfile_8(token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Customer>(`${this.basePath}/v2.1/customer/profile`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve Customer Referral Info By Id
     * 
     * @param token Customer Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCustomerReferralInfo(token?: string, observe?: 'body', reportProgress?: boolean): Observable<CustomerReferralInfo>;
    public getCustomerReferralInfo(token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomerReferralInfo>>;
    public getCustomerReferralInfo(token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomerReferralInfo>>;
    public getCustomerReferralInfo(token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CustomerReferralInfo>(`${this.basePath}/v2/customer/referral`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve Web Settings
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCustomerSettings(observe?: 'body', reportProgress?: boolean): Observable<CustomerSettings>;
    public getCustomerSettings(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomerSettings>>;
    public getCustomerSettings(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomerSettings>>;
    public getCustomerSettings(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<CustomerSettings>(`${this.basePath}/customer/settings`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve Next Available Slot
     * 
     * @param token User Access Token
     * @param getNextAvailableSlotRequest Get Next Available Slots Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getNextAvailableSlot(token?: string, getNextAvailableSlotRequest?: GetNextAvailableSlotRequest, observe?: 'body', reportProgress?: boolean): Observable<NextAvailableSlotResponse>;
    public getNextAvailableSlot(token?: string, getNextAvailableSlotRequest?: GetNextAvailableSlotRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<NextAvailableSlotResponse>>;
    public getNextAvailableSlot(token?: string, getNextAvailableSlotRequest?: GetNextAvailableSlotRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<NextAvailableSlotResponse>>;
    public getNextAvailableSlot(token?: string, getNextAvailableSlotRequest?: GetNextAvailableSlotRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<NextAvailableSlotResponse>(`${this.basePath}/customer/nextAvailableSlot`,
            getNextAvailableSlotRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Order Details By Rating Token
     * 
     * @param token Rating Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrderByRatingToken(token: string, observe?: 'body', reportProgress?: boolean): Observable<RatingViewResponse>;
    public getOrderByRatingToken(token: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RatingViewResponse>>;
    public getOrderByRatingToken(token: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RatingViewResponse>>;
    public getOrderByRatingToken(token: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (token === null || token === undefined) {
            throw new Error('Required parameter token was null or undefined when calling getOrderByRatingToken.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<RatingViewResponse>(`${this.basePath}/customer/order/token/${encodeURIComponent(String(token))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve Order Info By Id
     * 
     * @param orderId Order ID
     * @param token Customer Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrderDetailsById(orderId: string, token?: string, observe?: 'body', reportProgress?: boolean): Observable<Order>;
    public getOrderDetailsById(orderId: string, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Order>>;
    public getOrderDetailsById(orderId: string, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Order>>;
    public getOrderDetailsById(orderId: string, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling getOrderDetailsById.');
        }


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Order>(`${this.basePath}/customer/order/${encodeURIComponent(String(orderId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve Order Info By Id Version 2 Minor 1
     * 
     * @param orderId Order ID
     * @param token Customer Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrderDetailsById_9(orderId: string, token?: string, observe?: 'body', reportProgress?: boolean): Observable<Order>;
    public getOrderDetailsById_9(orderId: string, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Order>>;
    public getOrderDetailsById_9(orderId: string, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Order>>;
    public getOrderDetailsById_9(orderId: string, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling getOrderDetailsById_9.');
        }


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Order>(`${this.basePath}/v2.1/customer/order/${encodeURIComponent(String(orderId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * List Category Services in tree format
     * 
     * @param categoryId Category ID
     * @param token Customer Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getParentChilds(categoryId: string, token?: string, observe?: 'body', reportProgress?: boolean): Observable<CategoryChildsResponse>;
    public getParentChilds(categoryId: string, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CategoryChildsResponse>>;
    public getParentChilds(categoryId: string, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CategoryChildsResponse>>;
    public getParentChilds(categoryId: string, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (categoryId === null || categoryId === undefined) {
            throw new Error('Required parameter categoryId was null or undefined when calling getParentChilds.');
        }


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CategoryChildsResponse>(`${this.basePath}/customer/listServicesByParent/${encodeURIComponent(String(categoryId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * List Quick Services
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getQuickServices(observe?: 'body', reportProgress?: boolean): Observable<ServicesResponse>;
    public getQuickServices(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ServicesResponse>>;
    public getQuickServices(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ServicesResponse>>;
    public getQuickServices(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<ServicesResponse>(`${this.basePath}/customer/listQuickServices`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * List Category Services in tree format
     * 
     * @param categoryId Category ID
     * @param token Customer Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getServicesV2(categoryId: string, token?: string, observe?: 'body', reportProgress?: boolean): Observable<CategoryChildsResponseV2>;
    public getServicesV2(categoryId: string, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CategoryChildsResponseV2>>;
    public getServicesV2(categoryId: string, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CategoryChildsResponseV2>>;
    public getServicesV2(categoryId: string, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (categoryId === null || categoryId === undefined) {
            throw new Error('Required parameter categoryId was null or undefined when calling getServicesV2.');
        }


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CategoryChildsResponseV2>(`${this.basePath}/v2/customer/services/${encodeURIComponent(String(categoryId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Logs customer into website by facebook
     * 
     * @param token Customer Anonymous Token
     * @param customerFacebookAccessToken Customer Facebook Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public loginByFacebook(token?: string, customerFacebookAccessToken?: CustomerFacebookAccessToken, observe?: 'body', reportProgress?: boolean): Observable<CustomerLoginResponse>;
    public loginByFacebook(token?: string, customerFacebookAccessToken?: CustomerFacebookAccessToken, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomerLoginResponse>>;
    public loginByFacebook(token?: string, customerFacebookAccessToken?: CustomerFacebookAccessToken, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomerLoginResponse>>;
    public loginByFacebook(token?: string, customerFacebookAccessToken?: CustomerFacebookAccessToken, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CustomerLoginResponse>(`${this.basePath}/customer/loginByFacebook`,
            customerFacebookAccessToken,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Logs customer into website by facebook
     * 
     * @param token Customer Anonymous Token
     * @param customerFacebookAccessToken Customer Facebook Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public loginByFacebookV2(token?: string, customerFacebookAccessToken?: CustomerFacebookAccessToken1, observe?: 'body', reportProgress?: boolean): Observable<CustomerLoginResponse>;
    public loginByFacebookV2(token?: string, customerFacebookAccessToken?: CustomerFacebookAccessToken1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomerLoginResponse>>;
    public loginByFacebookV2(token?: string, customerFacebookAccessToken?: CustomerFacebookAccessToken1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomerLoginResponse>>;
    public loginByFacebookV2(token?: string, customerFacebookAccessToken?: CustomerFacebookAccessToken1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CustomerLoginResponse>(`${this.basePath}/v2/customer/loginByFacebook`,
            customerFacebookAccessToken,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Customer login by token
     * 
     * @param loginByTokenRequest Login By Token Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public loginByToken(loginByTokenRequest?: LoginByTokenRequest, observe?: 'body', reportProgress?: boolean): Observable<CustomerLoginResponse>;
    public loginByToken(loginByTokenRequest?: LoginByTokenRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomerLoginResponse>>;
    public loginByToken(loginByTokenRequest?: LoginByTokenRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomerLoginResponse>>;
    public loginByToken(loginByTokenRequest?: LoginByTokenRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CustomerLoginResponse>(`${this.basePath}/customer/loginByToken`,
            loginByTokenRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Customer Rate Order
     * 
     * @param rateOrderByCustomerRequest Rate Order Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public rateOrder(rateOrderByCustomerRequest?: RateOrderByCustomerRequest, observe?: 'body', reportProgress?: boolean): Observable<SuccessResponse>;
    public rateOrder(rateOrderByCustomerRequest?: RateOrderByCustomerRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessResponse>>;
    public rateOrder(rateOrderByCustomerRequest?: RateOrderByCustomerRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessResponse>>;
    public rateOrder(rateOrderByCustomerRequest?: RateOrderByCustomerRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<SuccessResponse>(`${this.basePath}/customer/rateOrder`,
            rateOrderByCustomerRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Customer Rate Order By Token
     * 
     * @param rateOrderByCustomerRequest Rate Order By Token Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public rateOrderByToken(rateOrderByCustomerRequest?: RateOrderByCustomerRequest, observe?: 'body', reportProgress?: boolean): Observable<SuccessResponse>;
    public rateOrderByToken(rateOrderByCustomerRequest?: RateOrderByCustomerRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessResponse>>;
    public rateOrderByToken(rateOrderByCustomerRequest?: RateOrderByCustomerRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessResponse>>;
    public rateOrderByToken(rateOrderByCustomerRequest?: RateOrderByCustomerRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<SuccessResponse>(`${this.basePath}/customer/rateOrderByToken`,
            rateOrderByCustomerRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Register Customer
     * 
     * @param token Customer Anonymous Token
     * @param registerCustomerRequest Register Customer Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public registerCustomer(token?: string, registerCustomerRequest?: RegisterCustomerRequest, observe?: 'body', reportProgress?: boolean): Observable<CustomerLoginResponse>;
    public registerCustomer(token?: string, registerCustomerRequest?: RegisterCustomerRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomerLoginResponse>>;
    public registerCustomer(token?: string, registerCustomerRequest?: RegisterCustomerRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomerLoginResponse>>;
    public registerCustomer(token?: string, registerCustomerRequest?: RegisterCustomerRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CustomerLoginResponse>(`${this.basePath}/customer/register`,
            registerCustomerRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Register Customer
     * 
     * @param token Customer Anonymous Token
     * @param customerRegisterRequestV2 Register Customer Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public registerV2(token?: string, customerRegisterRequestV2?: CustomerRegisterRequestV2, observe?: 'body', reportProgress?: boolean): Observable<CustomerLoginResponse>;
    public registerV2(token?: string, customerRegisterRequestV2?: CustomerRegisterRequestV2, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomerLoginResponse>>;
    public registerV2(token?: string, customerRegisterRequestV2?: CustomerRegisterRequestV2, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomerLoginResponse>>;
    public registerV2(token?: string, customerRegisterRequestV2?: CustomerRegisterRequestV2, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CustomerLoginResponse>(`${this.basePath}/v2/customer/register`,
            customerRegisterRequestV2,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Register Customer Version 2 Minor 1
     * 
     * @param token Customer Anonymous Token
     * @param customerRegisterRequestV2 Register Customer Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public registerV2_10(token?: string, customerRegisterRequestV2?: CustomerRegisterRequestV2, observe?: 'body', reportProgress?: boolean): Observable<CustomerLoginResponse>;
    public registerV2_10(token?: string, customerRegisterRequestV2?: CustomerRegisterRequestV2, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomerLoginResponse>>;
    public registerV2_10(token?: string, customerRegisterRequestV2?: CustomerRegisterRequestV2, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomerLoginResponse>>;
    public registerV2_10(token?: string, customerRegisterRequestV2?: CustomerRegisterRequestV2, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CustomerLoginResponse>(`${this.basePath}/v2.1/customer/register`,
            customerRegisterRequestV2,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * remove services by category id
     * 
     * @param token Customer Access Token
     * @param removeCategoryRequest Remove Category Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public removeCategory(token?: string, removeCategoryRequest?: RemoveCategoryRequest, observe?: 'body', reportProgress?: boolean): Observable<CartV2>;
    public removeCategory(token?: string, removeCategoryRequest?: RemoveCategoryRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CartV2>>;
    public removeCategory(token?: string, removeCategoryRequest?: RemoveCategoryRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CartV2>>;
    public removeCategory(token?: string, removeCategoryRequest?: RemoveCategoryRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CartV2>(`${this.basePath}/v2/customer/category/remove`,
            removeCategoryRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * remove coupon code
     * 
     * @param token Customer Access Token
     * @param removeCouponRequest Remove Coupon Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public removeCouponId(token?: string, removeCouponRequest?: RemoveCouponRequest, observe?: 'body', reportProgress?: boolean): Observable<Cart>;
    public removeCouponId(token?: string, removeCouponRequest?: RemoveCouponRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Cart>>;
    public removeCouponId(token?: string, removeCouponRequest?: RemoveCouponRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Cart>>;
    public removeCouponId(token?: string, removeCouponRequest?: RemoveCouponRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Cart>(`${this.basePath}/customer/coupon/remove`,
            removeCouponRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * remove coupon code
     * 
     * @param token Customer Access Token
     * @param removeCouponRequest Remove Coupon Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public removeCouponId_11(token?: string, removeCouponRequest?: RemoveCouponRequest, observe?: 'body', reportProgress?: boolean): Observable<CartV2>;
    public removeCouponId_11(token?: string, removeCouponRequest?: RemoveCouponRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CartV2>>;
    public removeCouponId_11(token?: string, removeCouponRequest?: RemoveCouponRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CartV2>>;
    public removeCouponId_11(token?: string, removeCouponRequest?: RemoveCouponRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CartV2>(`${this.basePath}/v2/customer/coupon/remove`,
            removeCouponRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remove From Cart
     * 
     * @param token Customer Access Token
     * @param removeFromCartRequest Remove From Cart Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public removeFromCart(token?: string, removeFromCartRequest?: RemoveFromCartRequest, observe?: 'body', reportProgress?: boolean): Observable<Cart>;
    public removeFromCart(token?: string, removeFromCartRequest?: RemoveFromCartRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Cart>>;
    public removeFromCart(token?: string, removeFromCartRequest?: RemoveFromCartRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Cart>>;
    public removeFromCart(token?: string, removeFromCartRequest?: RemoveFromCartRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Cart>(`${this.basePath}/customer/removeFromCart`,
            removeFromCartRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remove Inspection from Cart
     * 
     * @param categoryId Category ID
     * @param token Customer Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public removeInspectionFromCart(categoryId: string, token?: string, observe?: 'body', reportProgress?: boolean): Observable<Cart>;
    public removeInspectionFromCart(categoryId: string, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Cart>>;
    public removeInspectionFromCart(categoryId: string, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Cart>>;
    public removeInspectionFromCart(categoryId: string, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (categoryId === null || categoryId === undefined) {
            throw new Error('Required parameter categoryId was null or undefined when calling removeInspectionFromCart.');
        }


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<Cart>(`${this.basePath}/customer/cart/remove/${encodeURIComponent(String(categoryId))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Customer removes other mobile
     * 
     * @param token Customer Access Token
     * @param removeOtherMobileRequest Customer remove other mobile
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public removeOtherMobile(token?: string, removeOtherMobileRequest?: RemoveOtherMobileRequest, observe?: 'body', reportProgress?: boolean): Observable<Customer>;
    public removeOtherMobile(token?: string, removeOtherMobileRequest?: RemoveOtherMobileRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Customer>>;
    public removeOtherMobile(token?: string, removeOtherMobileRequest?: RemoveOtherMobileRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Customer>>;
    public removeOtherMobile(token?: string, removeOtherMobileRequest?: RemoveOtherMobileRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Customer>(`${this.basePath}/customer/removeOtherMobile`,
            removeOtherMobileRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request Customer Anonymous Token
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public requestAnonymousToken(observe?: 'body', reportProgress?: boolean): Observable<AnonymousTokenResponse>;
    public requestAnonymousToken(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AnonymousTokenResponse>>;
    public requestAnonymousToken(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AnonymousTokenResponse>>;
    public requestAnonymousToken(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<AnonymousTokenResponse>(`${this.basePath}/customer/anonymous-token`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Customer request email verification
     * 
     * @param token Customer Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public requestEmailVerification(token?: string, observe?: 'body', reportProgress?: boolean): Observable<SuccessResponse>;
    public requestEmailVerification(token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessResponse>>;
    public requestEmailVerification(token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessResponse>>;
    public requestEmailVerification(token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<SuccessResponse>(`${this.basePath}/customer/requestEmailVerification`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request Customer Mobile Verification
     * 
     * @param token Customer Access Token
     * @param mobileVerificationRequest Mobile Verification Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public requestMobileVerificationForChange(token?: string, mobileVerificationRequest?: MobileVerificationRequest, observe?: 'body', reportProgress?: boolean): Observable<VerifyCustomerResponse>;
    public requestMobileVerificationForChange(token?: string, mobileVerificationRequest?: MobileVerificationRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VerifyCustomerResponse>>;
    public requestMobileVerificationForChange(token?: string, mobileVerificationRequest?: MobileVerificationRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VerifyCustomerResponse>>;
    public requestMobileVerificationForChange(token?: string, mobileVerificationRequest?: MobileVerificationRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<VerifyCustomerResponse>(`${this.basePath}/customer/requestMobileVerificationForChange`,
            mobileVerificationRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request Customer Mobile Verification
     * 
     * @param token Customer Access Token
     * @param mobileVerificationRequest Mobile Verification Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public requestMobileVerificationForOtherMobile(token?: string, mobileVerificationRequest?: MobileVerificationRequest, observe?: 'body', reportProgress?: boolean): Observable<VerifyCustomerResponse>;
    public requestMobileVerificationForOtherMobile(token?: string, mobileVerificationRequest?: MobileVerificationRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VerifyCustomerResponse>>;
    public requestMobileVerificationForOtherMobile(token?: string, mobileVerificationRequest?: MobileVerificationRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VerifyCustomerResponse>>;
    public requestMobileVerificationForOtherMobile(token?: string, mobileVerificationRequest?: MobileVerificationRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<VerifyCustomerResponse>(`${this.basePath}/customer/requestMobileVerificationForOtherMobile`,
            mobileVerificationRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request Customer Mobile Verification
     * 
     * @param mobileVerificationRequest Mobile Verification Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public requestMobileVerificationForResetPassword(mobileVerificationRequest?: MobileVerificationRequest, observe?: 'body', reportProgress?: boolean): Observable<VerifyCustomerResponse>;
    public requestMobileVerificationForResetPassword(mobileVerificationRequest?: MobileVerificationRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VerifyCustomerResponse>>;
    public requestMobileVerificationForResetPassword(mobileVerificationRequest?: MobileVerificationRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VerifyCustomerResponse>>;
    public requestMobileVerificationForResetPassword(mobileVerificationRequest?: MobileVerificationRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<VerifyCustomerResponse>(`${this.basePath}/customer/requestMobileVerificationForResetPassword`,
            mobileVerificationRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Resend Verification Code
     * 
     * @param resendVerificationCodeRequest Resend Verification Code Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resendVerificationCode(resendVerificationCodeRequest?: ResendVerificationCodeRequest, observe?: 'body', reportProgress?: boolean): Observable<VerifyCustomerResponse>;
    public resendVerificationCode(resendVerificationCodeRequest?: ResendVerificationCodeRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VerifyCustomerResponse>>;
    public resendVerificationCode(resendVerificationCodeRequest?: ResendVerificationCodeRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VerifyCustomerResponse>>;
    public resendVerificationCode(resendVerificationCodeRequest?: ResendVerificationCodeRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<VerifyCustomerResponse>(`${this.basePath}/customer/resendVerificationCode`,
            resendVerificationCodeRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Reset Customer Password
     * 
     * @param token Customer Anonymous Token
     * @param resetPasswordRequest Reset Password Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resetPassword(token?: string, resetPasswordRequest?: ResetPasswordRequest, observe?: 'body', reportProgress?: boolean): Observable<CustomerLoginResponse>;
    public resetPassword(token?: string, resetPasswordRequest?: ResetPasswordRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomerLoginResponse>>;
    public resetPassword(token?: string, resetPasswordRequest?: ResetPasswordRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomerLoginResponse>>;
    public resetPassword(token?: string, resetPasswordRequest?: ResetPasswordRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CustomerLoginResponse>(`${this.basePath}/customer/resetPassword`,
            resetPasswordRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Customer Verify Email to reset password
     * 
     * @param resetPasswordByTokenRequest Reset Password By Token Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resetPasswordByEmail(resetPasswordByTokenRequest?: ResetPasswordByTokenRequest, observe?: 'body', reportProgress?: boolean): Observable<CustomerLoginResponse>;
    public resetPasswordByEmail(resetPasswordByTokenRequest?: ResetPasswordByTokenRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomerLoginResponse>>;
    public resetPasswordByEmail(resetPasswordByTokenRequest?: ResetPasswordByTokenRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomerLoginResponse>>;
    public resetPasswordByEmail(resetPasswordByTokenRequest?: ResetPasswordByTokenRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CustomerLoginResponse>(`${this.basePath}/customer/resetPasswordByEmail`,
            resetPasswordByTokenRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Customer Verify Email to reset password Version 2 Minor 1
     * 
     * @param resetPasswordByTokenRequest Reset Password By Token Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resetPasswordByEmailV21(resetPasswordByTokenRequest?: ResetPasswordByTokenRequest, observe?: 'body', reportProgress?: boolean): Observable<CustomerLoginResponse>;
    public resetPasswordByEmailV21(resetPasswordByTokenRequest?: ResetPasswordByTokenRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomerLoginResponse>>;
    public resetPasswordByEmailV21(resetPasswordByTokenRequest?: ResetPasswordByTokenRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomerLoginResponse>>;
    public resetPasswordByEmailV21(resetPasswordByTokenRequest?: ResetPasswordByTokenRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CustomerLoginResponse>(`${this.basePath}/v2.1/customer/resetPasswordByEmail`,
            resetPasswordByTokenRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Reset Customer Password Version 2 Minor 1
     * 
     * @param token Customer Anonymous Token
     * @param resetPasswordRequest Reset Password Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resetPasswordV21(token?: string, resetPasswordRequest?: ResetPasswordRequest, observe?: 'body', reportProgress?: boolean): Observable<CustomerLoginResponse>;
    public resetPasswordV21(token?: string, resetPasswordRequest?: ResetPasswordRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomerLoginResponse>>;
    public resetPasswordV21(token?: string, resetPasswordRequest?: ResetPasswordRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomerLoginResponse>>;
    public resetPasswordV21(token?: string, resetPasswordRequest?: ResetPasswordRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CustomerLoginResponse>(`${this.basePath}/v2.1/customer/resetPassword`,
            resetPasswordRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Login Customer By Magic Link
     * 
     * @param loginByMagicLinkRequest Login By Magic Link Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sendMagicLoginLinkForMobileApp(loginByMagicLinkRequest?: LoginByMagicLinkRequest, observe?: 'body', reportProgress?: boolean): Observable<SuccessResponse>;
    public sendMagicLoginLinkForMobileApp(loginByMagicLinkRequest?: LoginByMagicLinkRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessResponse>>;
    public sendMagicLoginLinkForMobileApp(loginByMagicLinkRequest?: LoginByMagicLinkRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessResponse>>;
    public sendMagicLoginLinkForMobileApp(loginByMagicLinkRequest?: LoginByMagicLinkRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<SuccessResponse>(`${this.basePath}/v2/customer/loginByMagicLink`,
            loginByMagicLinkRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Customer Address
     * 
     * @param token Customer Access Token
     * @param updateCustomerAddressRequest Update Customer Address Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateAddress(token?: string, updateCustomerAddressRequest?: UpdateCustomerDetailedAddressRequest, observe?: 'body', reportProgress?: boolean): Observable<Customer>;
    public updateAddress(token?: string, updateCustomerAddressRequest?: UpdateCustomerDetailedAddressRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Customer>>;
    public updateAddress(token?: string, updateCustomerAddressRequest?: UpdateCustomerDetailedAddressRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Customer>>;
    public updateAddress(token?: string, updateCustomerAddressRequest?: UpdateCustomerDetailedAddressRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Customer>(`${this.basePath}/customer/updateAddress`,
            updateCustomerAddressRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Customer Address Version 2 Minor 1
     * 
     * @param token Customer Access Token
     * @param updateCustomerAddressRequest Update Customer Address Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateAddress_12(token?: string, updateCustomerAddressRequest?: UpdateCustomerDetailedAddressRequest, observe?: 'body', reportProgress?: boolean): Observable<Customer>;
    public updateAddress_12(token?: string, updateCustomerAddressRequest?: UpdateCustomerDetailedAddressRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Customer>>;
    public updateAddress_12(token?: string, updateCustomerAddressRequest?: UpdateCustomerDetailedAddressRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Customer>>;
    public updateAddress_12(token?: string, updateCustomerAddressRequest?: UpdateCustomerDetailedAddressRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Customer>(`${this.basePath}/v2.1/customer/updateAddress`,
            updateCustomerAddressRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Cart
     * 
     * @param token Customer Access Token
     * @param updateCartRequest UpdateCartRequest
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCart(token?: string, updateCartRequest?: UpdateCartRequest, observe?: 'body', reportProgress?: boolean): Observable<Cart>;
    public updateCart(token?: string, updateCartRequest?: UpdateCartRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Cart>>;
    public updateCart(token?: string, updateCartRequest?: UpdateCartRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Cart>>;
    public updateCart(token?: string, updateCartRequest?: UpdateCartRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Cart>(`${this.basePath}/customer/updateCart`,
            updateCartRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * update cart address
     * 
     * @param token Customer Access Token
     * @param updateCartAddressRequest Update Cart Address Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCartAddress(token?: string, updateCartAddressRequest?: UpdateCartAddressRequest, observe?: 'body', reportProgress?: boolean): Observable<Cart>;
    public updateCartAddress(token?: string, updateCartAddressRequest?: UpdateCartAddressRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Cart>>;
    public updateCartAddress(token?: string, updateCartAddressRequest?: UpdateCartAddressRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Cart>>;
    public updateCartAddress(token?: string, updateCartAddressRequest?: UpdateCartAddressRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Cart>(`${this.basePath}/customer/cart/address`,
            updateCartAddressRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Cart
     * 
     * @param token Customer Access Token
     * @param updateCartRequest UpdateCartRequest
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCartV2(token?: string, updateCartRequest?: UpdateCartRequest, observe?: 'body', reportProgress?: boolean): Observable<CartV2>;
    public updateCartV2(token?: string, updateCartRequest?: UpdateCartRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CartV2>>;
    public updateCartV2(token?: string, updateCartRequest?: UpdateCartRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CartV2>>;
    public updateCartV2(token?: string, updateCartRequest?: UpdateCartRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CartV2>(`${this.basePath}/v2/customer/cart`,
            updateCartRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Customer Profile
     * 
     * @param token Customer Access Token
     * @param updateCustomerProfileRequest Update Customer Profile Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProfile(token?: string, updateCustomerProfileRequest?: UpdateCustomerProfileRequest, observe?: 'body', reportProgress?: boolean): Observable<Customer>;
    public updateProfile(token?: string, updateCustomerProfileRequest?: UpdateCustomerProfileRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Customer>>;
    public updateProfile(token?: string, updateCustomerProfileRequest?: UpdateCustomerProfileRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Customer>>;
    public updateProfile(token?: string, updateCustomerProfileRequest?: UpdateCustomerProfileRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Customer>(`${this.basePath}/customer/profile`,
            updateCustomerProfileRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Customer Profile version 2 minor 1
     * 
     * @param token Customer Access Token
     * @param updateCustomerProfileRequest Update Customer Profile Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProfile_13(token?: string, updateCustomerProfileRequest?: UpdateCustomerProfileRequest, observe?: 'body', reportProgress?: boolean): Observable<Customer>;
    public updateProfile_13(token?: string, updateCustomerProfileRequest?: UpdateCustomerProfileRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Customer>>;
    public updateProfile_13(token?: string, updateCustomerProfileRequest?: UpdateCustomerProfileRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Customer>>;
    public updateProfile_13(token?: string, updateCustomerProfileRequest?: UpdateCustomerProfileRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Customer>(`${this.basePath}/v2.1/customer/profile`,
            updateCustomerProfileRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Verify Customer
     * 
     * @param token Customer Anonymous Token
     * @param verifyCustomerRequest Verify Customer Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public verifyAccount(token?: string, verifyCustomerRequest?: VerifyCustomerRequest, observe?: 'body', reportProgress?: boolean): Observable<VerifyCustomerResponse>;
    public verifyAccount(token?: string, verifyCustomerRequest?: VerifyCustomerRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VerifyCustomerResponse>>;
    public verifyAccount(token?: string, verifyCustomerRequest?: VerifyCustomerRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VerifyCustomerResponse>>;
    public verifyAccount(token?: string, verifyCustomerRequest?: VerifyCustomerRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<VerifyCustomerResponse>(`${this.basePath}/customer/verifyAccount`,
            verifyCustomerRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Customer Verify Email
     * 
     * @param customerVerifyEmailRequest Customer Verify Email Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public verifyEmail(customerVerifyEmailRequest?: CustomerVerifyEmailRequest, observe?: 'body', reportProgress?: boolean): Observable<SuccessResponse>;
    public verifyEmail(customerVerifyEmailRequest?: CustomerVerifyEmailRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessResponse>>;
    public verifyEmail(customerVerifyEmailRequest?: CustomerVerifyEmailRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessResponse>>;
    public verifyEmail(customerVerifyEmailRequest?: CustomerVerifyEmailRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<SuccessResponse>(`${this.basePath}/customer/verifyEmail`,
            customerVerifyEmailRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Verify Customer Mobile
     * 
     * @param verifyMobileRequest Verify Mobile Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public verifyMobile(verifyMobileRequest?: VerifyMobileRequest, observe?: 'body', reportProgress?: boolean): Observable<SuccessResponse>;
    public verifyMobile(verifyMobileRequest?: VerifyMobileRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessResponse>>;
    public verifyMobile(verifyMobileRequest?: VerifyMobileRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessResponse>>;
    public verifyMobile(verifyMobileRequest?: VerifyMobileRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<SuccessResponse>(`${this.basePath}/customer/verifyMobile`,
            verifyMobileRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Verify Customer Mobile to change
     * 
     * @param token Customer Access Token
     * @param verifyMobileRequest Verify Mobile Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public verifyMobileToAddToOtherMobiles(token?: string, verifyMobileRequest?: VerifyMobileRequest, observe?: 'body', reportProgress?: boolean): Observable<Customer>;
    public verifyMobileToAddToOtherMobiles(token?: string, verifyMobileRequest?: VerifyMobileRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Customer>>;
    public verifyMobileToAddToOtherMobiles(token?: string, verifyMobileRequest?: VerifyMobileRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Customer>>;
    public verifyMobileToAddToOtherMobiles(token?: string, verifyMobileRequest?: VerifyMobileRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Customer>(`${this.basePath}/customer/verifyMobileToAddToOtherMobiles`,
            verifyMobileRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Verify Customer Mobile to change
     * 
     * @param verifyMobileRequest Verify Mobile Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public verifyMobileToChange(verifyMobileRequest?: VerifyMobileRequest, observe?: 'body', reportProgress?: boolean): Observable<Customer>;
    public verifyMobileToChange(verifyMobileRequest?: VerifyMobileRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Customer>>;
    public verifyMobileToChange(verifyMobileRequest?: VerifyMobileRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Customer>>;
    public verifyMobileToChange(verifyMobileRequest?: VerifyMobileRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Customer>(`${this.basePath}/customer/verifyMobileToChange`,
            verifyMobileRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Verify Customer Mobile to login
     * 
     * @param verifyMobileRequest Verify Mobile Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public verifyMobileToLogin(verifyMobileRequest?: VerifyMobileRequest, observe?: 'body', reportProgress?: boolean): Observable<CustomerLoginResponse>;
    public verifyMobileToLogin(verifyMobileRequest?: VerifyMobileRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomerLoginResponse>>;
    public verifyMobileToLogin(verifyMobileRequest?: VerifyMobileRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomerLoginResponse>>;
    public verifyMobileToLogin(verifyMobileRequest?: VerifyMobileRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CustomerLoginResponse>(`${this.basePath}/customer/verifyMobileToLogin`,
            verifyMobileRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
