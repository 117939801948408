import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RatingRoutingModule } from './rating-routing.module';
import { RatingComponent } from './rating/rating.component';
import { SharedUiModule } from '../sharedui/sharedui.module';
import { SharedModule } from '../shared';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HelpersModule } from '../helpers/helpers.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({

  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    HelpersModule,
    TranslateModule,
    RatingRoutingModule,
    SharedModule,
    SharedUiModule
  ],
  declarations: [RatingComponent]
})
export class RatingModule { }
