import { Directive, ElementRef, OnInit } from "@angular/core";

@Directive({ selector: '[FkdAutoPlay]' })
export class AutoplayVideoDirective implements OnInit {

  constructor(public element: ElementRef) { }

  public ngOnInit(): void {
    let vid = this.element.nativeElement;
    vid.muted = true;
    vid.play();
  }
}