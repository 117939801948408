/**
 * Filkhedma API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface ChangeOrderTimeRequest { 
    /**
     * Order ID
     */
    orderId: string;
    /**
     * New Order Time
     */
    orderTime: string;
    /**
     * Change visit time reason
     */
    reason: ChangeOrderTimeRequest.ReasonEnum;
}
export namespace ChangeOrderTimeRequest {
    export type ReasonEnum = 'customer_did_not_show_up' | 'customer_asked_to_change' | 'technician_did_not_show_up' | 'technician_asked_to_change';
    export const ReasonEnum = {
        CustomerDidNotShowUp: 'customer_did_not_show_up' as ReasonEnum,
        CustomerAskedToChange: 'customer_asked_to_change' as ReasonEnum,
        TechnicianDidNotShowUp: 'technician_did_not_show_up' as ReasonEnum,
        TechnicianAskedToChange: 'technician_asked_to_change' as ReasonEnum
    };
}
