/**
 * Filkhedma API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface FilterTechniciansBusySlotsRequest { 
    /**
     * Category ID
     */
    categoryId?: string;
    /**
     * Technician ID
     */
    technicianId?: string;
    /**
     * Date
     */
    date?: string;
    /**
     * Technician Type
     */
    technicianType?: FilterTechniciansBusySlotsRequest.TechnicianTypeEnum;
    take?: number;
    skip?: number;
    sort?: FilterTechniciansBusySlotsRequest.SortEnum;
}
export namespace FilterTechniciansBusySlotsRequest {
    export type TechnicianTypeEnum = 'inhouse' | 'marketplace';
    export const TechnicianTypeEnum = {
        Inhouse: 'inhouse' as TechnicianTypeEnum,
        Marketplace: 'marketplace' as TechnicianTypeEnum
    };
    export type SortEnum = 'available' | 'busy';
    export const SortEnum = {
        Available: 'available' as SortEnum,
        Busy: 'busy' as SortEnum
    };
}
