/**
 * Filkhedma API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface PartyFinancialPricing { 
    /**
     * Visit Party Financial Revenue
     */
    revenue?: number;
    /**
     * Visit Party Financial Estimated Revenue
     */
    estimatedRevenue?: number;
    /**
     * Visit Party Financial Balance Change
     */
    balanceChange?: number;
    /**
     * Visit Party Financial Type
     */
    type?: PartyFinancialPricing.TypeEnum;
}
export namespace PartyFinancialPricing {
    export type TypeEnum = 'customer' | 'provider' | 'technician';
    export const TypeEnum = {
        Customer: 'customer' as TypeEnum,
        Provider: 'provider' as TypeEnum,
        Technician: 'technician' as TypeEnum
    };
}
