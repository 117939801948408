import { AuthenticationService } from './../../shared/services/authenication/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { CustomerService } from './../../swagger/api/customer.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { ResetPasswordByTokenRequest } from '../../swagger/model/resetPasswordByTokenRequest';
import { ToastrConfig, ToastrService } from 'ngx-toastr';
import { AUTH_TYPE } from '../../shared/utilities/defines/app-types';

@Component({
  selector: 'change-password-by-email',
  templateUrl: './change-password-by-email.component.html',
  styleUrls: ['./change-password-by-email.component.scss']
})
export class ChangePasswordByEmailComponent implements OnInit {
  public changePasswordForm: FormGroup;
  public newPasswordSent = false;
  public errorMessage: string;
  public token: string;
  public toastConfig: Partial<ToastrConfig> = {
    timeOut: 6000
  };
  /**
   *
   * @param route
   * @param customerService
   * @param toastr
   * @param authenticationService
   * @param translationService
   * @param formBuilder
   */
  constructor(
    private route: ActivatedRoute,
    private customerService: CustomerService,
    private toastr: ToastrService,
    private authenticationService: AuthenticationService,
    private translationService: TranslateService,
    private formBuilder: FormBuilder
  ) {
  }

  ngOnInit() {
    this.route.params.subscribe(
      params => {
        if (params['token']) {
          this.token = params['token'];
        }
      }
    );
    this.loadForm();
  }

  loadForm() {
    this.changePasswordForm = this.formBuilder.group({
      password: '',
      rePassword: ['', this.rePassword]
    });
  }
  rePassword(c: FormControl) {
    if (c === undefined || c.parent === undefined || c.value === '') { return; }
    const lastPassword = c.parent.controls['rePassword'].value;
    if (c.value !== '' && c.parent.controls['password'].value === lastPassword) {
      return null;
    } else {
      return { lastPassword: true };
    }
  }
  changePassword(formData) {
    if (formData) {
      this.newPasswordSent = true;
      const resetPasswordByTokenReq: ResetPasswordByTokenRequest = {
        token: this.token,
        newPassword: formData.password,
        confirmPassword: formData.rePassword,
      };
      this.customerService.resetPasswordByEmail(resetPasswordByTokenReq).subscribe(
        res => {
          this.newPasswordSent = false;
          this.authenticationService.login(res, AUTH_TYPE.Login);
          this.toastr.success(this.translationService.instant('registration_done_successfully'),
            `${this.translationService.instant('hello')} ${res.customerFirstName}`, this.toastConfig);
        },
        err => {
          this.newPasswordSent = false;
        },
      );
    }
  }

}
