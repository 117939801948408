import { TOKEN } from './../../shared/utilities/defines/index';
import { Router } from '@angular/router';
import { StorageService } from './../../shared/services/storage/storage.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ToastrConfig, ToastrService } from 'ngx-toastr';
import { CustomerService } from '../../swagger/api/customer.service';
import { AuthService as AngularxAuthService, FacebookLoginProvider } from 'angularx-social-login';
import { CustomerCredentials } from '../../swagger/model/customerCredentials';
import { CustomerFacebookAccessToken } from '../../swagger/model/customerFacebookAccessToken';
import { EventsService, EventType } from '../../shared/services/events/events.service';
import { TranslateService } from '@ngx-translate/core';
import { AUTH_TYPE, ERROR_TYPES } from '../../shared/utilities/defines/app-types';
import { UserService, VerificatinDataModel } from '../../user/services/user.service';
import { AuthenticationService } from '../../shared/services/authenication/authentication.service';
import { routeConfig } from '../../shared/utilities/pages-config';
import { LoginByMagicLinkRequest } from '../../swagger';
import { ModalDirective } from 'ngx-bootstrap';

interface LoginFormType {
  emailOrMobile: string;
  password: string;
}
@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {


  public loginUserLoading = false;
  public isLoading = false;
  public isLoginByTokenLoading = false;
  public errorMessage: string;

  public form: FormGroup;
  public toastConfig: Partial<ToastrConfig> = {
    timeOut: 3000,
  };
  public isDisabled: boolean;
  @ViewChild('showLoginByTokenModal') showLoginByTokenModal: ModalDirective;
  constructor(
    private customerService: CustomerService,
    public authenticationService: AuthenticationService,
    private toastr: ToastrService,
    private angularxAuthService: AngularxAuthService,
    private userService: UserService,
    private eventsService: EventsService,
    private translationService: TranslateService,
    private formBuilder: FormBuilder,
    private storageService: StorageService,
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.loadForm();
  }
  loadForm() {
    this.form = this.formBuilder.group({
      emailOrMobile: '',
      password: ''
    });
    if (this.form.valid) {
      this.isDisabled = false;
    } else {
      this.isDisabled = true;
    }
  }
  checkMobileValidation() {
    if ((/[\u0660-\u0669]/).test(this.form.controls['emailOrMobile'].value)) {
      this.toastr.error(this.translationService.instant('you_cannot_registar_number_with_arabic'),
        this.translationService.instant('an_error_occurred'), this.toastConfig);
      return false;
    }
    return true;
  }
  hideModal() {
    this.showLoginByTokenModal.hide();
  }
  openModal() {
    this.showLoginByTokenModal.show();
  }
  login(formData: LoginFormType) {
    const token = this.storageService.getStorage(TOKEN);
    if (this.form.valid && this.checkMobileValidation()) {
      this.loginUserLoading = true;
      const customerCredentials: CustomerCredentials = {
        username: formData.emailOrMobile,
        password: formData.password,
      };
      this.customerService.customerLogin(token, customerCredentials).subscribe(
        res => {
          this.authenticationService.login(res, AUTH_TYPE.Login);
          this.loginUserLoading = false;
          this.errorMessage = '';
          const hello = this.translationService.instant('hello');
          this.toastr.success(this.translationService.instant('registration_done_successfully'),
            `${hello} ${res.customerFirstName}`, this.toastConfig);
          this.eventsService.publish(EventType.LoggedIn, res);

        },
        err => {
          if (window.innerWidth <= 1024) {
            this.openModal();
          }
          this.loginUserLoading = false;
          const error = err.error;
          if (err.status === 310) {
            this.verifyOldCustomer(error.verificationId, this.form.controls['emailOrMobile'].value);
            return;
          }
          if (error) {
            if (error.msg === ERROR_TYPES.INVALID_AUTH) {
              this.errorMessage = this.translationService.instant('invalid_name_password');
            } else if (error.msg === ERROR_TYPES.EMAIL_NOT_VERIFIED) {
              this.errorMessage = this.translationService.instant('email_not_activitied_login_with_mobile');
            } else {
              this.toastr.error(this.translationService.instant('please_try_later'),
                this.translationService.instant('an_error_occurred'), this.toastConfig);
            }
          }
        },
      );
    }
  }

  facebookLogin() {
    this.isLoading = true;
    const token = this.storageService.getStorage(TOKEN);
    this.angularxAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then(
      user => {
        if (user) {
          const fbAccessToken: CustomerFacebookAccessToken = {
            accessToken: user.authToken,
          };
          this.customerService.loginByFacebook(token, fbAccessToken).subscribe(
            res => {
              this.toastr.success(this.translationService.instant('registration_done_successfully'),
                `${this.translationService.instant('hello')} ${user.name}`, this.toastConfig);
              this.authenticationService.login(res, AUTH_TYPE.Login);
              this.isLoading = false;

            },
            err => {
              if (err.error && err.error.msg === ERROR_TYPES.INVALID_AUTH) {
                this.toastr.error(this.translationService.instant('you_are_not_registar'),
                  this.translationService.instant('an_error_occurred'), this.toastConfig);
                this.userService.registerByFaceBook = true;
                this.navigateToRegistration();
                this.isLoading = false;

              }
            },
          );
        }
      },
      err => {
        this.isLoading = false;
        this.toastr.error(this.translationService.instant('please_try_later'),
          this.translationService.instant('an_error_occurred'), this.toastConfig);
      },
    );
  }
  loginByToken(formData) {
    this.isLoginByTokenLoading = true;
    const loginByMagicLinkRequest: LoginByMagicLinkRequest = {
      username: formData.emailOrMobile,
    };
    this.customerService.sendMagicLoginLinkForMobileApp(loginByMagicLinkRequest).subscribe(res => {
      this.toastr.success(this.translationService.instant('sms sent Successfully'));
      this.isLoginByTokenLoading = false;
    }, err => {
      this.toastr.error(this.translationService.instant('invalid email/mobile number'));
      this.isLoginByTokenLoading = false;
    });
  }
  verifyOldCustomer(verificationId, mobile) {
    const verificationData: VerificatinDataModel = {
      verificationId: verificationId,
      mobile: mobile,
      type: 'oldUserMobile',
    };
    this.userService.setVerificationData(verificationData);
    this.router.navigate([routeConfig.verify_mobile.route]);
  }
  navigateToForgetPassword() {
    this.router.navigate([routeConfig.forget_password.route]);
  }
  navigateToRegistration() {
    this.router.navigate([routeConfig.register.route]);
  }
}
