/**
 * Filkhedma API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CouponPolicy } from './couponPolicy';


export interface Coupon { 
    couponId?: string;
    code?: string;
    type?: Coupon.TypeEnum;
    value?: number;
    upto?: number;
    recurrence?: number;
    policies?: Array<CouponPolicy>;
    startTime?: string;
    endTime?: string;
    createdAt?: string;
    source?: string;
    updatedAt?: string;
}
export namespace Coupon {
    export type TypeEnum = 'percentage';
    export const TypeEnum = {
        Percentage: 'percentage' as TypeEnum
    };
}
