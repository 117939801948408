import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../../../helpers/translate service/language.service';
import { Router } from '@angular/router';
import { routeConfig } from '../../../shared/utilities/pages-config';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css']
})
export class HelpComponent implements OnInit {

  constructor(public languageService: LanguageService,
    private router: Router) { }

  ngOnInit() {
  }
  navigateToContactUs() {
    this.router.navigate([routeConfig.contact_us.route]);
  }
  navigateToOrder() {
    this.router.navigate([routeConfig.order.route]);
  }
  navigateToCompletedOrder() {
    this.router.navigate([routeConfig.order.completed.route]);
  }
  navigateToServices() {
    this.router.navigate([routeConfig.services.route]);
  }
  navigateToPrice() {
    this.router.navigate([routeConfig.prices.route]);
  }
}
