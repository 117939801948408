import { EventEmitter } from '@angular/core';
import { Output } from '@angular/core';
import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, AbstractControl, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'fkd-radio-button',
  templateUrl: './fkd-radio-button.component.html',
  styleUrls: ['./fkd-radio-button.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FkdRadioButtonComponent),
      multi: true
    }
  ]
})
export class FkdRadioButtonComponent implements ControlValueAccessor {

  /** Radio Label  */
  @Input() label: string;
  @Input() checkIcon = true;

  /** Radio Name  */
  @Input() name: string;
  /** Radio Name  */
  @Input() hasTitle = false;
  /** Radio Name  */
  @Input() title: string;

  /** Radio Value */
  @Input() radioValue: any;
  /** Radio is checked  */
  @Input() checked: boolean;
  @Input() labelItem = true;
  @Input() disabled = false;
  @Input() id: string;
  @Input() icon: string;
  /** Emit on Checkbox change */
  @Output() changed = new EventEmitter<boolean>();

  /** to hold the inner value for the input */
  innerValue: boolean;

  /** function template */
  private onTouchedCallback: () => {};

  /** function template */
  private onChangeCallback = (_: any) => { };

  /** get accessor including */
  @Input() get value(): any {
    return this.innerValue;
  }

  /** set accessor including call the onChange callback */
  set value(v: any) {
    this.innerValue = this.radioValue;
    this.onChangeCallback(this.radioValue);
  }
  checkboxChange() {
    this.changed.emit(this.value);
  }
  //#region implementing ControlValueAccessor

  /**
   * this function form implementing ControlValueAccessor
   * responsible for Writes a new value to the element.
   * @param obj
   */
  writeValue(obj: any): void {
    this.innerValue = obj;
    this.onChangeCallback(this.radioValue);
    this.changed.emit(this.value);
  }

  /**
   * this function form implementing ControlValueAccessor
   * @param fn
   */
  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  /**
   * this function form implementing ControlValueAccessor
   * @param fn
   */
  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  //#endregion

  //#region implementing ControlValueAccessor
}

