import { AuthenticationService } from './../../shared/services/authenication/authentication.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { CustomerService, CustomerLoginResponse } from '../../swagger';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginByTokenRequest } from '../../swagger/model/loginByTokenRequest';
import { routeConfig } from '../../../app/shared/utilities/pages-config';
import { EventsService, EventType } from '../../../app/shared/services/events/events.service';
import { AUTH_TYPE } from '../../shared/utilities/defines/app-types';
import { ModalDirective } from 'ngx-bootstrap';

@Component({
  selector: 'login-by-token',
  templateUrl: './login-by-token.component.html',
  styleUrls: ['./login-by-token.component.scss']
})
export class LoginByTokenComponent implements OnInit {

  loginByToken = true;
  error = false;
  isLoggedin = false;
  @ViewChild('loginWarning') loginWarning: ModalDirective;
  customerLoginRespons: CustomerLoginResponse;
  isLoginByTokenLoading = false;


  constructor(
    private customerService: CustomerService,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private eventsService: EventsService
  ) { }

  ngOnInit() {
    this.authenticationService.isAuthenticated.subscribe(isAuthenticated => {
      this.isLoggedin = isAuthenticated;
    });
    this.route.params.subscribe(
      params => {
        if (params['token']) {
          this.verifyLoginByToken(params['token']);
        }
      }
    );
  }
  proccessedWithLogin() {
    this.isLoginByTokenLoading = true;
    if (this.isLoggedin) {
      this.authenticationService.unAuthenticateUser();
    }
    this.authenticationService.login(this.customerLoginRespons, AUTH_TYPE.Login);
    this.eventsService.publish(EventType.LoggedIn, this.customerLoginRespons);
    this.router.navigate([routeConfig.services.route]);
  }
  verifyLoginByToken(token) {
    this.loginByToken = true;
    this.error = false;
    const customerVerifyEmailReq: LoginByTokenRequest = {
      token: token,
    };
    this.customerService.loginByToken(customerVerifyEmailReq).subscribe(
      res => {
        this.customerLoginRespons = res;
        this.loginByToken = false;
        this.error = false;
        if (this.isLoggedin) {
          this.openModal();
        } else {
          this.proccessedWithLogin();
        }
        this.isLoginByTokenLoading = false;
      },
      err => {
        this.loginByToken = false;
        this.error = true;
      },
    );
  }
  hideModal() {
    this.loginWarning.hide();
    this.router.navigate([routeConfig.home.route]);
  }
  openModal() {
    this.loginWarning.show();
  }
}
