import { ChangePasswordByEmailComponent } from './change-password-by-email/change-password-by-email.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { RegistrationComponent } from './registration/registration.component';
import { LoginComponent } from './login/login.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { routeConfig } from '../shared/utilities/pages-config';
import { LoginByTokenComponent } from './login-by-token/login-by-token.component';

const routes: Routes = [
    {
        path: routeConfig.login_by_token.name,
        component: LoginByTokenComponent,
    },
    {
        path: routeConfig.login.name,
        component: LoginComponent,
    },
    {
        path: routeConfig.register.name,
        component: RegistrationComponent,
    },
    {
        path: routeConfig.forget_password.name,
        component: ForgetPasswordComponent,
    },
    {
        path: routeConfig.reset_passowrd.name,
        component: ChangePasswordByEmailComponent,
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AuthenticationRoutingModule { }
