import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfigLoaderService {

  public regex: any;
  constructor(
    private httpClient: HttpClient
  ) { }
  /** get all regex after application initialized */
  public getRegex(): Observable<any> {
    const data = 'assets/configration/regex-config.json';
    if (this.regex) {
      return of(this.regex);
    } else {
      return this.httpClient.get(data).pipe(tap(response => {
        this.regex = response;
      }));
    }
  }
}
