import { AuthenticationService } from './../../../shared/services/authenication/authentication.service';
import { TOKEN } from './../../../shared/utilities/defines/index';
import { Order } from "./../../../swagger/model/order";
import {
  Component,
  OnInit,
  EventEmitter
} from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Category } from "../../../swagger/model/category";
import { CustomerService } from "../../../swagger/api/customer.service";
import { CategoryChildsResponse } from "../../../swagger/model/categoryChildsResponse";
import { CartsService } from "../../sharedServices/carts.service";
import { UpdateCartRequest } from "../../../swagger/model/updateCartRequest";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { MixpanelService } from "../../../shared/services/mixpanel/mixpanel.service";
import { ToastrConfig, ToastrService } from "ngx-toastr";
import { LanguageService } from "../../../helpers/translate service/language.service";
import { TranslateService } from "@ngx-translate/core";
import { GoogoleAnalyticsService, PageView } from "../../../shared/services/google-analytics/googole-analytics.service";
import { routeConfig } from "../../../shared/utilities/pages-config";
import { ERROR_TYPES } from "../../../shared/utilities/defines/app-types";
import { StorageService } from "../../../shared/services/storage/storage.service";
import { USER_LANGUAGE_DIRECTION } from "../../../shared/utilities/defines";
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';

interface MyCategoryChildsResponse extends CategoryChildsResponse {
  qty?: number;
}
@Component({
  selector: "app-category-services-modal",
  templateUrl: "./category-services-modal.component.html",
  styleUrls: ["./category-services-modal.component.scss"]
})
export class CategoryServicesModalComponent implements OnInit {
  modalRef: BsModalRef;
  category: Category;
  categoryId: string;
  categoryChilds: MyCategoryChildsResponse;
  updateOrder = false;
  subChilds: MyCategoryChildsResponse;
  childs: MyCategoryChildsResponse[];
  categoriesNames: any[] = [];
  inputCategoriesNames: any[] = [];
  addNewServicesEE = new EventEmitter();
  closeEE = new EventEmitter();
  olderOrder: Order;
  categoryImage: string;
  onHide: Subject<any> = new Subject<any>();
  categoryServicesModalRef: BsModalRef;
  canAddCategoriesService: string;
  canShowSubcategories = true;
  canShowServices = false;
  orderInspectionLoading = false;
  toastConfig: Partial<ToastrConfig> = {
    timeOut: 3000,
  };
  dir: string;
  public isAuthenticated: boolean;

  constructor(
    private modalService: BsModalService,
    private customerService: CustomerService,
    public cartsService: CartsService,
    public authenticationService: AuthenticationService,
    private router: Router,
    private _bsModalRef: BsModalRef,
    private mixpanelService: MixpanelService,
    private googoleAnalyticsService: GoogoleAnalyticsService,
    private storageService: StorageService,
    private toastr: ToastrService,
    public languageService: LanguageService,
    private translation: TranslateService
  ) {
    this.mixpanelService.trackEvent('Customer Open Category Services', { pageName: 'Category Services' }).subscribe((params) => { });
  }

  ngOnInit() {
    this.authenticationService.isAuthenticated.pipe(distinctUntilChanged()).subscribe((isLoggedIn: boolean) => {
      this.isAuthenticated = isLoggedIn;
    });
    this.dir = this.storageService.getStorage(USER_LANGUAGE_DIRECTION);
    if (this.inputCategoriesNames) {
      this.categoriesNames = this.inputCategoriesNames;
    }
    if (this.categoryId) {
      this.getChoosedCategory(this.categoryId);
      this.cartsService.getCarts();
      this.getCategoryChilds(this.categoryId);
    } else if (this.category) {
      if (!this.categoriesNames) {
        this.categoriesNames = [];
      }
      this.categoriesNames.push({ ar: this.category.nameAr, en: this.category.nameEn });
      this.getCategoryChilds(this.category.categoryId);
    }
  }
  navigateToLogin() {
    this.router.navigate([routeConfig.login.route]);
    this.closeModals();
  }
  openModal(categoryOption: string) {
    if (this.inputCategoriesNames) {
      this.categoriesNames = this.inputCategoriesNames;
    }
    this.cartsService.getCarts();
    if (this.category && categoryOption === "category") {
      this.categoriesNames = [];
      this.categoriesNames.push({ ar: this.category.nameAr, en: this.category.nameEn });
      this.getCategoryChilds(this.category.categoryId);
    } else if (this.categoryChilds) {
      this.categoriesNames.push({ ar: this.categoryChilds.nameAr, en: this.categoryChilds.nameEn });
      this.subChilds = this.categoryChilds;
      this.subChilds.qty = 1;
      this.childs = this.subChilds.childs;
      for (const child of this.childs) {
        child.qty = 1;
      }
    }
  }

  openModalFromLandingPage(categoryId) {
    this.getChoosedCategory(categoryId);
    this.cartsService.getCarts();
    this.getCategoryChilds(categoryId);
  }

  getChoosedCategory(categoryId) {
    this.canShowSubcategories = false;
    this.customerService.getActiveCategories().subscribe(
      res => {
        this.canShowSubcategories = true;
        this.category = res.categories.filter(
          i => i.categoryId === categoryId
        )[0];
        this.categoriesNames.push({ ar: this.category.nameAr, en: this.category.nameEn });
        this.categoryImage = this.category.inactiveImage;
        if (this.category.nameEn) {
          const page: PageView = {
            page: `${routeConfig.order_category.route}${this.getFriendlyName(this.category.nameEn)}`,
            title: 'Category Services',
          };
          this.googoleAnalyticsService.sendPageView(page);
        }
      },
      err => {
        this.canShowSubcategories = true;
      }
    );
  }

  getCategoryChilds(categoryId: string) {
    const token = this.storageService.getStorage(TOKEN);
    this.canShowServices = false;
    this.customerService.getParentChilds(categoryId, token).subscribe(
      res => {
        this.canShowServices = true;
        this.categoryChilds = res;
        this.subChilds = res;
        this.childs = this.subChilds.childs;
        for (const child of this.childs) {
          (<MyCategoryChildsResponse>child).qty = 1;
        }
        if (res.nameEn) {
          const page: PageView = {
            page: `${routeConfig.order_category.route}${this.getFriendlyName(res.nameEn)}`,
            title: 'Category Services',
          };
          this.googoleAnalyticsService.sendPageView(page);
        }
      },
      err => {
        this.canShowServices = true;
      }
    );
  }

  getFriendlyName(categoryName: string) {
    if (categoryName) {
      return categoryName.split(' / ').join('_').split(' ').join('_').toLowerCase();
    }
  }



  addServices(serviceId, qty) {
    if (this.updateOrder === true) {
      this.addNewServicesEE.emit({ serviceId: serviceId, qty: qty });
    } else {
      this.addToCart(serviceId, qty);
    }
  }

  addToCart(serviceId, qty) {
    this.googoleAnalyticsService.sendEvent({
      category: "cart",
      action: "Add To Cart",
      label: "custom_filkhedma_label",
    });
    this.canAddCategoriesService = serviceId;
    const updateCartReq: UpdateCartRequest = {
      inputService: {
        serviceId: serviceId,
        qty: +qty
      }
    };
    const token = this.storageService.getStorage(TOKEN);
    this.customerService.addToCart(token, updateCartReq).subscribe(
      res => {
        this.canAddCategoriesService = '';
        this.cartsService.filterServices(res); // to update incompletd order
        this.cartsService.cart = res;
        // scroll to show added services
        const modalelements = Array.from(document.querySelectorAll('modal-container'));
        if (modalelements.length !== 0) {
          modalelements.forEach(i => {
            i.scroll({
              top: 400,
              behavior: "smooth"
            });
          });
        }
      },
      err => {
        this.canAddCategoriesService = '';
      }
    );
  }

  hideAllModals() {
    for (let i = 1; i <= this.modalService.getModalsCount(); i++) {
      this.modalService.hide(i);
    }
    // for scroll issue
    document.body.classList.remove('modal-open');

    this.router.navigate([routeConfig.create_order.first_step.route]);
  }

  back() {
    this._bsModalRef.hide();
  }

  closeModals() {
    this.categoriesNames = [];
    this.inputCategoriesNames = [];
    const count = this.modalService.getModalsCount();
    for (let i = 1; i <= this.modalService.getModalsCount(); i++) {
      this.modalService.hide(i);
    }
  }

  removeFromCart(serviceId) {
    this.cartsService.removeFromCart(serviceId);
  }

  removeInspection(categortyId) {
    this.cartsService.removeInspection(categortyId);
  }

  openModalFromInside(
    categoryChilds,
    inputCategoriesNames,
    category,
    categoryImage,
    updateOrder,
    olderOrder
  ) {
    const initialState = {
      categoryChilds,
      inputCategoriesNames: [...inputCategoriesNames],
      category,
      categoryImage,
      updateOrder,
      addNewServicesEE: this.addNewServicesEE,
      olderOrder
    };
    this.categoryServicesModalRef = this.modalService.show(
      CategoryServicesModalComponent,
      { initialState }
    );
    this.categoryServicesModalRef.content.closeEE.subscribe(() => this.close());
  }

  close() {
    this._bsModalRef.hide();
    this.closeEE.emit();
  }

  passOrUpdateOrderDetailsOlderOrder(olderOrder) {
    this.olderOrder = olderOrder;
    if (this.categoryServicesModalRef) {
      this.categoryServicesModalRef.content.passOrUpdateOrderDetailsOlderOrder(
        olderOrder
      );
    }
  }

  canShowInspection(categoryId) {
    if (this.cartsService.cart) {
      const hasIsnpection: boolean = this.cartsService.cart.inspectionCategories.filter(i => i.categoryId === categoryId).length === 0;
      const hasService: boolean = this.cartsService.cart.services.filter(i => i.rootCategory.categoryId === categoryId).length === 0;
      return hasIsnpection && hasService;
    }
  }

  orderInspection(categoryId) {
    this.orderInspectionLoading = true;
    const token = this.storageService.getStorage(TOKEN);
    this.customerService.addInspectionToCart(categoryId, token).subscribe(
      res => {
        this.orderInspectionLoading = false;
        this.cartsService.filterServices(res);
        this.cartsService.cart = res;
        const modalelements = Array.from(document.querySelectorAll('modal-container'));
        if (modalelements.length !== 0) {
          modalelements.forEach(i => {
            i.scroll({
              top: 400,
              behavior: "smooth"
            });
          });
        }
      },
      err => {
        this.orderInspectionLoading = false;
      },
    );
  }

  showMessageToAnonymousUser() {
    this.toastr.error(this.translation.instant('registration_is_required_to_complete_the_order'),
      this.translation.instant('registration_is_required'), this.toastConfig);
    this.router.navigate([routeConfig.login.route]);
  }
}
