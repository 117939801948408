import { Injectable } from '@angular/core';
import { EventsService, EventModel } from '../events/events.service';
import { Observable } from 'rxjs';
declare const dataLayer: any;
@Injectable({ providedIn: 'root' })
export class GTMService {
    eventServiceObservable: Observable<EventModel>;

    constructor(private eventsService: EventsService) {
    }

    initGTMService() {
        this.eventServiceObservable = this.eventsService.getObservable();

        this.eventServiceObservable.subscribe(event => {
            event.eventData.event = event.eventName;
            dataLayer.push(event.eventData);
        }
        );
    }
}
