import { ToastrService } from 'ngx-toastr';
import { Component, OnInit } from '@angular/core';
import { CustomerService, RatingViewResponse, RateOrderByCustomerRequest } from '../../../app/swagger';
import { ActivatedRoute, Router } from '@angular/router';
import { LanguageService } from '../../helpers/translate service/language.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { routeConfig } from '../../shared/utilities/pages-config';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss']
})
export class RatingComponent implements OnInit {

  public isLoading = true;
  public orderRatingData: RatingViewResponse = new Object();
  public rateOrderByCustomerRequest: RateOrderByCustomerRequest = new Object();
  public toggleFactors: Boolean;
  public form: FormGroup;
  public isSubmitLoading = false;
  constructor(
    private customerService: CustomerService,
    private route: ActivatedRoute,
    public languageService: LanguageService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    private translationService: TranslateService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(
      params => {
        if (params['token']) {
          this.ratingByToken(params['token']);
        }
      }
    );
  }

  ratingByToken(token) {
    this.isLoading = true;
    this.customerService.getOrderByRatingToken(token).subscribe(
      res => {
        this.isLoading = false;
        this.orderRatingData = res;
        this.initList(this.orderRatingData);
        this.orderRatingData.order.rating ? this.rateValue(this.orderRatingData.order.rating.service) : this.rateValue(undefined);
        this.rateOrderByCustomerRequest.token = token;
        this.rateOrderByCustomerRequest.orderId = this.orderRatingData.order.orderId;
      },
      err => {
      },
    );
  }
  initList(ratingViewResponse: RatingViewResponse) {
    this.form = this.formBuilder.group({
      comment: this.orderRatingData.order.rating ? this.orderRatingData.order.rating.comment : ''
    });
    ratingViewResponse.factors.map(item => {
      this.form.addControl(
        `value_${item.factorId}`,
        this.formBuilder.control(item.isChecked)
      );
    });
  }

  rateValue(e) {
    if (e <= 4) {
      this.toggleFactors = true;
    } else {
      this.toggleFactors = false;
    }
    this.rateOrderByCustomerRequest.rate = e;

  }
  submit() {
    this.isSubmitLoading = true;
    const body = this.orderRatingData.factors.map((item) => {
      return {
        factor_id: item.factorId,
        value: this.form.controls['value_' + item.factorId].value ? 1 : 0
      };
    });
    this.rateOrderByCustomerRequest.factorsIds = body
      .filter(x => x.value === 1)
      .map((item) => item.factor_id);
    if (this.rateOrderByCustomerRequest.rate === 5) {
      this.rateOrderByCustomerRequest.factorsIds = [];
    }
    this.rateOrderByCustomerRequest.comment = this.form.controls['comment'].value;
    this.customerService.rateOrderByToken(this.rateOrderByCustomerRequest).subscribe(
      res => {
        this.isSubmitLoading = false;
        this.toastr.success(this.translationService.instant('order_rated_successfully'));
        this.router.navigate([routeConfig.home.route]);
      },
      err => {
        this.isSubmitLoading = false;
      },
    );
  }
}
