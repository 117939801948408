export const AUTH_TYPE = {
    Login: "login",
    Registration: "registration"
};

export const LOCAL_STORAGE = {
    TOKEN: 'token',
    REFRESH_TOKEN: 'refresh_token',
    EXPIRES_AT: 'expires_at',
    TOKEN_TYPE: 'token_type',
    AUTH_TOKEN: 'authToken'
};
export const TOKEN_TYPES = {
    ANONYMOUS_TOKEN: 'anonymous-access-token',
    ACCESS_TOKEN: 'access-token'
};
export const ADDRESS_TYPE = {
    HOME: 'home',
    WORK: 'work'
};

export const ERROR_TYPES = {
    TOKEN_EXPIRED: 'token_expired',
    INVALID_COUPON: 'invalid_coupon_code',
    INVALID_ORDER_TIME: 'invalid_order_time',
    COUPON_EXCEEDED_LIMIT: 'coupon_exceeded_limit',
    REFRESH_RESCHEDULED_ORDERS: 'refresh_rescheduled_orders',
    INVALID_AUTH: 'invalid_auth',
    ALREADY_EXISTS: 'already_exists',
    MOBILE_ALREADY_EXISTED: 'mobile_already_existed',
    EMIAL_ALREADY_EXISTED: 'email_already_existed',
    CUSTOMER_NOT_FOUND: 'customer_not_found',
    EMAIL_NOT_VERIFIED: 'email_not_verified',
    MOBILE_VERFICATION_EXPIRED: 'mobile_verification_expired',
    WRONG_VERFICATION_CODE: 'wrong_verification_code'

};
export const ORDER_STEPS = {
    COMPELETED: 'completed',
    STARTED: 'started',
    CANCELLED_BY_CUSTOMER: 'cancelled_by_customer',
};
export const ENQUIRY_TYPES = [
    { nameEn: 'enquiry', nameAr: 'استفسار', id: 'enquiry' },
    { nameEn: 'complaint', nameAr: 'شكوى', id: 'complaint' },
    { nameEn: 'suggestion', nameAr: 'اقتراح', id: 'suggestion' },
];
export const GENDER = [
    {
        'key': 'male',
        'value': 'male'
    }, {
        'key': 'female',
        'value': 'female'
    }
];
