import { AuthenticationService } from './../../../shared/services/authenication/authentication.service';
import { ContactUsRequest } from './../../../swagger/model/contactUsRequest';
import { Component, OnInit, ErrorHandler } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CustomerService } from '../../../swagger/api/customer.service';
import { Router } from '@angular/router';
import { ToastrConfig, ToastrService } from 'ngx-toastr';
import { EventsService, EventType } from '../../../shared/services/events/events.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../../helpers/translate service/language.service';
import { ERROR_TYPES, ENQUIRY_TYPES } from '../../../shared/utilities/defines/app-types';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.scss']
})
export class ContactusComponent implements OnInit {
  form: FormGroup;
  inputName: FormControl;
  inputMobile: FormControl;
  enquiryType: FormControl;
  textAreaDetails: FormControl;
  enquiryTypeOptions = ENQUIRY_TYPES;
  toastConfig: Partial<ToastrConfig> = {
    timeOut: 3000,
  };

  constructor(
    private customerService: CustomerService,
    private eventsService: EventsService,
    private router: Router,
    private toastr: ToastrService,
    private errorHandler: ErrorHandler,
    private translationService: TranslateService,
    public languageService: LanguageService,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit() {
    this.inputName = new FormControl('', Validators.required);
    this.inputMobile = new FormControl('', Validators.compose([Validators.required, Validators.pattern('^01([0-9]{9})')]));
    this.enquiryType = new FormControl('enquiry', Validators.required);
    this.textAreaDetails = new FormControl('', Validators.required);

    this.form = new FormGroup({
      inputName: this.inputName,
      inputMobile: this.inputMobile,
      enquiryType: this.enquiryType,
      textAreaDetails: this.textAreaDetails,
    });
  }

  onSubmit() {
    if (this.form.valid) {
      const contactUsReq: ContactUsRequest = {
        enquiryType: this.enquiryType.value,
        name: this.inputName.value,
        mobile: this.inputMobile.value,
        msg: this.textAreaDetails.value,
      };
      this.customerService.contactUs(contactUsReq).subscribe(
        res => {
          this.router.navigate(['/']);
          this.eventsService.publish(EventType.Contact, contactUsReq);
        },
        err => {
          this.toastr.error(this.translationService.instant('please_try_later'),
            this.translationService.instant('an_error_occurred'), this.toastConfig);
        },
      );
    }
  }

}
