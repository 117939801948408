/**
 * Filkhedma API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { TechnicianBusySlot } from './technicianBusySlot';


export interface TechnicianCalendarSlots { 
    /**
     * Technician ID
     */
    technicianId?: string;
    /**
     * Technician First Name
     */
    firstName?: string;
    /**
     * Technician Last Name
     */
    lastName?: string;
    /**
     * Technician Mobile Number
     */
    mobile?: string;
    /**
     * Technician Mobile Number
     */
    code?: number;
    /**
     * Technician Score per day
     */
    score?: number;
    /**
     * Technician Type
     */
    type?: string;
    /**
     * Technician Status
     */
    status?: TechnicianCalendarSlots.StatusEnum;
    /**
     * Technician Account Status
     */
    accountStatus?: TechnicianCalendarSlots.AccountStatusEnum;
    /**
     * Technician Busy Slot
     */
    busySlots?: Array<TechnicianBusySlot>;
}
export namespace TechnicianCalendarSlots {
    export type StatusEnum = 'online' | 'offline';
    export const StatusEnum = {
        Online: 'online' as StatusEnum,
        Offline: 'offline' as StatusEnum
    };
    export type AccountStatusEnum = 'active' | 'suspended';
    export const AccountStatusEnum = {
        Active: 'active' as AccountStatusEnum,
        Suspended: 'suspended' as AccountStatusEnum
    };
}
