/**
 * Filkhedma API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface UpdateCustomerProfileRequest { 
    firstName?: string;
    lastName?: string;
    gender?: UpdateCustomerProfileRequest.GenderEnum;
    email?: string;
}
export namespace UpdateCustomerProfileRequest {
    export type GenderEnum = 'male' | 'female';
    export const GenderEnum = {
        Male: 'male' as GenderEnum,
        Female: 'female' as GenderEnum
    };
}
