import { Component, OnInit, forwardRef, Input, EventEmitter, Output } from '@angular/core';
import {
  FormControl, AbstractControl, ValidationErrors, NG_VALUE_ACCESSOR,
  NG_VALIDATORS, ControlValueAccessor, Validator
} from '@angular/forms';

@Component({
  selector: 'fkd-textarea',
  templateUrl: './fkd-textarea.component.html',
  styleUrls: ['./fkd-textarea.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FkdTextareaComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FkdTextareaComponent),
      multi: true
    }

  ]
})
export class FkdTextareaComponent implements OnInit, ControlValueAccessor, Validator {

  /** element id attribute*/
  @Input() id: string;

  /** element name attribute */
  @Input() name: string;

  /** element placeholder attribute */
  @Input() placeholder: string = '';

  /** the lable for the input */
  @Input() label: string;

  @Input() maxLength: number;

  /** for check if the input is required or not  */
  @Input() required: boolean = false;

  /** required errot message */
  @Input() requiredErrorMessage: string;
  /** Event on change */
  @Output() change = new EventEmitter<any>();
  /** to hold the inner value for the input */
  private innerValue: string;

  /** function template */
  private onTouchedCallback: () => {};

  /** function template */
  private onChangeCallback = (_: any) => { };

  /** get accessor including */
  @Input() get value(): any {
    return this.innerValue;
  }

  /** set accessor including call the onChange callback */
  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  /** function template */
  public validateFn: any = () => { };


  constructor() { }

  /**
   * for component initialization
   */
  ngOnInit() {
    this.validateFn = (c: FormControl) => {
      if (this.required) {
        this.value = c.value ? c.value : null;
        return c.value === null ? false : true;
      } else {
        return false;
      }
    };

  }

  //#region implementing ControlValueAccessor

  /**
   * this function form implementing ControlValueAccessor
   * responsible for Writes a new value to the element.
   * @param obj
   */
  writeValue(obj: any): void {
    this.innerValue = obj;
    if (obj) {
      this.change.emit(this.innerValue);
    }
  }

  /**
   * this function form implementing ControlValueAccessor
   * @param fn
   */
  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  /**
   * this function form implementing ControlValueAccessor
   * @param fn
   */
  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  //#endregion

  //#region implementing ControlValueAccessor

  /**
   * this function form implementing Validator
   * to validate the input based on our criteria
   * @param c
   */
  validate(c: AbstractControl): ValidationErrors {
    return this.validateFn(c);
  }

}
