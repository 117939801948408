/** Contains the key to help get and set info from localstorage */
export const LOCAL_STORAGE_PREFIX = 'Filkhedma-LocalStore.key';
/** contains the user info when dealing with storage */
export const USER_NAME = 'user';
/** contains the user type */
export const TOKEN_TYPE = 'token_type';
export const USER_LANGUAGE = 'lang';
export const USER_LANGUAGE_DIRECTION = 'dir';
export const TOKEN = 'token';
export const REFRESH_TOKEN = 'refresh_token';
export const USER = 'user';
export const USER_MOBILE = 'user-mobile';
/** List of language that parent provide */
export const LANGUAGES = ['en', 'ar'];
/** expected navigation */
export const REDIRECTION_PATH = 'redirection_url';

/** Default language for our application */
export const DEFAULT_LANGUAGE = 'en';

/** Default area  */
export const USER_AREA = 'user-city';
/** Default sub area  */
export const USER_SUBAREA = 'user-subarea';
/** Global Dates and Times formats */
export {
    GLOBAL_DATE_TIME_FORMATE,
    GLOBAL_DATE_FORMATE,
    GLOBAL_TIME_FORMATE_HOUR,
    GLOBAL_DAY_MONTH_FORMATE,
    GLOBAL_TIME_FORMATE_MINUTE
} from './date-time';

/** Default Avatars in case image not found */
export { CUSTOM_BACKGROUNDS, getDefaultImages } from './default-images';

/** Contains all keys used in Store */
export { STORE_KEYS } from './store-keys';
