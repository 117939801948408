import { LanguageService } from './../../helpers/translate service/language.service';
import { GENDER } from './../../shared/utilities/defines/app-types';
import { UserService } from './../../user/services/user.service';
import { Router } from '@angular/router';
import { StorageService } from './../../shared/services/storage/storage.service';
import { TOKEN } from './../../shared/utilities/defines/index';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { ToastrConfig, ToastrService } from 'ngx-toastr';
import { CustomerService } from '../../swagger/api/customer.service';
import { AuthService as AngularxAuthService, FacebookLoginProvider } from 'angularx-social-login';
import { VerifyCustomerRequest } from '../../swagger/model/verifyCustomerRequest';
import { TranslateService } from '@ngx-translate/core';
import { ERROR_TYPES } from '../../shared/utilities/defines/app-types';
import { routeConfig } from '../../shared/utilities/pages-config';

@Component({
    selector: 'registration',
    templateUrl: './registration.component.html',
    styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {

    verificationId: string;
    errorMessage: string;
    isLoading = false;
    completeSocialRegisteration = false;
    facebookAccessToken: string;
    registerForm: FormGroup;
    canRegisterByFacebook = true;
    canSocialRegister = true;
    canRegister = true;
    token: string;
    toastConfig: Partial<ToastrConfig> = {
        timeOut: 3000,
    };
    /** defined participants send to api */
    public genderList = [];
    /** hold gender intial value */
    gender: any;
    element = '';
    mismatchPassword = true;
    constructor(
        private customerService: CustomerService,
        private toastr: ToastrService,
        private angularxAuthService: AngularxAuthService,
        private userService: UserService,
        public translationService: TranslateService,
        private storageService: StorageService,
        private router: Router,
        private formBuilder: FormBuilder,
        private languageService: LanguageService
    ) {

    }
    ngOnInit() {
        this.loadForm();
        this.token = this.storageService.getStorage(TOKEN);
        setTimeout(() => {
            this.langChanged();
            this.loadTranslation();
        }, 0);
    }
    langChanged() {
        this.languageService.languageChange.subscribe(e => {
            this.loadTranslation();
        });
    }
    loadTranslation() {
        let genderItem;
        this.genderList = GENDER.map((gender) => {
            genderItem = Object.assign({}, gender);
            genderItem.value = this.translationService.instant(gender.value);
            return genderItem;
        });
    }
    loadForm() {
        this.registerForm = this.formBuilder.group({
            firstName: '',
            lastName: '',
            mobile: '',
            email: '',
            gender: '',
            password: '',
            rePassword: ['', this.rePassword]
        });
    }

    rePassword(c: FormControl) {
        if (c === undefined || c.parent === undefined || c.value === '') { return; }
        const lastPassword = c.parent.controls['rePassword'].value;
        if (c.value !== '' && c.parent.controls['password'].value === lastPassword) {
            return null;
        } else {
            return { lastPassword: true };
        }
    }

    checkMobileValidation() {
        if ((/[\u0660-\u0669]/).test(this.registerForm.controls['mobile'].value)) {
            this.toastr.error(this.translationService.instant('you_cannot_registar_number_with_arabic'),
                this.translationService.instant('an_error_occurred'), this.toastConfig);
            return false;
        }
        return true;
    }
    ActiveElement(e) {
        this.element = e;
    }
    register(formData) {
        this.canRegister = false;
        if (this.registerForm.valid && this.checkMobileValidation()) {
            this.isLoading = true;
            const verifyCustomerReq: VerifyCustomerRequest = {
                firstName: formData.firstName,
                lastName: formData.lastName,
                mobile: formData.mobile,
                email: formData.email,
                gender: formData.gender,
                password: formData.password,
                confirmPassword: formData.rePassword,
            };
            this.customerService.verifyAccount(this.token, verifyCustomerReq).subscribe(
                res => {
                    this.canRegister = true;
                    this.isLoading = false;
                    this.errorMessage = '';
                    this.verificationId = res.verificationId;
                    this.userService.setVerificationData({ mobile: formData.mobile, verificationId: res.verificationId, type: 'account' });
                    this.router.navigate([routeConfig.verify_mobile.route]);
                },
                err => {
                    this.canRegister = true;
                    this.isLoading = false;
                    this.handleRegisterError(err.error, verifyCustomerReq);
                },
            );
        }
    }

    handleRegisterError(error, request) {
        if (error.errors) {
            error.errors.forEach(element => {
                if (element.field === 'mobile' && element.error === ERROR_TYPES.ALREADY_EXISTS) {
                    this.errorMessage = this.translationService.instant('phone_number_is_registar');
                } else if (element.field === 'email' && element.error === ERROR_TYPES.ALREADY_EXISTS) {
                    this.errorMessage = this.translationService.instant('email_is_registar');
                }
            });
        } else if (error.msg) {
            if (error.msg === ERROR_TYPES.MOBILE_ALREADY_EXISTED) {
                this.errorMessage = this.translationService.instant('phone_number_is_registar');
            } else if (error.msg === ERROR_TYPES.EMIAL_ALREADY_EXISTED) {
                this.errorMessage = this.translationService.instant('email_is_registar');
            } else if (error.msg === 'facebook_profile_already_existed') {
                this.toastr.error(this.translationService.instant('account_is_already_register'));
            }
        } else {
            this.toastr.error(this.translationService.instant('please_try_later'),
                this.translationService.instant('an_error_occurred'), this.toastConfig);
        }
    }

    socialRegister(formData) {
        this.canSocialRegister = false;
        this.isLoading = true;
        const verifyCustomerReq: VerifyCustomerRequest = {
            firstName: formData.firstName,
            lastName: formData.lastName,
            mobile: formData.mobile,
            email: formData.email,
            gender: formData.gender,
            facebookAccessToken: this.facebookAccessToken,
        };
        this.customerService.verifyAccount(this.token, verifyCustomerReq).subscribe(
            res => {
                this.canSocialRegister = true;
                this.isLoading = false;
                this.errorMessage = '';
                this.verificationId = res.verificationId;
                this.userService.setVerificationData({ mobile: formData.mobile, verificationId: res.verificationId, type: 'account' });
                this.router.navigate([routeConfig.verify_mobile.route]);
                this.completeSocialRegisteration = false;
            },
            err => {
                this.canSocialRegister = true;
                this.completeSocialRegisteration = true;
                this.isLoading = false;
                this.handleRegisterError(err.error, verifyCustomerReq);
            },
        );
    }

    registerByFacebook() {
        this.canRegisterByFacebook = true;
        this.angularxAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then(
            user => {
                if (user) {
                    this.completeSocialRegisteration = true;
                    this.canRegisterByFacebook = true;
                    this.toastr.success(this.translationService.instant('please_complete_registration'),
                        `${this.translationService.instant('hello')} ${user.name}`, this.toastConfig);
                    this.registerForm.controls['firstName'].setValue(user.firstName);
                    this.registerForm.controls['lastName'].setValue(user.lastName);
                    this.registerForm.controls['email'].setValue(user.email);
                    this.loadSocialForm();
                    this.facebookAccessToken = user.authToken;
                }
            }
        );
    }
    navigateToLogin() {
        this.router.navigate([routeConfig.login.route]);
    }
    loadSocialForm() {
        this.registerForm.removeControl('password');
        this.registerForm.removeControl('rePassword');
    }
}
