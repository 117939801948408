import { HelperService } from './../../services/helper/helper.service';
import { Component, OnInit, forwardRef, Input, EventEmitter, Output, OnChanges, SimpleChanges, ElementRef, ViewChild } from '@angular/core';
import {
  FormControl, AbstractControl, ValidationErrors, NG_VALUE_ACCESSOR,
  NG_VALIDATORS, ControlValueAccessor, Validator
} from '@angular/forms';
@Component({
  selector: 'fkd-textbox',
  templateUrl: './fkd-textbox.component.html',
  styleUrls: ['./fkd-textbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FkdTextboxComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FkdTextboxComponent),
      multi: true
    }

  ]

})
export class FkdTextboxComponent implements OnInit, ControlValueAccessor, Validator, OnChanges {

  /** function template */
  private onTouchedCallback: () => {};

  @ViewChild('textInput', { read: ElementRef }) textInput: ElementRef;

  /** element id attribute*/
  @Input() id: string;

  /** element name attribute */
  @Input() name: string;

  /** element placeholder attribute */
  @Input() placeholder = '';

  /** the lable for the input */
  @Input() label = '';

  /** keep the label space */
  @Input() labelReservePlace = false;

  /** for check if the input passowrd or text */
  @Input() isPassword = false;

  /** regular expression pattern */
  @Input() patternValue = '';

  /** for check if the input is required or not  */
  @Input() required = false;

  /** required errot message */
  @Input() requiredErrorMessage: string;

  /** pattern errot message */
  @Input() patternErrorMessage: string;

  /** to disable the input or not */
  @Input() disabled = false;

  /** this variable will hold key name from regex-config.json file */
  @Input() patternKey: string = null;

  /** to check if the style of input using material design or not */
  @Input() isMaterialInput = false;

  /** to pass icon class name to the input */
  @Input() inputIconClass: string;

  /** to specify the maximum characters for the input  */
  @Input() maxLength = 200;

  /** to display the validation after submit the form */
  @Input() validateAfterSubmit = false;

  /** to display additional error message or not based on this flag */
  @Input() displayAdditionalErrorMessage = false;

  /** the content of the additional error message */
  @Input() additionalErrorMessage = '';

  /** this attribute for preventing user to wite any key on the keyboard does not match the pattern */
  @Input() preventWriting = false;

  /** holds value that show/hide the error logo when the control is invalid */
  @Input()
  public hideErrorIcon: boolean;

  /** to display x button to clear what in the textbox */
  @Input() public displayClearIcon = false;


  @Output() lostFocus: EventEmitter<void> = new EventEmitter<void>();

  /** on value changed */
  @Output() private change = new EventEmitter<string>();


  /** to display eye or not */
  public passwordMode = false;

  /** this variable will hold 'password' or 'text' keyword for input type attribute */
  public type: string;

  /** for hold the inner value for the input */
  private innerValue: string;

  /** this variable will hold the regex pattern */
  public patternValueFromConfig: () => string;

  /** to differentiate between to actions 'onblur' or 'whileWriting' */
  public validationMode = '';

  /** pre define actions */
  public validationModeObj = {
    onblur: 'onblur',
    whileWriting: 'whileWriting'
  };

  /** Boolean to show/hide error message in tooltip */
  @Input() showTooltipErrorMessage = false;

  /** To add hint beside the input field */
  @Input() hint = '';

  ngOnChanges(changes: SimpleChanges) {
    if (changes['validateAfterSubmit']) {
      if (changes['validateAfterSubmit'].currentValue === false) {
        this.validationMode = this.validationModeObj.whileWriting;
      } else {
        this.validationMode = this.validationModeObj.onblur;
      }
    }
  }


  /** function template */
  private onChangeCallback = (_: any) => { };

  /** get accessor including */
  get value(): any {
    return this.innerValue;
  }

  /** set accessor including call the onChange callback */
  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
      this.change.emit(v);
    }
  }

  /** function template */
  public validateFn: any = () => { };

  /**
   * first function will fire in the component life cycle
   * @param helperService Responsible for any functions for utilities purpose
   */
  constructor(private helperService: HelperService) { }

  /**
   * for component initialization
   */
  ngOnInit() {
    /** put the correct type in 'type' variable */
    this.type = (this.isPassword) ? 'password' : 'text';
    this.patternValueFromConfig = () => this.helperService.getRegexByKey(this.patternKey);

    if (!this.preventWriting) {
      this.patternValue = (this.patternValueFromConfig() !== '') ? this.patternValueFromConfig() : this.patternValue;
    }

    this.validateFn = this.createTextBoxValidator();

  }

  /** Set input focus */
  focus() {
    this.textInput.nativeElement.focus();
  }
  /**
   * on unfoucs from input
   */
  onBlur() {
    this.onTouchedCallback();
    this.onChangeCallback(this.innerValue);
    this.validationMode = this.validationModeObj.onblur;
    this.lostFocus.emit();
  }

  //#region implementing ControlValueAccessor

  /**
   * this function form implementing ControlValueAccessor
   * responsible for Writes a new value to the element.
   * @param obj
   */
  writeValue(obj: any): void {
    this.innerValue = obj;
  }

  /**
   * this function form implementing ControlValueAccessor
   * @param fn
   */
  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  /**
   * this function form implementing ControlValueAccessor
   * @param fn
   */
  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  //#endregion

  //#region implementing ControlValueAccessor

  /**
   * this function form implementing Validator
   * to validate the input based on our criteria
   * @param c
   */
  validate(c: AbstractControl): ValidationErrors {
    return this.validateFn(c);
  }

  //#endregion

  /**
   * custom validation for inputs
   */
  createTextBoxValidator() {
    return (c: FormControl) => {
      const patt = new RegExp(this.patternValueFromConfig());
      if (patt && this.required) {
        return (c.value && patt.test(c.value)) ? null : { mismatch: true };
      } else if (patt && c.value) {
        return (patt.test(c.value)) ? null : { mismatch: true };
      } else {
        return null;
      }
    };
  }

  /**
   * toggle between input types 'text' or 'password'
   */
  togglePasswordMode() {
    this.passwordMode = !this.passwordMode;
    this.type = (this.passwordMode) ? 'text' : 'password';
  }

  preventBasedOnPattern(e) {
    if (this.preventWriting && e.key.match(this.patternValueFromConfig()) == null) {
      e.preventDefault();
    }
  }

  clear() {
    this.innerValue = '';
    this.onChangeCallback(this.innerValue);
  }

}

