import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'rate',
  templateUrl: './rate.component.html',
  styleUrls: ['./rate.component.scss'],
})
export class RateComponent {
  @Output() currentRate: EventEmitter<string> = new EventEmitter<string>();
  @Input() max: any;
  @Input() rate: any;
  isReadonly = false;

  overStar: number | undefined;
  percent: number;

  hoveringOver(value: number): void {
    this.overStar = value;
    this.percent = (value / this.max) * 100;
    this.currentRate.emit(this.rate);
  }
  resetStar(): void {
    this.overStar = void 0;
  }

}
