/**
 * Filkhedma API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { BusySlotReason } from './busySlotReason';
import { TechnicianOrderSlot } from './technicianOrderSlot';
import { User } from './user';


export interface TechnicianBusySlot { 
    slotId?: string;
    title?: string;
    titleAr?: string;
    dateFrom?: string;
    dateTo?: string;
    day?: TechnicianBusySlot.DayEnum;
    timeFrom?: number;
    timeTo?: number;
    reason?: BusySlotReason;
    repeated?: boolean;
    createdAt?: string;
    creator?: User;
    type?: TechnicianBusySlot.TypeEnum;
    order?: TechnicianOrderSlot;
}
export namespace TechnicianBusySlot {
    export type DayEnum = 'sun' | 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat';
    export const DayEnum = {
        Sun: 'sun' as DayEnum,
        Mon: 'mon' as DayEnum,
        Tue: 'tue' as DayEnum,
        Wed: 'wed' as DayEnum,
        Thu: 'thu' as DayEnum,
        Fri: 'fri' as DayEnum,
        Sat: 'sat' as DayEnum
    };
    export type TypeEnum = 'technician_busy_slot' | 'order_slot';
    export const TypeEnum = {
        TechnicianBusySlot: 'technician_busy_slot' as TypeEnum,
        OrderSlot: 'order_slot' as TypeEnum
    };
}
