import { Component, } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { LanguageService } from '../../../helpers/translate service/language.service';

@Component({
  selector: 'fkd-carousel',
  templateUrl: './fkd-carousel.component.html',
  styleUrls: ['./fkd-carousel.component.scss']
})
export class FkdCarouselComponent {
  constructor(public languageService: LanguageService) {

  }
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 50000,
    stagePadding: 70,
    autoplayHoverPause: true,
    autoplay: true,
    navText: [""],
    center: true,
    margin: 10,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 2
      },
      940: {
        items: 3
      }
    },
    nav: true,
  }
}

