import { environment } from './../environments/environment';
import { AuthServiceConfig } from 'angularx-social-login';
import { FacebookLoginProvider } from 'angularx-social-login';


const angularx_config = new AuthServiceConfig([
    {
      id: FacebookLoginProvider.PROVIDER_ID,
      provider: new FacebookLoginProvider(environment.faceBookId),
    },
  ]);

  export function angularxProvideConfig () {
    return angularx_config;
  }
