import { TOKEN_TYPE, USER_NAME, REFRESH_TOKEN, TOKEN, USER_MOBILE, USER_AREA, USER_SUBAREA } from './../../utilities/defines/index';
import { Injectable, OnDestroy } from "@angular/core";
import { CustomerLoginResponse } from "../../../swagger/model/customerLoginResponse";
import { Router } from "@angular/router";
import { CustomerService } from "../../../swagger/api/customer.service";
import { Observable, BehaviorSubject } from "rxjs";
import { Subject } from "rxjs";
import { MixpanelService } from "../../../shared/services/mixpanel/mixpanel.service";
import { AUTH_TYPE, TOKEN_TYPES } from "../../../shared/utilities/defines/app-types";
import { routeConfig } from "../../../shared/utilities/pages-config";
import { StorageService } from "../storage/storage.service";
import { take, tap } from 'rxjs/operators';
import { CustomerRefreshTokenRequest } from '../../../swagger';
interface AuthResultType {
  token: string;
  refreshToken: string;
  expiresIn: string;
  type?: string;
}

interface SocialLoginType {
  authToken: string;
  firstName: string;
  lastName: string;
  name: string;
  photoUrl: string;
  provider: string;
}
@Injectable({
  providedIn: 'root'
})

export class AuthenticationService implements OnDestroy {
  public completeOrder = false;

  /** Behavior Subject for authentication status */
  public authentication = new BehaviorSubject<boolean>(false);

  /** Subject for token status */
  public tokenExpire = new Subject<boolean>();

  public tokenExpired$ = this.tokenExpire.asObservable();

  /** Subject for User data change status */
  public userDataChange = new Subject<void>();

  public userDataChanged$ = this.userDataChange.asObservable();

  /** Subject for User data change status */
  public isAnonymous = new Subject<boolean>();

  public isAnonymous$ = this.isAnonymous.asObservable();
  /** Current User Type [ anonymous | user ] */
  get userType(): string {
    return this.storageService.getStorage(TOKEN_TYPE);
  }
  /**
   * Check if the user already have tokens and userProfile
   * @return Observable<boolean> indicated if the user Authenticated or not
   */
  get isAuthenticated(): Observable<boolean> {
    return this.authentication.asObservable();
  }
  public customerRefreshTokenRequest: CustomerRefreshTokenRequest = new Object();

  constructor(
    private router: Router,
    private customerService: CustomerService,
    private mixpanelService: MixpanelService,
    private storageService: StorageService
  ) {
    const isUser = (this.storageService.getStorage(TOKEN_TYPE)) === TOKEN_TYPES.ACCESS_TOKEN ? true : false;
    this.authentication.next(isUser);
    isUser === false ? this.storageService.setStorage(TOKEN_TYPE, TOKEN_TYPES.ANONYMOUS_TOKEN) :
      this.storageService.setStorage(TOKEN_TYPE, TOKEN_TYPES.ACCESS_TOKEN);
    window.addEventListener('focus', this.checkFocusAuth.bind(this));
  }

  /**
 * Check authentication status on page focus
 */
  private checkFocusAuth() {
    const isUser = (this.storageService.getStorage(USER_NAME)) ? true : false;
    this.authentication.next(isUser);
  }

  /**
 * Set token is expired
 */
  public setTokenExpire() {
    this.tokenExpire.next(true);
  }

  /** Setting new token in storage */
  public setToken(token: string) {
    const data = Object.assign({}, this.storageService.getStorage(USER_NAME));
    if (token && data) {
      data.token = token;
      this.storageService.setStorage(USER_NAME, null);
      this.storageService.user = data;
      this.storageService.setStorage(USER_NAME, data);
    }
  }
  /** old code */
  login(loginResponse: CustomerLoginResponse, authType = AUTH_TYPE.Login) {
    const token = loginResponse.token;
    const parseJwt = (tok) => {
      try {
        return JSON.parse(atob(tok.split('.')[1]));
      } catch (e) {
        return null;
      }
    };
    const authResult: CustomerLoginResponse = {
      customerFirstName: loginResponse.customerFirstName,
      token: loginResponse.token,
      refreshToken: loginResponse.refreshToken,
      profile: loginResponse.profile
    };
    this.setUserData(authResult);


    const customerId = parseJwt(token).customerId;
    if (authType === AUTH_TYPE.Registration) {

      this.mixpanelService.alias(customerId);
    } else {
      this.mixpanelService.identify(customerId);

    }

    if (this.completeOrder) {
      this.router.navigate([routeConfig.create_order.first_step.route]);
      this.completeOrder = false;
    } else {
      this.router.navigate([routeConfig.services.route]);
    }
  }

  socialLogin(socialLoginResponse: SocialLoginType) {
    this.setUserData(socialLoginResponse);
  }
  setUserData(userResponse: any) {
    this.isAuthenticated.pipe(take(1)).subscribe(isAuthenticated => {
      this.storageService.user = Object() as CustomerLoginResponse;
      this.storageService.user.token = userResponse.token || this.storageService.getStorage(TOKEN);
      this.storageService.user.refreshToken = userResponse.refreshToken;
      this.storageService.user.customerFirstName = userResponse.customerFirstName;
      this.storageService.setStorage(TOKEN_TYPE, TOKEN_TYPES.ACCESS_TOKEN);
      this.storageService.setStorage(USER_NAME, this.storageService.user.customerFirstName || this.storageService.getStorage(USER_NAME));
      this.storageService.setStorage(TOKEN, this.storageService.user.token);
      this.storageService.setStorage(REFRESH_TOKEN, this.storageService.user.refreshToken);
      if (!isAuthenticated) {
        this.authentication.next(true);
      }
    });
  }
  public unAuthenticateUser() {
    this.authentication.next(false);
    this.storageService.user = null;
    this.storageService.setStorage(USER_NAME, null);
    this.storageService.setStorage(TOKEN, null);
    this.storageService.setStorage(REFRESH_TOKEN, null);
    this.storageService.setStorage(TOKEN_TYPE, TOKEN_TYPES.ANONYMOUS_TOKEN);
    this.storageService.setStorage(USER_AREA, null);
    this.storageService.setStorage(USER_SUBAREA, null);
  }

  public logout() {
    this.unAuthenticateUser();
    this.goAnonymous();
    this.router.navigate([routeConfig.home.route]);
  }

  refreshToken(refreshToken?: string, cityId?: string, subAreaId?: string) {
    const subject = new Subject<boolean>();
    this.customerRefreshTokenRequest.cityId = cityId ? cityId : undefined;

    this.customerRefreshTokenRequest.subareaId = subAreaId ? subAreaId : undefined;

    return this.customerService.customerRefreshToken(
      refreshToken,
      this.customerRefreshTokenRequest).pipe(tap(
        res => {
          const parseJwt = (tok) => {
            try {
              return JSON.parse(atob(tok.split('.')[1]));
            } catch (e) {
              return null;
            }
          };
          const authResult: AuthResultType = {
            token: res.token,
            refreshToken: res.refreshToken,
            expiresIn: parseJwt(res.token) ? parseJwt(res.token).exp : 'error happend',
            type: parseJwt(res.token) ? parseJwt(res.token).type : 'error happend',
          };
          this.storageService.user = Object() as AuthResultType;
          this.storageService.user.token = res.token || this.storageService.getStorage(TOKEN);
          this.storageService.user.refreshToken = res.refreshToken;

          this.storageService.setStorage(USER_AREA, res.city);
          this.storageService.setStorage(USER_SUBAREA, res.subArea ? res.subArea : res.area);

          this.storageService.setStorage(USER_NAME, this.storageService.user.customerFirstName ||
            this.storageService.getStorage(USER_NAME));
          this.storageService.setStorage(TOKEN, this.storageService.user.token);
          this.storageService.setStorage(REFRESH_TOKEN, this.storageService.user.refreshToken);
          subject.next(true);
        },
        err => {
          subject.next(false);
        },
      ));

  }

  goAnonymous() {
    this.customerService.requestAnonymousToken().subscribe(
      res => {
        const loginResponse = res;
        const token = loginResponse.token;
        const parseJwt = (tok) => {
          try {
            return JSON.parse(atob(tok.split('.')[1]));
          } catch (e) {
            return null;
          }
        };
        const authResult: AuthResultType = {
          token: loginResponse.token,
          refreshToken: loginResponse.refreshToken,
          expiresIn: parseJwt(token) ? parseJwt(token).exp : 'error happend',
          type: parseJwt(res.token) ? parseJwt(res.token).type : 'error happend',
        };
        this.storageService.user = Object() as CustomerLoginResponse;
        this.storageService.setStorage(USER_NAME, null);
        this.storageService.setStorage(TOKEN, authResult.token);
        this.storageService.setStorage(REFRESH_TOKEN, authResult.refreshToken);
        this.storageService.setStorage(TOKEN_TYPE, TOKEN_TYPES.ANONYMOUS_TOKEN);
        this.storageService.user.token = authResult.token || this.storageService.getStorage(TOKEN);
        this.storageService.user.refreshToken = authResult.refreshToken;
        this.storageService.user.customerFirstName = null;
      }, error => {
      });
  }
  ngOnDestroy() {
    this.authentication.complete();
  }
}
