import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import {
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpEvent,
} from '@angular/common/http';
import { Observable, Subject, EMPTY } from 'rxjs';
import { Router } from '@angular/router';
import { RollbarErrorHandler } from '../rollbar/rollbar.service';
import { AuthenticationService } from '../authenication/authentication.service';
import { routeConfig } from '../../utilities/pages-config';
import 'rxjs/add/operator/catch';
import { tap } from 'rxjs/operators';
import { switchMap } from 'rxjs/operators';
import 'rxjs/add/observable/throw';
import { StorageService } from '../storage/storage.service';
import { TOKEN, REFRESH_TOKEN, USER_AREA, USER_SUBAREA } from '../../utilities/defines';



@Injectable()
export class ErrorHttpInterceptor implements HttpInterceptor {
    private refreshTokenInProgress = false;
    private tempToken: string;

    private tokenRefreshedSource = new Subject();
    private tokenRefreshed$ = this.tokenRefreshedSource.asObservable();
    constructor(
        private router: Router,
        private rollbar: RollbarErrorHandler,
        private toastrService: ToastrService,
        private translationService: TranslateService,
        private authenticationService: AuthenticationService,
        private storageService: StorageService
    ) {
    }

    addAuthHeader(request, token) {
        if (request) {
            return request.clone({
                setHeaders: {
                    token: `${token}`
                }
            });
        }
        return request;
    }

    refreshToken() {
        if (this.refreshTokenInProgress) {
            return new Observable(observer => {
                this.tokenRefreshed$.subscribe(() => {
                    observer.next();
                    observer.complete();
                });
            });
        } else {
            this.refreshTokenInProgress = true;

            const areaId = this.storageService.getStorage(USER_AREA) ? this.storageService.getStorage(USER_AREA).cityId : '';
            const subAreaId = this.storageService.getStorage(USER_SUBAREA) ? this.storageService.getStorage(USER_SUBAREA).subareaId : '';

            return this.authenticationService.refreshToken(this.storageService.getStorage(REFRESH_TOKEN),
                areaId, subAreaId).pipe(tap((e) => {
                    this.tempToken = e.token;
                    this.refreshTokenInProgress = false;
                    this.tokenRefreshedSource.next();
                }));
        }
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Handle response
        return next.handle(request).catch(error => {
            if (error) {
                this.rollbar.handleError({ error, request });
                if (error.status === 403 && error.error.msg !== 'invalid_token' &&
                    !error.url.endsWith('resetPasswordByEmail') && !error.url.includes('order/token/')) {
                    return this.refreshToken().pipe(
                        switchMap(() => {
                            request = this.addAuthHeader(request, this.tempToken);
                            return next.handle(request);
                        })).catch(() => {
                            return EMPTY;
                        });
                } else if (error.url.endsWith('resetPasswordByEmail') || error.url.includes('order/token/')) {
                    this.toastrService.error(this.translationService.instant('token_expired'));
                    this.router.navigate([routeConfig.home.route]);
                } else if (error.error.msg === 'invalid_token' && !error.url.endsWith('customer/refresh-token')) {
                    if (!request.headers.get('token')) {
                        const token = this.storageService.getStorage(TOKEN);
                        request = this.addAuthHeader(request, token);
                        return next.handle(request);
                    } else {
                        this.authenticationService.unAuthenticateUser();
                        this.authenticationService.goAnonymous();
                    }
                }
            }
            return Observable.throw(error);
        }
        );
    }

}
