import { Component, OnInit } from '@angular/core';
import { CustomerService, CityResponse } from '../../../swagger';
import { StorageService } from '../../../shared/services/storage/storage.service';
import { USER_AREA, USER_SUBAREA, REFRESH_TOKEN, REDIRECTION_PATH } from '../../../shared/utilities/defines';
import { AuthenticationService } from '../../../shared/services/authenication/authentication.service';
import { RouteInterceptorService } from '../../../shared';
import { Router } from '@angular/router';
import { routeConfig } from '../../../shared/utilities/pages-config';
import { LanguageService } from '../../../helpers/translate service/language.service';

@Component({
  selector: 'select-location',
  templateUrl: './select-location.component.html',
  styleUrls: ['./select-location.component.scss']
})
export class SelectLocationComponent implements OnInit {
  selectedCity: any = '';
  selectedSubArea: any = '';
  cities: CityResponse[] = [];
  areas = [];
  isCityLoading = true;
  isRedirectionLoading = true;
  constructor(private customerService: CustomerService,
    private storageService: StorageService,
    private authenticationService: AuthenticationService,
    private routeInterceptorService: RouteInterceptorService,
    private router: Router,
    public languageService: LanguageService) { }

  ngOnInit() {
    this.getCitiesList();
  }
  getCitiesList() {
    this.customerService.getCities().subscribe(cities => {
      if (cities) {
        this.isCityLoading = false;
        this.cities = cities.cities;
        this.selectedCity = this.storageService.getStorage(USER_AREA) ?
          this.storageService.getStorage(USER_AREA).cityId : this.cities[0].cityId;
        this.cities.filter(x => x.cityId === this.selectedCity).map(x => this.areas = x.subAreas);
        this.selectedSubArea = this.storageService.getStorage(USER_SUBAREA) ?
          this.storageService.getStorage(USER_SUBAREA).subareaId || this.storageService.getStorage(USER_SUBAREA).areaId : '';
      }
    }, err => {
      this.isCityLoading = false;
    });
  }

  redirectToExpectedDestination() {
    this.isRedirectionLoading = false;
    const cityId = this.cities.find(x => x.cityId === this.selectedCity)
      ? this.cities.find(x => x.cityId === this.selectedCity).cityId : '';
    const subAreaId = this.cities.find(x => x.cityId === this.selectedCity).subAreas ?
      this.cities.find(x => x.cityId === this.selectedCity).subAreas.find(x => x.subareaId === this.selectedSubArea).subareaId : '';
    this.authenticationService.refreshToken(this.storageService.getStorage(REFRESH_TOKEN), cityId, subAreaId).subscribe(x => {
      if (x) {
        if (this.storageService.getStorage(REDIRECTION_PATH)) {
          this.router.navigate([this.storageService.getStorage(REDIRECTION_PATH)]);
        } else {
          this.router.navigate([routeConfig.services.route]);
        }
      }
    }, err => {
      this.isRedirectionLoading = true;
    });

  }
  onChange(e) {
    if (e) {
      this.selectedSubArea = '';
      this.areas = e.subAreas;
    }

  }
}
