/**
 * Filkhedma API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Customer } from './customer';
import { Technician } from './technician';


export interface FinancialTransaction { 
    /**
     * Transaction ID
     */
    transactionId?: string;
    /**
     * Transaction Time
     */
    time?: string;
    /**
     * Transaction Time
     */
    transactionTime?: string;
    /**
     * Transaction Type
     */
    type?: FinancialTransaction.TypeEnum;
    /**
     * Transaction Settlement Source
     */
    source?: FinancialTransaction.SourceEnum;
    /**
     * Transaction Description
     */
    description?: string;
    partyType?: string;
    provider?: string;
    paymentMethod?: FinancialTransaction.PaymentMethodEnum;
    technician?: Technician;
    customer?: Customer;
    /**
     * Transaction Amount
     */
    amount?: number;
}
export namespace FinancialTransaction {
    export type TypeEnum = 'order' | 'settlement';
    export const TypeEnum = {
        Order: 'order' as TypeEnum,
        Settlement: 'settlement' as TypeEnum
    };
    export type SourceEnum = 'dashboard' | 'fawry' | 'fawry_reconciliation';
    export const SourceEnum = {
        Dashboard: 'dashboard' as SourceEnum,
        Fawry: 'fawry' as SourceEnum,
        FawryReconciliation: 'fawry_reconciliation' as SourceEnum
    };
    export type PaymentMethodEnum = 'cash' | 'credit';
    export const PaymentMethodEnum = {
        Cash: 'cash' as PaymentMethodEnum,
        Credit: 'credit' as PaymentMethodEnum
    };
}
