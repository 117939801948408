/**
 * Filkhedma API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface UpdateOrderService { 
    /**
     * Service ID
     */
    serviceId?: string;
    /**
     * Service Type
     */
    type?: UpdateOrderService.TypeEnum;
    /**
     * Service Quantity
     */
    qty?: number;
}
export namespace UpdateOrderService {
    export type TypeEnum = 'guarantee';
    export const TypeEnum = {
        Guarantee: 'guarantee' as TypeEnum
    };
}
