import { Component, OnInit, HostListener, Inject, ViewChild } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { LanguageService } from '../../helpers/translate service/language.service';
import { StorageService } from '../../shared/services/storage/storage.service';
import { USER_LANGUAGE, USER_LANGUAGE_DIRECTION } from '../../shared/utilities/defines';
import { ModalDirective } from 'ngx-bootstrap';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'branding-landing',
  templateUrl: './branding-landing.component.html',
  styleUrls: ['./branding-landing.component.scss']
})
export class BrandingLandingComponent implements OnInit {
  toggle = false;
  desktopView = false;
  public currentLang: string;
  @ViewChild('filkhedmaVideo') filkhedmaVideo: ModalDirective;
  safeSrc: SafeResourceUrl;
  constructor(@Inject(DOCUMENT) document,
    public languageService: LanguageService,
    private storageService: StorageService,
    private sanitizer: DomSanitizer) {
    this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/fRMqpGHrC3w?start=1");
  }

  ngOnInit() {
    this.filkhedmaVideo.onHidden.subscribe(re => {
      const myScope = document.getElementById('player');
      const iframes = myScope.getElementsByTagName("iframe");
      if (iframes != null) {
        for (let i = 0; i < iframes.length; i++) {
          iframes[i].src = iframes[i].src;
        }
      }
    });
    if (window.screen.width > 1024) {
      this.desktopView = true;
    } else {
      this.desktopView = false;
    }
    this.changeLang(this.storageService.getStorage(USER_LANGUAGE));
  }
  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    if (window.pageYOffset > 50) {
      const element = document.getElementById('navbar');
      element.classList.add('scrolled');
    } else {
      const element = document.getElementById('navbar');
      element.classList.remove('scrolled');
    }
  }
  scrollToElement($element) {
    const yourElement = document.getElementById($element.id);
    const yCoordinate = yourElement.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -100;

    window.scrollTo({
      top: yCoordinate + yOffset,
      behavior: 'smooth'
    });
  }
  navigateToWebsite() {
    window.open('https://www.filkhedma.com/home/services', '_self');
  }
  navigateToApp() {
    window.open('https://filkhedma.page.link/f8Hs', '_self');
  }
  toggleMenu(e) {
    this.toggle = !this.toggle;
    if (this.toggle) {
      const element = document.getElementById('navbar');
      element.classList.add('scrolled');
      const el = document.getElementById('navbarResponsive');
      el.classList.add('d-block');
      el.classList.remove('d-none');
    } else {
      const element = document.getElementById('navbar');
      element.classList.remove('scrolled');
      const el = document.getElementById('navbarResponsive');
      el.classList.remove('d-block');
      el.classList.add('d-none');
    }
  }
  changeLang(e) {
    switch (e) {
      case 'ar':
        this.languageService.setLanguage('ar');
        this.storageService.setStorage(USER_LANGUAGE, 'ar');
        this.storageService.setStorage(USER_LANGUAGE_DIRECTION, 'rtl');
        this.currentLang = this.storageService.getStorage(USER_LANGUAGE);
        break;
      case 'en':
        this.languageService.setLanguage('en');
        this.storageService.setStorage(USER_LANGUAGE, 'en');
        this.currentLang = this.storageService.getStorage(USER_LANGUAGE);
        this.storageService.setStorage(USER_LANGUAGE_DIRECTION, 'ltr');
        break;
      default:
        this.languageService.setLanguage('ar');
        this.storageService.setStorage(USER_LANGUAGE, 'ar');
        this.storageService.setStorage(USER_LANGUAGE_DIRECTION, 'rtl');
        this.currentLang = this.storageService.getStorage(USER_LANGUAGE);
        break;
    }
  }
  openVideo() {
    this.filkhedmaVideo.show();

  }
  close() {
    if (this.toggle) {
      this.toggle = true;
      this.toggleMenu(false);
    }
  }
}
