import { versions } from "./versions";

export const environment = {
  envName: "prod",
  production: true,
  api_base_url: "https://api.filkhedma.net",
  google_analytics_key: "UA-55043718-1",
  faceBookId: "503794553423675",
  google_gtm_key: "GTM-NQDT44C",
  google_gtm_auth: "abP85SWhU7nd4z-2MWk88Q",
  google_gtm_env: "env-2",
  ...versions,
  applyRollbar: true,
  firebase: {
    apiKey: "AIzaSyAXSvSVtz5Z3TPknI9jmjw_u4-3SRtsDfk",
    authDomain: "filkhedma-custom-1553462465340.firebaseapp.com",
    databaseURL: "https://filkhedma-custom-1553462465340.firebaseio.com",
    projectId: "filkhedma-custom-1553462465340",
    storageBucket: "filkhedma-custom-1553462465340.appspot.com",
    messagingSenderId: "52100250183",
    appId: "1:52100250183:web:8372f6304e24bbeeed4f62",
    measurementId: "G-V6G04VN31L"
  }
};
