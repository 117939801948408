import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { routeConfig } from '../shared/utilities/pages-config';
import { RatingComponent } from './rating/rating.component';

const routes: Routes = [
  {
    path:  routeConfig.rating.name,
    component: RatingComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RatingRoutingModule { }
