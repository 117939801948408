import { TestomonialResponseModel, TestomonialModel } from './../../../sharedui/components/testomonials/testomonials.component';
import { Component, OnInit } from '@angular/core';
import { CustomerService } from '../../../swagger/api/customer.service';
import { Category } from '../../../swagger/model/category';
import { Service } from '../../../swagger/model/service';
import { Router } from '@angular/router';
import { CarouselConfig } from '../../../../../node_modules/ngx-bootstrap/carousel';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../../helpers/translate service/language.service';
import { routeConfig } from '../../../shared/utilities/pages-config';

@Component({
    selector: 'app-landing-page',
    templateUrl: './landing-page.component.html',
    styleUrls: ['./landing-page.component.scss'],

    providers: [
        { provide: CarouselConfig, useValue: { interval: 3600000, noPause: true, showIndicators: false } }
    ]
})
export class LandingPageComponent implements OnInit {

    allCategories: Category[];
    quickServices: Service[];
    firstService: Service;
    secondService: Service;
    thirdService: Service;
    hoverCategory: any = {};
    carouselCategoryIndex = 0;
    canOrderQuickService = true;
    testomonials: TestomonialModel[];


    constructor(
        private customerService: CustomerService,
        private router: Router,
        private http: HttpClient,
        public translate: TranslateService,
        public languageService: LanguageService,
    ) {

    }

    ngOnInit() {
        this.getCategories();
        this.getQuickServices();
        this.getJSON().subscribe(
            res => {
                this.testomonials = res.testomonials;
            },
            error => {
                console.error(error);
            }
        );
    }
    displayedText() {
        if (this.languageService.currentLanguage === 'ar') {
            return 'nameAr';
        } else {
            return 'nameEn';
        }
    }


    goToServices(id) {
        this.router.navigateByUrl(`${routeConfig.services.service.route}${id}`);
    }
    public getJSON(): Observable<TestomonialResponseModel> {
        return this.http.get<TestomonialResponseModel>("../../../../assets/json/testomonials.json");
    }

    getCategories() {
        this.customerService.getActiveCategories().subscribe(
            res => {
                this.allCategories = res.categories;
            },
            err => {
            },
        );
    }


    getQuickServices() {
        this.customerService.getQuickServices().subscribe(
            res => {
                this.quickServices = res.services;
                this.firstService = res.services[0];
                this.secondService = res.services[1];
                this.thirdService = res.services[2];
            },
        );
    }

    nextSlide() {
        if (this.carouselCategoryIndex === 0) {
            this.carouselCategoryIndex = this.allCategories.length - 1;
            return;
        }
        this.carouselCategoryIndex--;
    }

    prevSlide() {
        if (this.carouselCategoryIndex === this.allCategories.length - 1) {
            this.carouselCategoryIndex = 0;
            return;
        }
        this.carouselCategoryIndex++;
    }
    navigateToChooseService(id) {
        return `${routeConfig.category.route}${id}`;
    }
}
