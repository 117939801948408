/**
 * Filkhedma API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { BusinessBranch } from './businessBranch';


export interface OrderBusiness { 
    businessId?: string;
    name?: string;
    branch?: BusinessBranch;
    /**
     * Order Deal
     */
    deal?: OrderBusiness.DealEnum;
}
export namespace OrderBusiness {
    export type DealEnum = 'emergency' | 'preventive' | 'extra';
    export const DealEnum = {
        Emergency: 'emergency' as DealEnum,
        Preventive: 'preventive' as DealEnum,
        Extra: 'extra' as DealEnum
    };
}
