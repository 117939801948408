import { UserService } from './../../user/services/user.service';
import { AuthenticationService } from './../../shared/services/authenication/authentication.service';
import { TOKEN } from './../../shared/utilities/defines/index';
import { StorageService } from './../../shared/services/storage/storage.service';
import { LanguageService } from './../../helpers/translate service/language.service';
import { VerifyMobileRequest } from './../../swagger/model/verifyMobileRequest';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { CustomerService } from '../../swagger/api/customer.service';
import { ToastrService, ToastrConfig } from 'ngx-toastr';
import { ResendVerificationCodeRequest } from '../../swagger/model/resendVerificationCodeRequest';
import { RegisterCustomerRequest } from '../../swagger/model/registerCustomerRequest';
import * as moment from 'moment';
import { AUTH_TYPE, ERROR_TYPES } from '../../shared/utilities/defines/app-types';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { routeConfig } from '../../shared/utilities/pages-config';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';


@Component({
  selector: 'verify-mobile',
  templateUrl: './verify-mobile.component.html',
  styleUrls: ['./verify-mobile.component.scss']
})
export class VerifyMobileComponent implements OnInit {

  public errorMessage: string;
  public verifyLoading = false;
  public verifyForm: FormGroup;
  public timer;
  public maxTime: number;
  public waitingTime;
  public resendVerificationErrorMessage: string;
  public mobile: string;
  public verificationId: string;
  public verificationType: string;
  public verifyMobReq: VerifyMobileRequest;
  @ViewChild('prg') prg: ElementRef;
  public token: string;
  public toastConfig: Partial<ToastrConfig> = {
    timeOut: 3000,
  };
  public canResendAgain = false;
  public canVerify = true;
  constructor(
    private customerService: CustomerService,
    private toastr: ToastrService,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private storageService: StorageService,
    private translationService: TranslateService,
    public languageService: LanguageService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {
  }

  ngOnInit() {
    const data = this.userService.getVerificationData();
    this.loadForm();
    if (data) {
      this.token = this.storageService.getStorage(TOKEN);
      this.startTimer(2);
      this.mobile = data.mobile;
      this.verificationId = data.verificationId;
      this.verificationType = data.type;
    } else {
      this.router.navigate([routeConfig.home.route]);
      console.log('here');
    }
  }
  loadForm() {
    this.verifyForm = this.formBuilder.group({
      verifyCode: '',
    });
  }

  verifyMobile(formDate) {
    this.canVerify = false;
    const token = this.storageService.getStorage(TOKEN);
    if (this.verifyForm.valid) {
      switch (this.verificationType) {
        case 'account':
          this.verifyLoading = true;
          const registerCustomerReq: RegisterCustomerRequest = {
            verificationCode: +(formDate.verifyCode),
            verificationId: this.verificationId,
          };
          this.customerService.registerCustomer(token, registerCustomerReq).subscribe(
            res => {
              this.canVerify = true;
              this.verifyLoading = false;
              this.errorMessage = '';
              this.toastr.success(this.translationService.instant('account_successfully_activated'));
              this.authenticationService.login(res, AUTH_TYPE.Registration);
              this.userService.sendMessage('hide_register');
            },
            err => {
              this.canVerify = true;
              this.verifyLoading = false;
              this.handleError(err.error, { token: token, registerCustomerReq: registerCustomerReq });
            },
          );
          break;
        case 'mobile':
          this.verifyLoading = true;
          this.canVerify = false;
          this.verifyMobReq = {
            verificationCode: +(formDate.verifyCode),
            verificationId: this.verificationId,
          };
          this.customerService.verifyMobile(this.verifyMobReq).subscribe(
            res => {
              this.canVerify = true;
              this.verifyLoading = false;
              this.errorMessage = '';
              this.userService.setForgetPasswordData(this.verifyMobReq);
              this.userService.forgetPasswordStep = 'second';
              this.router.navigate([routeConfig.forget_password.route]);
            },
            err => {
              this.canVerify = true;
              this.verifyLoading = false;
              this.handleError(err.error, { verifyMobReq: this.verifyMobReq });
            },
          );
          break;
        case 'oldUserMobile':
          this.verifyLoading = true;
          this.canVerify = false;
          this.verifyMobReq = {
            verificationCode: +(formDate.verifyCode),
            verificationId: this.verificationId,
          };
          this.customerService.verifyMobileToLogin(this.verifyMobReq).subscribe(
            res => {
              this.canVerify = true;
              this.errorMessage = '';
              this.toastr.success(this.translationService.instant('account_successfully_activated'));
              this.authenticationService.login(res, AUTH_TYPE.Registration);
            },
            err => {
              this.canVerify = true;
              this.verifyLoading = false;
              this.handleError(err.error, { verifyMobReq: this.verifyMobReq });
            },
          );
          break;
        case 'mobile_to_change':
          this.verifyLoading = true;
          this.canVerify = false;
          this.verifyMobReq = {
            verificationCode: +(formDate.verifyCode),
            verificationId: this.verificationId,
          };
          this.customerService.verifyMobileToChange(this.verifyMobReq).subscribe(
            res => {
              this.canVerify = true;
              this.errorMessage = '';
              this.toastr.success(this.translationService.instant('mobile_number_successfully_changed'));
              this.router.navigate([routeConfig.home.route]);
            },
            err => {
              this.canVerify = true;
              this.verifyLoading = false;
              this.handleError(err.error, { verifyMobReq: this.verifyMobReq });
            },
          );
          break;
        case 'other_mobile':
          this.verifyLoading = true;
          this.canVerify = false;

          this.verifyMobReq = {
            verificationCode: +(formDate.verifyCode),
            verificationId: this.verificationId,
          };
          this.customerService.verifyMobileToAddToOtherMobiles(this.token, this.verifyMobReq).subscribe(
            res => {
              this.canVerify = true;
              this.errorMessage = '';
              this.toastr.success(this.translationService.instant('mobile_number_successfully_changed'));
              this.router.navigate([routeConfig.home.route]);
              this.userService.sendMessage(res);
            },
            err => {
              this.canVerify = true;
              this.verifyLoading = false;
              this.handleError(err.error, { token: this.token, verifyMobReq: this.verifyMobReq });
            },
          );
          break;
        default:
          break;
      }
    }
  }
  handleError(error, req) {
    if (error.msg === ERROR_TYPES.WRONG_VERFICATION_CODE) {
      this.errorMessage = this.translationService.instant('code_not_vaild');
    } else if (error.msg === ERROR_TYPES.MOBILE_VERFICATION_EXPIRED) {
      this.errorMessage = this.translationService.instant('code_has_expired');
      this.canResendAgain = true;
    } else {
      this.toastr.error(this.translationService.instant('try_again_later'),
        this.translationService.instant('an_error_occurred'), this.toastConfig);
    }
  }

  startTimer(max) {
    this.timer = max * 60;
    this.maxTime = max * 60;
    const interval = setInterval(() => {
      this.timer--;
      this.waitingTime = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).second(this.timer).format('mm:ss');
      if (this.timer === 0) {
        clearInterval(interval);
      }
    }, 1000);
  }

  getWidth(): string {
    return (100 - ((this.timer / this.maxTime) * 100)).toString() + '%';
  }

  resendVerification() {
    const resendVerificationCodeReq: ResendVerificationCodeRequest = {
      verificationId: this.verificationId,
    };
    this.customerService.resendVerificationCode(resendVerificationCodeReq).subscribe(
      res => {
        this.errorMessage = '';
        this.startTimer(5);
        this.canResendAgain = false;
        this.verifyForm.reset();
      },
      err => {
        this.toastr.error(this.translationService.instant('try_again_later'),
          this.translationService.instant('an_error_occurred'), this.toastConfig);
      },
    );
  }

}
