/**
 * Filkhedma API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Material } from './material';
import { ServiceCost } from './serviceCost';
import { TechnicianTool } from './technicianTool';


export interface Service { 
    /**
     * Service ID
     */
    serviceId?: string;
    /**
     * Service English Name
     */
    nameEn?: string;
    /**
     * Service Arabic Name
     */
    nameAr?: string;
    /**
     * Service Customer English Name
     */
    customerNameEn?: string;
    /**
     * Service Customer Arabic Name
     */
    customerNameAr?: string;
    /**
     * DescriptionAr
     */
    descriptionAr?: string;
    /**
     * DescriptionEn
     */
    descriptionEn?: string;
    /**
     * Service Image
     */
    image?: string;
    /**
     * Service Website Brief ( English )
     */
    websiteBriefEn?: string;
    /**
     * Service Website Brief ( Arabic )
     */
    websiteBriefAr?: string;
    /**
     * Service Cost
     */
    cost?: ServiceCost;
    assistants?: number;
    level?: Service.LevelEnum;
    guaranteeInWeeks?: number;
    materials?: Array<Material>;
    tools?: Array<TechnicianTool>;
}
export namespace Service {
    export type LevelEnum = 'A' | 'B';
    export const LevelEnum = {
        A: 'A' as LevelEnum,
        B: 'B' as LevelEnum
    };
}
