import { VerifyEmailComponent } from './authentication/verify-email/verify-email.component';
import { ChangePasswordByEmailComponent } from './authentication/change-password-by-email/change-password-by-email.component';
import { ForgetPasswordComponent } from './authentication/forget-password/forget-password.component';
import { RegistrationComponent } from './authentication/registration/registration.component';
import { TestomonialsComponent } from './sharedui/components/testomonials/testomonials.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { LandingPageComponent } from './landing/components/landing-page/landing-page.component';
import { HelpComponent } from './sharedui/components/help/help.component';
import { TermsComponent } from './sharedui/components/terms/terms.component';
import { PricesComponent } from './sharedui/components/prices/prices.component';
import { AboutusComponent } from './sharedui/components/aboutus/aboutus.component';
import { ContactusComponent } from './sharedui/components/contactus/contactus.component';
import { EditProfilePageComponent } from './user/pages/edit-profile-page/edit-profile-page.component';
import { routeConfig } from './shared/utilities/pages-config';
import { LoginByTokenComponent } from './authentication/login-by-token/login-by-token.component';
import { RatingComponent } from './rating/rating/rating.component';
import { LoginComponent } from './authentication/login/login.component';
import { VerifyMobileComponent } from './authentication/verify-mobile/verify-mobile.component';
import { SelectLocationComponent } from './landing/components/select-location/select-location.component';
import { BrandingLandingComponent } from './branding-landing/branding-landing/branding-landing.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: routeConfig.home.name,
        pathMatch: 'full'
    },
    {
        path: routeConfig.home.name,
        component: LandingPageComponent,
    },
    {
        path: routeConfig.about_us.name,
        component: AboutusComponent,
    },
    {
        path: routeConfig.contact_us.name,
        component: ContactusComponent,
    },
    {
        path: routeConfig.help.name,
        component: HelpComponent,
    },
    {
        path: routeConfig.terms.name,
        component: TermsComponent,
    },
    {
        path: routeConfig.prices.name,
        component: PricesComponent,
    },
    {
        path: routeConfig.testomonials.name,
        component: TestomonialsComponent,
    },
    {
        path: routeConfig.forget_password.name,
        component: ForgetPasswordComponent,
    },
    {
        path: routeConfig.verify_mobile.name,
        component: VerifyMobileComponent,
    },
    {
        path: routeConfig.edit_profile.name,
        component: EditProfilePageComponent,
    },
    {
        path: routeConfig.email_verify.name,
        component: VerifyEmailComponent,
    },
    {
        path: routeConfig.reset_passowrd.name,
        component: ChangePasswordByEmailComponent,
    },
    {
        path: routeConfig.register.name,
        component: RegistrationComponent,
    },
    {
        path: routeConfig.login_by_token.name,
        loadChildren: './authentication/authentication.module#AuthenticationModule',
        component: LoginByTokenComponent,
    },
    {
        path: routeConfig.login.name,
        loadChildren: './authentication/authentication.module#AuthenticationModule',
        component: LoginComponent,
    },
    {
        path: routeConfig.rating.name,
        loadChildren: './rating/rating.module#RatingModule',
        component: RatingComponent,
    },
    {
        path: routeConfig.landing.name,
        component: BrandingLandingComponent,
        loadChildren: './branding-landing/branding-landing.module#BrandingLandingModule',
    },
    {
        path: routeConfig.select_area.name,
        component: SelectLocationComponent,
    },
    {
        path: '',
        loadChildren: './home/home.module#HomeModule'
    },
    {
        path: '**',
        redirectTo: routeConfig.home.name,
    },
];

// const config: ExtraOptions = {
//     useHash: true,
// };

@NgModule({
    imports: [
        RouterModule.forRoot(routes),
    ],
    exports: [RouterModule],
    providers: [
    ],
})
export class AppRoutingModule {
}

export const routedComponents = [
    LandingPageComponent
];
