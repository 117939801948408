/**
 * Filkhedma API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface UpdateTechnicianRequest { 
    technicianId?: string;
    firstName?: string;
    lastName?: string;
    firstNameAr?: string;
    lastNameAr?: string;
    mobile?: string;
    otherMobiles?: Array<string>;
    categoryIds?: Array<string>;
    areaIds?: Array<string>;
    subareaIds?: Array<string>;
    nearestAreaId?: string;
    level?: UpdateTechnicianRequest.LevelEnum;
    type?: UpdateTechnicianRequest.TypeEnum;
    profilePic?: string;
}
export namespace UpdateTechnicianRequest {
    export type LevelEnum = 'A' | 'B';
    export const LevelEnum = {
        A: 'A' as LevelEnum,
        B: 'B' as LevelEnum
    };
    export type TypeEnum = 'inhouse' | 'marketplace';
    export const TypeEnum = {
        Inhouse: 'inhouse' as TypeEnum,
        Marketplace: 'marketplace' as TypeEnum
    };
}
