import { RouteInterceptorService } from './shared/services/route-interceptor/route-interceptor.service';
import { TOKEN_TYPES } from './shared/utilities/defines/app-types';
import { AuthenticationService } from './shared/services/authenication/authentication.service';
import { LANGUAGES, USER_LANGUAGE, DEFAULT_LANGUAGE, USER_LANGUAGE_DIRECTION, TOKEN_TYPE, TOKEN } from './shared/utilities/defines/index';
import { Component, OnInit, DoCheck } from '@angular/core';
import { MixpanelService } from './shared/services/mixpanel/mixpanel.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { BsLocaleService } from 'ngx-bootstrap';
import { GoogoleAnalyticsService, PageView } from './shared/services/google-analytics/googole-analytics.service';
import { routeConfig } from './shared/utilities/pages-config';
import { GTMService } from './shared/services/gtm/gtm.service';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from './shared/services/storage/storage.service';

// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as firebase from "firebase/app";

// Add the Performance Monitoring library
import "firebase/performance";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, DoCheck {
  title = 'Filkhedma Web Site';
  public dir: string;

  constructor(
    private mixpanelService: MixpanelService,
    private googoleAnalyticsService: GoogoleAnalyticsService,
    private router: Router,
    private localeService: BsLocaleService,
    private gtmService: GTMService,
    private translate: TranslateService,
    private storageService: StorageService,
    private authenticationService: AuthenticationService,
    private routeInterceptorService: RouteInterceptorService,
    private route: ActivatedRoute,
  ) {
    translate.addLangs(LANGUAGES);
    translate.setDefaultLang(this.storageService.getStorage(USER_LANGUAGE) || DEFAULT_LANGUAGE);
    translate.use(this.storageService.getStorage(USER_LANGUAGE) || DEFAULT_LANGUAGE);
    if (this.storageService.getStorage(TOKEN_TYPE) === TOKEN_TYPES.ANONYMOUS_TOKEN && !(this.storageService.getStorage(TOKEN))) {
      this.authenticationService.goAnonymous();
    }

  }

  ngOnInit() {
    firebase.performance();
    this.gtmService.initGTMService();
    this.dir = this.storageService.getStorage(USER_LANGUAGE_DIRECTION);
    this.mixpanelService.trackEvent('Customer Open Page', { pageName: this.title }).subscribe();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (!event.urlAfterRedirects.includes(routeConfig.services.route) &&
          !event.urlAfterRedirects.includes(routeConfig.category.route)) {
          const page: PageView = {
            page: event.urlAfterRedirects,
            title: 'Page View',
          };
          this.googoleAnalyticsService.sendPageView(page);
        } else if (event.urlAfterRedirects.match('home/services/') &&
          !event.urlAfterRedirects.includes(routeConfig.category.route)) {
          if (screen.width <= 991) {
            const id = event.urlAfterRedirects.split('/')[3];
            this.router.navigateByUrl(`${routeConfig.category.route}${id}`);
          }
        }
        if ((event.url.startsWith('/#/'))) {
          // console.log('includes #', event.url, location.origin);
          const sliceHash = event.url.slice(3);
          // console.log(sliceHash);
          this.router.navigateByUrl(`${sliceHash}`);

        }
      }
    });
  }
  ngDoCheck() {
    this.dir = this.storageService.getStorage(USER_LANGUAGE_DIRECTION);
    this.localeService.use(this.storageService.getStorage(USER_LANGUAGE) || DEFAULT_LANGUAGE);
  }
}
