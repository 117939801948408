/**
 * Filkhedma API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { UpdateOrderService } from './updateOrderService';


export interface CalculateVisitExpectedPaidAmountRequest { 
    orderId?: string;
    doneServicesIds?: Array<UpdateOrderService>;
    postAction?: CalculateVisitExpectedPaidAmountRequest.PostActionEnum;
}
export namespace CalculateVisitExpectedPaidAmountRequest {
    export type PostActionEnum = 'finalize' | 'reschedule';
    export const PostActionEnum = {
        Finalize: 'finalize' as PostActionEnum,
        Reschedule: 'reschedule' as PostActionEnum
    };
}
