/**
 * Filkhedma API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { UpdateOrderService } from './updateOrderService';


export interface NewOrderRequest { 
    type?: NewOrderRequest.TypeEnum;
    customerId?: string;
    categoryId?: string;
    areaId?: string;
    subareaId?: string;
    serviceIds?: Array<UpdateOrderService>;
    addressId?: string;
    tagsIds?: Array<string>;
    orderTime?: string;
    /**
     * Order Discount Percentage
     */
    discountPercentage?: number;
    /**
     * Order Discount Up To Amount
     */
    discountUpto?: number;
    /**
     * Order Coupon ID
     */
    couponId?: string;
    customerNotes?: string;
    channel?: NewOrderRequest.ChannelEnum;
}
export namespace NewOrderRequest {
    export type TypeEnum = 'individual' | 'business';
    export const TypeEnum = {
        Individual: 'individual' as TypeEnum,
        Business: 'business' as TypeEnum
    };
    export type ChannelEnum = 'old_customer' | 'call_center' | 'app_chat' | 'website_chat' | 'icommunity' | 'facebook_messenger';
    export const ChannelEnum = {
        OldCustomer: 'old_customer' as ChannelEnum,
        CallCenter: 'call_center' as ChannelEnum,
        AppChat: 'app_chat' as ChannelEnum,
        WebsiteChat: 'website_chat' as ChannelEnum,
        Icommunity: 'icommunity' as ChannelEnum,
        FacebookMessenger: 'facebook_messenger' as ChannelEnum
    };
}
