/**
 * Filkhedma API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { UpdateOrderService } from './updateOrderService';


export interface NewVisitVerificationRequest { 
    orderId?: string;
    postAction?: NewVisitVerificationRequest.PostActionEnum;
    technicianComment?: string;
    usedMaterials?: string;
    servicesChecksum?: string;
    paidMaterialsAmount?: number;
    paidServicesAmount?: number;
    expectedPaidAmount?: number;
    doneServicesIds?: Array<UpdateOrderService>;
}
export namespace NewVisitVerificationRequest {
    export type PostActionEnum = 'finalize' | 'reschedule';
    export const PostActionEnum = {
        Finalize: 'finalize' as PostActionEnum,
        Reschedule: 'reschedule' as PostActionEnum
    };
}
