import { TOKEN_TYPES } from './../../utilities/defines/app-types';
import { CustomerLoginResponse } from './../../../swagger/model/customerLoginResponse';
import { USER_NAME, LOCAL_STORAGE_PREFIX, TOKEN_TYPE } from './../../utilities/defines/index';
import { User } from './../../../swagger/model/user';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {


  /** holds current logged in user info*/
  public user: CustomerLoginResponse;

  /** holds current logged in user status*/
  public status: { isLoggedIn: boolean };

  /**
   * initiating the default values
  */
  constructor() {
    this.status = { isLoggedIn: this.getStorage(TOKEN_TYPE) === TOKEN_TYPES.ACCESS_TOKEN ? true : false };
  }


  /**
  * add/set item to browser local storage
  * @param key the identifier for the local storage item
   * @param value the value of local storage item
  */
  public setStorage(key: string, value: any) {
    // const newKey = LOCAL_STORAGE_PREFIX + '.' + key;
    const newKey = key;
    // Add to local storage
    if (typeof value === 'object') {
      localStorage.setItem(newKey, JSON.stringify(value));
    } else {
      localStorage.setItem(newKey, String(value));
    }

  }

  /**
   * read certain item from the session storage or from the cachedSession and
   * parse the item to json if the item is a stringified object.
   * @param  {key} The key of the property to be detected
   * @returns {Object} the returned object holds the value for the detected property
  */
  public getStorage(key: string) {
    try {
      return JSON.parse(localStorage.getItem(key));
    } catch (error) {
      return localStorage.getItem(key);
    }
  }

  public updateUser() {
    this.status = { isLoggedIn: this.getStorage(TOKEN_TYPE) === TOKEN_TYPES.ACCESS_TOKEN ? true : false };
  }

  /**
   * remove certain item from the localStorage and from the cachedSession
   * @param  {key} The key of the property to be removed
  */
  public remove(key: string) {
    localStorage.removeItem(key);
  }

  /**
  * clear all the localStorage items and the cachedSession items
  */
  public empty() {
    localStorage.clear();
  }


  /**
   * list all items in the localStorage
   * @returns {Object} the returned object holds the cached Session object
  */
  public listAllItems() {
    const items = [];
    for (const key in localStorage) {
      if (localStorage.hasOwnProperty(key)) {
        items.push(items);
      }
    }
    return items;
  }




}
