import { take, map } from 'rxjs/operators';
import { AuthenticationService } from './../../shared/services/authenication/authentication.service';
import { Injectable } from '@angular/core';
import { CanActivate, Router, CanLoad } from '@angular/router';
import { ToastrConfig } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { routeConfig } from '../../shared/utilities/pages-config';

@Injectable()
export class AuthGuard implements CanActivate, CanLoad {
  toastConfig: Partial<ToastrConfig> = {
    timeOut: 3000,
  };

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
  ) { }

  canLoad(): boolean | Observable<boolean> | Promise<boolean> {
    return this.authenticationService.isAuthenticated.pipe(
      take(1),
      map((isAuthenticated: boolean) => {
        if (!isAuthenticated) {
          this.router.navigate([routeConfig.login.route]);
          return false;
        }
        return true;
      }));
  }
  canActivate(): boolean | Observable<boolean> | Promise<boolean> {
    return this.authenticationService.isAuthenticated.pipe(
      take(1),
      map((isAuthenticated: boolean) => {
        if (isAuthenticated) {
          return true;
        }
        return false;
      }));

  }
}

