import { AuthenticationService } from './../../../shared/services/authenication/authentication.service';
import {
    USER_LANGUAGE_DIRECTION, USER_LANGUAGE, USER_NAME,
    TOKEN, USER_AREA, USER_SUBAREA
} from './../../../shared/utilities/defines/index';
import { StorageService } from './../../../shared/services/storage/storage.service';
import { CustomerService } from './../../../swagger/api/customer.service';
import { LanguageService } from './../../../helpers/translate service/language.service';
import { UserService } from './../../../user/services/user.service';
import { OnInit, DoCheck } from '@angular/core';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { routeConfig } from '../../../shared/utilities/pages-config';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
    selector: 'app-main-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})

export class MainHeaderComponent implements OnInit, DoCheck {
    isCollapsed = true;
    public currentLang: string;
    balanceNumber: string;
    public isAuthenticated = false;
    /** Reset header */
    public resetHeader = false;
    public userName: string;
    public areaName: string;
    public subAreaName: string;

    constructor(
        private router: Router,
        public userService: UserService,
        public translate: TranslateService,
        public languageService: LanguageService,
        private customerService: CustomerService,
        private storageService: StorageService,
        private authenticationService: AuthenticationService
    ) {
    }
    ngDoCheck(): void {
        this.areaName = this.storageService.getStorage(USER_AREA) ? this.storageService.getStorage(USER_AREA) : '';
        this.subAreaName = this.storageService.getStorage(USER_SUBAREA) ? this.storageService.getStorage(USER_SUBAREA) : '';

    }
    ngOnInit() {
        this.userName = this.storageService.getStorage(USER_NAME);
        this.areaName = this.storageService.getStorage(USER_AREA) ? this.storageService.getStorage(USER_AREA) : '';
        this.subAreaName = this.storageService.getStorage(USER_SUBAREA) ? this.storageService.getStorage(USER_SUBAREA) : '';
        this.authenticationService.isAuthenticated.pipe(distinctUntilChanged()).subscribe((isLoggedIn: boolean) => {
            this.isAuthenticated = isLoggedIn;
            if (this.isAuthenticated) {
                this.getProfileData();
            }
        });
        this.authenticationService.userDataChanged$.subscribe(res => {
            this.resetHeader = true;
            setTimeout(() => {
                this.resetHeader = false;
            }, 0);
        });
        this.changeLang(this.storageService.getStorage(USER_LANGUAGE));
            document.addEventListener('smartbanner.exit', function () {
                (document.querySelector('.page-header') as HTMLElement).style.top = '0';
            });
            document.addEventListener('smartbanner.view', function () {
                (document.querySelector('.page-header') as HTMLElement).style.top = '80px';
            });
            if (document.querySelector('.smartbanner')) {
                if (document.querySelector('.smartbanner').scrollHeight !== 0 || document.querySelector('.smartbanner').scrollHeight !== null) {
                    (document.querySelector('.page-header') as HTMLElement).style.top = '80px';
                }
            }
        
    }

    changeLang(e) {
        switch (e) {
            case 'ar':
                this.languageService.setLanguage('ar');
                this.storageService.setStorage(USER_LANGUAGE, 'ar');
                this.storageService.setStorage(USER_LANGUAGE_DIRECTION, 'rtl');
                this.currentLang = this.storageService.getStorage(USER_LANGUAGE);
                break;
            case 'en':
                this.languageService.setLanguage('en');
                this.storageService.setStorage(USER_LANGUAGE, 'en');
                this.currentLang = this.storageService.getStorage(USER_LANGUAGE);
                this.storageService.setStorage(USER_LANGUAGE_DIRECTION, 'ltr');
                break;
            default:
                this.languageService.setLanguage('ar');
                this.storageService.setStorage(USER_LANGUAGE, 'ar');
                this.storageService.setStorage(USER_LANGUAGE_DIRECTION, 'rtl');
                this.currentLang = this.storageService.getStorage(USER_LANGUAGE);
                break;
        }
        if (this.translate.currentLang === 'ar') {
            this.balanceNumber = '.';

        } else {
            this.balanceNumber = '0';
        }
    }


    getProfileData() {
        const token = this.storageService.getStorage(TOKEN);
        this.customerService.getCustomerProfile(token).subscribe(
            res => {
                this.userService.setCustomer(res);
            },
            err => {
            }
        );
    }

    backToLanding() {
        this.router.navigate(['/']);
    }
    navigateToContactUs() {
        this.router.navigate([routeConfig.contact_us.route]);
    }
    navigateToOpenedOrder() {
        this.router.navigate([routeConfig.order.opened.route]);
    }
    navigateToServices() {
        this.router.navigate([routeConfig.services.route]);
    }
    navigateToEditProfile() {
        this.router.navigate([routeConfig.edit_profile.route]);
    }
    navigateToLogin() {
        this.router.navigate([routeConfig.login.route]);
    }
    navigateToRegistration() {
        this.router.navigate([routeConfig.register.route]);
    }
    redirectToLanding() {
        this.router.navigate([routeConfig.select_area.route]);
    }
}
