import { AuthenticationService } from './../../../shared/services/authenication/authentication.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ERROR_TYPES } from './../../../shared/utilities/defines/app-types';
import { LanguageService } from './../../../helpers/translate service/language.service';
import { Category } from './../../../swagger/model/category';
import { Component, OnInit } from '@angular/core';
import { CustomerService } from '../../../swagger/api/customer.service';
import { Router } from '@angular/router';
import { routeConfig } from '../../../shared/utilities/pages-config';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  categories: Category[];
  toggleScreenSize: any;
  constructor(
    private customerService: CustomerService,
    public languageService: LanguageService,
    private route: Router,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit() {
    this.getCategories();
    if (screen.width <= 991) {
      this.toggleScreenSize = true;
    } else {
      this.toggleScreenSize = false;
    }

  }

  getCategories() {
    this.customerService.getActiveCategories().subscribe(
      res => {
        this.categories = res.categories;
      },
      err => {
      },
    );
  }
  redirect(id: any) {
    if (this.toggleScreenSize) {
      this.route.navigateByUrl(`${routeConfig.category.route}${id}`);
    } else {
      this.route.navigateByUrl(`${routeConfig.services.service.route}${id}`);
    }
  }
  navigateToPrice() {
    this.route.navigate([routeConfig.prices.route]);
  }
  navigateToContactUs() {
    this.route.navigate([routeConfig.contact_us.route]);
  }
  navigateToAboutUs() {
    this.route.navigate([routeConfig.about_us.route]);
  }
  navigateToHelp() {
    this.route.navigate([routeConfig.help.route]);
  }
  navigateToTestomonials() {
    this.route.navigate([routeConfig.testomonials.route]);
  }
  navigateToTerms() {
    this.route.navigate([routeConfig.terms.route]);
  }

}
