import { AuthenticationService } from './../services/authenication/authentication.service';
import { routeConfig } from './../utilities/pages-config';
import { Injectable } from '@angular/core';
import { CanLoad, Route, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
@Injectable()
export class AuthenticationGuard implements CanLoad {


    constructor(
        private router: Router,
        private authenticationService: AuthenticationService) { }

    canLoad(route: Route): boolean | Observable<boolean> | Promise<boolean> {
        return this.authenticationService.isAuthenticated.pipe(
            take(1),
            map((isAuthenticated: boolean) => {
                if (!isAuthenticated) {
                    this.router.navigate([routeConfig.login.route]);
                    return false;
                }
                return true;
            }));
    }
}
