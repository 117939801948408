/**
 * Filkhedma API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Actor } from './actor';
import { Customer } from './customer';
import { Opportunity } from './opportunity';
import { Order } from './order';
import { Technician } from './technician';
import { TechnicianBusySlot } from './technicianBusySlot';


export interface UserLog { 
    /**
     * Log Transaction ID
     */
    id?: string;
    /**
     * Action Time
     */
    time?: string;
    /**
     * Action
     */
    action?: string;
    actor?: Actor;
    actorType?: UserLog.ActorTypeEnum;
    order?: Order;
    technician?: Technician;
    customer?: Customer;
    oppotunity?: Opportunity;
    busySlot?: TechnicianBusySlot;
}
export namespace UserLog {
    export type ActorTypeEnum = 'user' | 'technician' | 'customer' | 'system';
    export const ActorTypeEnum = {
        User: 'user' as ActorTypeEnum,
        Technician: 'technician' as ActorTypeEnum,
        Customer: 'customer' as ActorTypeEnum,
        System: 'system' as ActorTypeEnum
    };
}
