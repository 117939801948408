/**
 * Filkhedma API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Area } from './area';
import { Category } from './category';
import { Subarea } from './subarea';


export interface Technician { 
    /**
     * Technician ID
     */
    technicianId?: string;
    /**
     * Technician First Name
     */
    firstName?: string;
    /**
     * Technician Last Name
     */
    lastName?: string;
    /**
     * Technician First Name AR
     */
    firstNameAr?: string;
    /**
     * Technician Last Name AR
     */
    lastNameAr?: string;
    /**
     * Technician Mobile Number
     */
    mobile?: string;
    /**
     * Technician Other Mobile Numbers
     */
    otherMobiles?: Array<string>;
    /**
     * Technician Password
     */
    password?: string;
    /**
     * Technician Status
     */
    status?: string;
    /**
     * Technician Balance
     */
    balance?: number;
    /**
     * Technician Account Status
     */
    accountStatus?: string;
    /**
     * Technician Account Status
     */
    categories?: Array<Category>;
    /**
     * Technician Areas
     */
    areas?: Array<Area>;
    /**
     * Technician Areas
     */
    subareas?: Array<Subarea>;
    /**
     * Technician Areas
     */
    nearestArea?: Area;
    /**
     * Technician Profile Picture
     */
    profilePic?: string;
    /**
     * Technician Profile Picture Abs Path
     */
    photo?: string;
    /**
     * Technician Created At
     */
    createdAt?: string;
    /**
     * Technician Updated At
     */
    updatedAt?: string;
    /**
     * Technician First Activated At
     */
    firstActivatedAt?: string;
    /**
     * Technician Code
     */
    code?: number;
    /**
     * Technician Code
     */
    ratedOrders?: number;
    /**
     * Technician Code
     */
    rate?: number;
    /**
     * Technician Level
     */
    level?: Technician.LevelEnum;
    /**
     * Technician Type
     */
    type?: Technician.TypeEnum;
    /**
     * Labels
     */
    labels?: Array<string>;
}
export namespace Technician {
    export type LevelEnum = 'A' | 'B';
    export const LevelEnum = {
        A: 'A' as LevelEnum,
        B: 'B' as LevelEnum
    };
    export type TypeEnum = 'inhouse' | 'marketplace';
    export const TypeEnum = {
        Inhouse: 'inhouse' as TypeEnum,
        Marketplace: 'marketplace' as TypeEnum
    };
}
