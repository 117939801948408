/**
 * Filkhedma API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CustomerProfileAddress } from './customerProfileAddress';


export interface CustomerProfile { 
    /**
     * Customer ID
     */
    customerId?: string;
    hasPassword?: boolean;
    /**
     * Customer First Name
     */
    firstName?: string;
    /**
     * Customer Last Name
     */
    lastName?: string;
    /**
     * Customer Mobile Number
     */
    mobile?: string;
    otherMobiles?: Array<string>;
    /**
     * Customer Email
     */
    email?: string;
    /**
     * Is Customer Email Verified
     */
    emailVerified?: boolean;
    gender?: CustomerProfile.GenderEnum;
    /**
     * Customer Addresses
     */
    addresses?: Array<CustomerProfileAddress>;
    /**
     * Customer Source
     */
    source?: CustomerProfile.SourceEnum;
    /**
     * Customer Balance
     */
    balance?: number;
    /**
     * Customer Rate
     */
    rate?: number;
    /**
     * Customer Account Status
     */
    accountStatus?: string;
    /**
     * Customer Created At
     */
    createdAt?: string;
    /**
     * Customer Updated At
     */
    updatedAt?: string;
}
export namespace CustomerProfile {
    export type GenderEnum = 'male' | 'female';
    export const GenderEnum = {
        Male: 'male' as GenderEnum,
        Female: 'female' as GenderEnum
    };
    export type SourceEnum = 'dashboard' | 'website' | 'mobile_app';
    export const SourceEnum = {
        Dashboard: 'dashboard' as SourceEnum,
        Website: 'website' as SourceEnum,
        MobileApp: 'mobile_app' as SourceEnum
    };
}
