/**
 * Filkhedma API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface CouponPolicy { 
    type?: CouponPolicy.TypeEnum;
    operator?: CouponPolicy.OperatorEnum;
    value?: string;
    value2?: number;
    list?: Array<string>;
}
export namespace CouponPolicy {
    export type TypeEnum = 'area' | 'category' | 'subtotal';
    export const TypeEnum = {
        Area: 'area' as TypeEnum,
        Category: 'category' as TypeEnum,
        Subtotal: 'subtotal' as TypeEnum
    };
    export type OperatorEnum = '<' | '<=' | '>' | '>=' | '=' | '!=' | '$in' | '$between';
    export const OperatorEnum = {
        LessThan: '<' as OperatorEnum,
        LessThanOrEqualTo: '<=' as OperatorEnum,
        GreaterThan: '>' as OperatorEnum,
        GreaterThanOrEqualTo: '>=' as OperatorEnum,
        Equal: '=' as OperatorEnum,
        NotEqual: '!=' as OperatorEnum,
        In: '$in' as OperatorEnum,
        Between: '$between' as OperatorEnum
    };
}
