/**
 * Filkhedma API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface SetBusinessOrderDealRequest { 
    /**
     * Order ID
     */
    orderId?: string;
    /**
     * Order Deal
     */
    orderDeal?: SetBusinessOrderDealRequest.OrderDealEnum;
}
export namespace SetBusinessOrderDealRequest {
    export type OrderDealEnum = 'emergency' | 'preventive' | 'extra';
    export const OrderDealEnum = {
        Emergency: 'emergency' as OrderDealEnum,
        Preventive: 'preventive' as OrderDealEnum,
        Extra: 'extra' as OrderDealEnum
    };
}
