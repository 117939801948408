/**
 * Filkhedma API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { TechnicianBusySlot } from './technicianBusySlot';
import { TechnicianOrderSlot } from './technicianOrderSlot';


export interface TechnicianOrdersAndSlots { 
    /**
     * Technician ID
     */
    technicianId?: string;
    /**
     * Technician First Name
     */
    firstName?: string;
    /**
     * Technician last Name
     */
    lastName?: string;
    /**
     * Technician Code
     */
    code?: string;
    /**
     * Technician Type
     */
    type?: TechnicianOrdersAndSlots.TypeEnum;
    /**
     * Technician Mobile
     */
    mobile?: string;
    /**
     * Technician Other Mobiles
     */
    otherMobiles?: Array<string>;
    orders?: Array<TechnicianOrderSlot>;
    busySlots?: Array<TechnicianBusySlot>;
}
export namespace TechnicianOrdersAndSlots {
    export type TypeEnum = 'inhouse' | 'marketplace';
    export const TypeEnum = {
        Inhouse: 'inhouse' as TypeEnum,
        Marketplace: 'marketplace' as TypeEnum
    };
}
