import { Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PageView, GoogoleAnalyticsService } from "../../../shared/services/google-analytics/googole-analytics.service";
import { routeConfig } from '../../../shared/utilities/pages-config';

@Component({
  selector: 'app-edit-profile-modal',
  templateUrl: './edit-profile-modal.component.html',
  styleUrls: ['./edit-profile-modal.component.css']
})
export class EditProfileModalComponent implements OnInit {

  modalRef: BsModalRef;
  @ViewChild('modalTempRef') modalTempRef;

  constructor(
    private modalService: BsModalService,
    private googoleAnalyticsService: GoogoleAnalyticsService,
    private router: Router
  ) {
  }

  ngOnInit() {
    const page: PageView = {
      page: '/editprofile',
      title: 'Page View',
    };
    this.googoleAnalyticsService.sendPageView(page);
  }
  openModal() {
    this.modalRef = this.modalService.show(this.modalTempRef, { class: 'modal-edit-profile' });
  }

  hideModal(event) {
    if (event === 'done') {
      this.modalRef.hide();
    }
  }

  openOtherModal(event) {
    if (event.old === false) {
      this.modalRef.hide();
    }
    if (event.new === 'verify') {
      this.router.navigate([routeConfig.verify_mobile.route]);
    }
  }

}
