/**
 * Filkhedma API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { ActivateTechnicianRequest } from '../model/activateTechnicianRequest';
import { ActivateUserRequest } from '../model/activateUserRequest';
import { AddNewCouponRequest } from '../model/addNewCouponRequest';
import { AddNewServiceRequest } from '../model/addNewServiceRequest';
import { AddTechnicianBusySlotRequest } from '../model/addTechnicianBusySlotRequest';
import { ApplyOptimizationRequest } from '../model/applyOptimizationRequest';
import { AreasResponse } from '../model/areasResponse';
import { AskForRecommendationsRequest } from '../model/askForRecommendationsRequest';
import { AskForRecommendationsResponse } from '../model/askForRecommendationsResponse';
import { AvailableSlotsResponse } from '../model/availableSlotsResponse';
import { BlockTechnicianRequest } from '../model/blockTechnicianRequest';
import { BlockUserRequest } from '../model/blockUserRequest';
import { Business } from '../model/business';
import { BusinessBranch } from '../model/businessBranch';
import { BusinessContract } from '../model/businessContract';
import { BusinessesResponse } from '../model/businessesResponse';
import { CalculateVisitExpectedPaidAmountRequest } from '../model/calculateVisitExpectedPaidAmountRequest';
import { CalculateVisitExpectedPaidAmountResponse } from '../model/calculateVisitExpectedPaidAmountResponse';
import { CancelOrderByCustomerRequest } from '../model/cancelOrderByCustomerRequest';
import { CancelOrderByTechnicianRequest } from '../model/cancelOrderByTechnicianRequest';
import { CategoriesResponse } from '../model/categoriesResponse';
import { Category } from '../model/category';
import { CategoryChildsResponse } from '../model/categoryChildsResponse';
import { CategoryChildsResponseV2 } from '../model/categoryChildsResponseV2';
import { ChangeOrderTimeRequest } from '../model/changeOrderTimeRequest';
import { CheckAvailableSlotsRequest } from '../model/checkAvailableSlotsRequest';
import { ConfirmOrderRequest1 } from '../model/confirmOrderRequest1';
import { CouponResponse } from '../model/couponResponse';
import { CouponsResponse } from '../model/couponsResponse';
import { CreateRootCategoryRequest } from '../model/createRootCategoryRequest';
import { CreateServiceRequest } from '../model/createServiceRequest';
import { CreateSubCategoryRequest } from '../model/createSubCategoryRequest';
import { Customer } from '../model/customer';
import { CustomerAddress } from '../model/customerAddress';
import { CustomerCancelVisitRequest } from '../model/customerCancelVisitRequest';
import { CustomerCartResponse } from '../model/customerCartResponse';
import { CustomerTicketsResponse } from '../model/customerTicketsResponse';
import { CustomersResponse } from '../model/customersResponse';
import { FilterCalendarRequest } from '../model/filterCalendarRequest';
import { FilterLogRequest } from '../model/filterLogRequest';
import { FilterOrdersRequest } from '../model/filterOrdersRequest';
import { FilterTechniciansRequest } from '../model/filterTechniciansRequest';
import { FinalizeOrderRequest } from '../model/finalizeOrderRequest';
import { FinancialOrder } from '../model/financialOrder';
import { ForceAssignOrderRequest } from '../model/forceAssignOrderRequest';
import { ForceUpdateOrderServicesRequest } from '../model/forceUpdateOrderServicesRequest';
import { ForceUpdateOrderVisitRequest } from '../model/forceUpdateOrderVisitRequest';
import { GetCouponsResponse } from '../model/getCouponsResponse';
import { ListOpportunitiesRequest } from '../model/listOpportunitiesRequest';
import { MaterialsResponse } from '../model/materialsResponse';
import { MoveServicesRequest } from '../model/moveServicesRequest';
import { NewBusinessBranchRequest } from '../model/newBusinessBranchRequest';
import { NewBusinessContractRequest } from '../model/newBusinessContractRequest';
import { NewBusinessOrderRequest } from '../model/newBusinessOrderRequest';
import { NewBusinessRequest } from '../model/newBusinessRequest';
import { NewCustomerDetailedAddressRequest } from '../model/newCustomerDetailedAddressRequest';
import { NewCustomerRequest } from '../model/newCustomerRequest';
import { NewOpportunityRequest } from '../model/newOpportunityRequest';
import { NewOrderRequest } from '../model/newOrderRequest';
import { NewRoleRequest } from '../model/newRoleRequest';
import { NewTechnicianRequest } from '../model/newTechnicianRequest';
import { NewUserRequest } from '../model/newUserRequest';
import { NewVisitRequest } from '../model/newVisitRequest';
import { OpportunitiesResponse } from '../model/opportunitiesResponse';
import { Opportunity } from '../model/opportunity';
import { OpportunityTypesResponse } from '../model/opportunityTypesResponse';
import { Order } from '../model/order';
import { OrderTimeConflictsResponse } from '../model/orderTimeConflictsResponse';
import { OrdersResponse } from '../model/ordersResponse';
import { OrdersStatusReportResponse } from '../model/ordersStatusReportResponse';
import { PermissionsResponse } from '../model/permissionsResponse';
import { RateFactorsResponse } from '../model/rateFactorsResponse';
import { RateOrderByCustomerRequest } from '../model/rateOrderByCustomerRequest';
import { RecommendationPreparingRequest } from '../model/recommendationPreparingRequest';
import { RematchOrderRequest } from '../model/rematchOrderRequest';
import { RescheduleOrderRequest } from '../model/rescheduleOrderRequest';
import { Role } from '../model/role';
import { RolesResponse } from '../model/rolesResponse';
import { ServiceResponse } from '../model/serviceResponse';
import { ServicesResponse } from '../model/servicesResponse';
import { SetBusinessOrderDealRequest } from '../model/setBusinessOrderDealRequest';
import { SettleCustomerRequest } from '../model/settleCustomerRequest';
import { SettleTechnicianOrdersRequest } from '../model/settleTechnicianOrdersRequest';
import { SettleTechnicianRequest } from '../model/settleTechnicianRequest';
import { StartOrderRequest1 } from '../model/startOrderRequest1';
import { SubCategoriesResponse } from '../model/subCategoriesResponse';
import { SubCategory } from '../model/subCategory';
import { SuccessResponse } from '../model/successResponse';
import { SuggestedTechniciansResponse } from '../model/suggestedTechniciansResponse';
import { SuspendTechnicianRequest } from '../model/suspendTechnicianRequest';
import { SwappingOrdersRequest } from '../model/swappingOrdersRequest';
import { TagsResponse } from '../model/tagsResponse';
import { Technician } from '../model/technician';
import { TechnicianBusySlotsResponse } from '../model/technicianBusySlotsResponse';
import { TechnicianToolsResponse } from '../model/technicianToolsResponse';
import { TechnicianUnsettledOrdersResponse } from '../model/technicianUnsettledOrdersResponse';
import { TechniciansCalendarSlotsResponse } from '../model/techniciansCalendarSlotsResponse';
import { TechniciansRequestsReportResponse } from '../model/techniciansRequestsReportResponse';
import { TechniciansResponse } from '../model/techniciansResponse';
import { UpdateBusinessBranchRequest } from '../model/updateBusinessBranchRequest';
import { UpdateBusinessContractRequest } from '../model/updateBusinessContractRequest';
import { UpdateBusinessRequest } from '../model/updateBusinessRequest';
import { UpdateCouponRequest } from '../model/updateCouponRequest';
import { UpdateCustomerDetailedAddressRequest } from '../model/updateCustomerDetailedAddressRequest';
import { UpdateCustomerNotesRequest } from '../model/updateCustomerNotesRequest';
import { UpdateCustomerRequest } from '../model/updateCustomerRequest';
import { UpdateGuaranteeServicesRequest } from '../model/updateGuaranteeServicesRequest';
import { UpdateOpportunityRequest } from '../model/updateOpportunityRequest';
import { UpdateOrderDiscountRequest } from '../model/updateOrderDiscountRequest';
import { UpdateOrderServicesRequest } from '../model/updateOrderServicesRequest';
import { UpdateOrderTagsRequest } from '../model/updateOrderTagsRequest';
import { UpdateRoleRequest } from '../model/updateRoleRequest';
import { UpdateRootCategoryRequest } from '../model/updateRootCategoryRequest';
import { UpdateServiceRequest } from '../model/updateServiceRequest';
import { UpdateSubCategoryRequest } from '../model/updateSubCategoryRequest';
import { UpdateTechnicianCommentRequest } from '../model/updateTechnicianCommentRequest';
import { UpdateTechnicianRequest } from '../model/updateTechnicianRequest';
import { UpdateUserRequest } from '../model/updateUserRequest';
import { UploadSuccessResponse } from '../model/uploadSuccessResponse';
import { User } from '../model/user';
import { UserCredentials } from '../model/userCredentials';
import { UserLogResponse } from '../model/userLogResponse';
import { UserLoginResponse } from '../model/userLoginResponse';
import { UsersResponse } from '../model/usersResponse';
import { VerifyCustomerResponse } from '../model/verifyCustomerResponse';
import { VisitVerification } from '../model/visitVerification';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class DashboardService {

    protected basePath = 'https://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Activate Technician
     * 
     * @param token User Access Token
     * @param activateTechnicianRequest Activate Technician Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public activateTechnician(token?: string, activateTechnicianRequest?: ActivateTechnicianRequest, observe?: 'body', reportProgress?: boolean): Observable<Technician>;
    public activateTechnician(token?: string, activateTechnicianRequest?: ActivateTechnicianRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Technician>>;
    public activateTechnician(token?: string, activateTechnicianRequest?: ActivateTechnicianRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Technician>>;
    public activateTechnician(token?: string, activateTechnicianRequest?: ActivateTechnicianRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Technician>(`${this.basePath}/dashboard/activateTechnician`,
            activateTechnicianRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Activate User
     * 
     * @param token User Access Token
     * @param activateUserRequest Activate User Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public activateUser(token?: string, activateUserRequest?: ActivateUserRequest, observe?: 'body', reportProgress?: boolean): Observable<User>;
    public activateUser(token?: string, activateUserRequest?: ActivateUserRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<User>>;
    public activateUser(token?: string, activateUserRequest?: ActivateUserRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<User>>;
    public activateUser(token?: string, activateUserRequest?: ActivateUserRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<User>(`${this.basePath}/dashboard/activateUser`,
            activateUserRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add New Customer Address
     * 
     * @param token User Access Token
     * @param newCustomerAddressRequest New Customer Address Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addCustomerAddress(token?: string, newCustomerAddressRequest?: NewCustomerDetailedAddressRequest, observe?: 'body', reportProgress?: boolean): Observable<Customer>;
    public addCustomerAddress(token?: string, newCustomerAddressRequest?: NewCustomerDetailedAddressRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Customer>>;
    public addCustomerAddress(token?: string, newCustomerAddressRequest?: NewCustomerDetailedAddressRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Customer>>;
    public addCustomerAddress(token?: string, newCustomerAddressRequest?: NewCustomerDetailedAddressRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Customer>(`${this.basePath}/dashboard/customer/addAddress`,
            newCustomerAddressRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * add New Coupon
     * 
     * @param token User Access Token
     * @param addNewCouponRequest Add New Coupon Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addNewCoupon(token?: string, addNewCouponRequest?: AddNewCouponRequest, observe?: 'body', reportProgress?: boolean): Observable<CouponResponse>;
    public addNewCoupon(token?: string, addNewCouponRequest?: AddNewCouponRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CouponResponse>>;
    public addNewCoupon(token?: string, addNewCouponRequest?: AddNewCouponRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CouponResponse>>;
    public addNewCoupon(token?: string, addNewCouponRequest?: AddNewCouponRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CouponResponse>(`${this.basePath}/dashboard/coupons`,
            addNewCouponRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * addNewService
     * 
     * @param token User Access Token
     * @param addNewServiceRequest Add New Service Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addNewService(token?: string, addNewServiceRequest?: AddNewServiceRequest, observe?: 'body', reportProgress?: boolean): Observable<Order>;
    public addNewService(token?: string, addNewServiceRequest?: AddNewServiceRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Order>>;
    public addNewService(token?: string, addNewServiceRequest?: AddNewServiceRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Order>>;
    public addNewService(token?: string, addNewServiceRequest?: AddNewServiceRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Order>(`${this.basePath}/dashboard/services`,
            addNewServiceRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * add Technician Busy Slot
     * 
     * @param token User Access Token
     * @param addTechnicianBusySlotRequest Add Technician Busy Slot Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addTechnicianBusySlot(token?: string, addTechnicianBusySlotRequest?: AddTechnicianBusySlotRequest, observe?: 'body', reportProgress?: boolean): Observable<TechnicianBusySlotsResponse>;
    public addTechnicianBusySlot(token?: string, addTechnicianBusySlotRequest?: AddTechnicianBusySlotRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TechnicianBusySlotsResponse>>;
    public addTechnicianBusySlot(token?: string, addTechnicianBusySlotRequest?: AddTechnicianBusySlotRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TechnicianBusySlotsResponse>>;
    public addTechnicianBusySlot(token?: string, addTechnicianBusySlotRequest?: AddTechnicianBusySlotRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<TechnicianBusySlotsResponse>(`${this.basePath}/dashboard/addTechnicianBusySlot`,
            addTechnicianBusySlotRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * applyOptimization
     * 
     * @param token User Access Token
     * @param applyOptimizationRequest Apply Optimization Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public applyOptimization(token?: string, applyOptimizationRequest?: ApplyOptimizationRequest, observe?: 'body', reportProgress?: boolean): Observable<TechniciansCalendarSlotsResponse>;
    public applyOptimization(token?: string, applyOptimizationRequest?: ApplyOptimizationRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TechniciansCalendarSlotsResponse>>;
    public applyOptimization(token?: string, applyOptimizationRequest?: ApplyOptimizationRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TechniciansCalendarSlotsResponse>>;
    public applyOptimization(token?: string, applyOptimizationRequest?: ApplyOptimizationRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<TechniciansCalendarSlotsResponse>(`${this.basePath}/dashboard/applyOptimization`,
            applyOptimizationRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Ask For Recommendation
     * 
     * @param token User Access Token
     * @param askForRecommendationsRequest Ask For Recommendation Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public askForRecommendation(token?: string, askForRecommendationsRequest?: AskForRecommendationsRequest, observe?: 'body', reportProgress?: boolean): Observable<AskForRecommendationsResponse>;
    public askForRecommendation(token?: string, askForRecommendationsRequest?: AskForRecommendationsRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AskForRecommendationsResponse>>;
    public askForRecommendation(token?: string, askForRecommendationsRequest?: AskForRecommendationsRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AskForRecommendationsResponse>>;
    public askForRecommendation(token?: string, askForRecommendationsRequest?: AskForRecommendationsRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<AskForRecommendationsResponse>(`${this.basePath}/dashboard/askForRecommendation`,
            askForRecommendationsRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Technician Assigned To Order
     * 
     * @param token User Access Token
     * @param forceAssignOrderRequest Force Assign Order Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public assignTechnicianToOrder(token?: string, forceAssignOrderRequest?: ForceAssignOrderRequest, observe?: 'body', reportProgress?: boolean): Observable<SuccessResponse>;
    public assignTechnicianToOrder(token?: string, forceAssignOrderRequest?: ForceAssignOrderRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessResponse>>;
    public assignTechnicianToOrder(token?: string, forceAssignOrderRequest?: ForceAssignOrderRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessResponse>>;
    public assignTechnicianToOrder(token?: string, forceAssignOrderRequest?: ForceAssignOrderRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<SuccessResponse>(`${this.basePath}/dashboard/assignTechnicianToOrder`,
            forceAssignOrderRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Block Technician
     * 
     * @param token User Access Token
     * @param blockTechnicianRequest Block Technician Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public blockTechnician(token?: string, blockTechnicianRequest?: BlockTechnicianRequest, observe?: 'body', reportProgress?: boolean): Observable<Technician>;
    public blockTechnician(token?: string, blockTechnicianRequest?: BlockTechnicianRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Technician>>;
    public blockTechnician(token?: string, blockTechnicianRequest?: BlockTechnicianRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Technician>>;
    public blockTechnician(token?: string, blockTechnicianRequest?: BlockTechnicianRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Technician>(`${this.basePath}/dashboard/blockTechnician`,
            blockTechnicianRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Block User
     * 
     * @param token User Access Token
     * @param blockUserRequest Block User Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public blockUser(token?: string, blockUserRequest?: BlockUserRequest, observe?: 'body', reportProgress?: boolean): Observable<User>;
    public blockUser(token?: string, blockUserRequest?: BlockUserRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<User>>;
    public blockUser(token?: string, blockUserRequest?: BlockUserRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<User>>;
    public blockUser(token?: string, blockUserRequest?: BlockUserRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<User>(`${this.basePath}/dashboard/blockUser`,
            blockUserRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Customer Cancel Order
     * 
     * @param token User Access Token
     * @param cancelOrderByCustomerRequest Cancel Order By Customer Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public cancelOrderByCustomer(token?: string, cancelOrderByCustomerRequest?: CancelOrderByCustomerRequest, observe?: 'body', reportProgress?: boolean): Observable<SuccessResponse>;
    public cancelOrderByCustomer(token?: string, cancelOrderByCustomerRequest?: CancelOrderByCustomerRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessResponse>>;
    public cancelOrderByCustomer(token?: string, cancelOrderByCustomerRequest?: CancelOrderByCustomerRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessResponse>>;
    public cancelOrderByCustomer(token?: string, cancelOrderByCustomerRequest?: CancelOrderByCustomerRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<SuccessResponse>(`${this.basePath}/dashboard/cancelOrderByCustomer`,
            cancelOrderByCustomerRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Technician Cancel Order
     * 
     * @param token User Access Token
     * @param cancelOrderByTechnicianRequest Cancel Order By Technician Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public cancelOrderByTechnician(token?: string, cancelOrderByTechnicianRequest?: CancelOrderByTechnicianRequest, observe?: 'body', reportProgress?: boolean): Observable<SuccessResponse>;
    public cancelOrderByTechnician(token?: string, cancelOrderByTechnicianRequest?: CancelOrderByTechnicianRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessResponse>>;
    public cancelOrderByTechnician(token?: string, cancelOrderByTechnicianRequest?: CancelOrderByTechnicianRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessResponse>>;
    public cancelOrderByTechnician(token?: string, cancelOrderByTechnicianRequest?: CancelOrderByTechnicianRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<SuccessResponse>(`${this.basePath}/dashboard/cancelOrderByTechnician`,
            cancelOrderByTechnicianRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Customer Cancel Visit
     * 
     * @param token User Access Token
     * @param customerCancelVisitRequest Cancel Visit By Customer Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public cancelVisitByCustomer(token?: string, customerCancelVisitRequest?: CustomerCancelVisitRequest, observe?: 'body', reportProgress?: boolean): Observable<SuccessResponse>;
    public cancelVisitByCustomer(token?: string, customerCancelVisitRequest?: CustomerCancelVisitRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessResponse>>;
    public cancelVisitByCustomer(token?: string, customerCancelVisitRequest?: CustomerCancelVisitRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessResponse>>;
    public cancelVisitByCustomer(token?: string, customerCancelVisitRequest?: CustomerCancelVisitRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<SuccessResponse>(`${this.basePath}/dashboard/cancelVisitByCustomer`,
            customerCancelVisitRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Change Order Time
     * 
     * @param token User Access Token
     * @param changeOrderTimeRequest Change Order Time Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public changeOrderTime(token?: string, changeOrderTimeRequest?: ChangeOrderTimeRequest, observe?: 'body', reportProgress?: boolean): Observable<Order>;
    public changeOrderTime(token?: string, changeOrderTimeRequest?: ChangeOrderTimeRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Order>>;
    public changeOrderTime(token?: string, changeOrderTimeRequest?: ChangeOrderTimeRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Order>>;
    public changeOrderTime(token?: string, changeOrderTimeRequest?: ChangeOrderTimeRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Order>(`${this.basePath}/dashboard/changeOrderTime`,
            changeOrderTimeRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve Available Slots
     * 
     * @param token User Access Token
     * @param checkAvailableSlotsRequest Check Available Slots Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public checkAvailableSlots(token?: string, checkAvailableSlotsRequest?: CheckAvailableSlotsRequest, observe?: 'body', reportProgress?: boolean): Observable<AvailableSlotsResponse>;
    public checkAvailableSlots(token?: string, checkAvailableSlotsRequest?: CheckAvailableSlotsRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AvailableSlotsResponse>>;
    public checkAvailableSlots(token?: string, checkAvailableSlotsRequest?: CheckAvailableSlotsRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AvailableSlotsResponse>>;
    public checkAvailableSlots(token?: string, checkAvailableSlotsRequest?: CheckAvailableSlotsRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<AvailableSlotsResponse>(`${this.basePath}/dashboard/checkAvailableSlots`,
            checkAvailableSlotsRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Technician Confirm Order
     * 
     * @param token User Access Token
     * @param confirmOrderRequest Confirm Order Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public confirmOrderByTechnician(token?: string, confirmOrderRequest?: ConfirmOrderRequest1, observe?: 'body', reportProgress?: boolean): Observable<SuccessResponse>;
    public confirmOrderByTechnician(token?: string, confirmOrderRequest?: ConfirmOrderRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessResponse>>;
    public confirmOrderByTechnician(token?: string, confirmOrderRequest?: ConfirmOrderRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessResponse>>;
    public confirmOrderByTechnician(token?: string, confirmOrderRequest?: ConfirmOrderRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<SuccessResponse>(`${this.basePath}/dashboard/confirmOrder`,
            confirmOrderRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create New Branch
     * 
     * @param token User Access Token
     * @param newBusinessBranchRequest New Branch Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createBranch(token?: string, newBusinessBranchRequest?: NewBusinessBranchRequest, observe?: 'body', reportProgress?: boolean): Observable<BusinessBranch>;
    public createBranch(token?: string, newBusinessBranchRequest?: NewBusinessBranchRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BusinessBranch>>;
    public createBranch(token?: string, newBusinessBranchRequest?: NewBusinessBranchRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BusinessBranch>>;
    public createBranch(token?: string, newBusinessBranchRequest?: NewBusinessBranchRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<BusinessBranch>(`${this.basePath}/dashboard/createBranch`,
            newBusinessBranchRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create New Business
     * 
     * @param token User Access Token
     * @param newBusinessRequest New Business Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createBusiness(token?: string, newBusinessRequest?: NewBusinessRequest, observe?: 'body', reportProgress?: boolean): Observable<Business>;
    public createBusiness(token?: string, newBusinessRequest?: NewBusinessRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Business>>;
    public createBusiness(token?: string, newBusinessRequest?: NewBusinessRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Business>>;
    public createBusiness(token?: string, newBusinessRequest?: NewBusinessRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Business>(`${this.basePath}/dashboard/createBusiness`,
            newBusinessRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create New Business Order
     * 
     * @param token User Access Token
     * @param newBusinessOrderRequest New Business Order Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createBusinessOrder(token?: string, newBusinessOrderRequest?: NewBusinessOrderRequest, observe?: 'body', reportProgress?: boolean): Observable<Order>;
    public createBusinessOrder(token?: string, newBusinessOrderRequest?: NewBusinessOrderRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Order>>;
    public createBusinessOrder(token?: string, newBusinessOrderRequest?: NewBusinessOrderRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Order>>;
    public createBusinessOrder(token?: string, newBusinessOrderRequest?: NewBusinessOrderRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Order>(`${this.basePath}/dashboard/business/order`,
            newBusinessOrderRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create Root Category
     * 
     * @param token User Access Token
     * @param createRootCategoryRequest Create Root Category Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createCategory(token?: string, createRootCategoryRequest?: CreateRootCategoryRequest, observe?: 'body', reportProgress?: boolean): Observable<Category>;
    public createCategory(token?: string, createRootCategoryRequest?: CreateRootCategoryRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Category>>;
    public createCategory(token?: string, createRootCategoryRequest?: CreateRootCategoryRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Category>>;
    public createCategory(token?: string, createRootCategoryRequest?: CreateRootCategoryRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Category>(`${this.basePath}/dashboard/createCategory`,
            createRootCategoryRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create New Contract
     * 
     * @param token User Access Token
     * @param newBusinessContractRequest New Contract Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createContract(token?: string, newBusinessContractRequest?: NewBusinessContractRequest, observe?: 'body', reportProgress?: boolean): Observable<BusinessContract>;
    public createContract(token?: string, newBusinessContractRequest?: NewBusinessContractRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BusinessContract>>;
    public createContract(token?: string, newBusinessContractRequest?: NewBusinessContractRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BusinessContract>>;
    public createContract(token?: string, newBusinessContractRequest?: NewBusinessContractRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<BusinessContract>(`${this.basePath}/dashboard/createContract`,
            newBusinessContractRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create New Customer
     * 
     * @param token User Access Token
     * @param newCustomerRequest New Customer Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createCustomer(token?: string, newCustomerRequest?: NewCustomerRequest, observe?: 'body', reportProgress?: boolean): Observable<Customer>;
    public createCustomer(token?: string, newCustomerRequest?: NewCustomerRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Customer>>;
    public createCustomer(token?: string, newCustomerRequest?: NewCustomerRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Customer>>;
    public createCustomer(token?: string, newCustomerRequest?: NewCustomerRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Customer>(`${this.basePath}/dashboard/createCustomer`,
            newCustomerRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create New Visit
     * 
     * @param token User Access Token
     * @param newVisitRequest New Visit Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createNewVisit(token?: string, newVisitRequest?: NewVisitRequest, observe?: 'body', reportProgress?: boolean): Observable<Order>;
    public createNewVisit(token?: string, newVisitRequest?: NewVisitRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Order>>;
    public createNewVisit(token?: string, newVisitRequest?: NewVisitRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Order>>;
    public createNewVisit(token?: string, newVisitRequest?: NewVisitRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Order>(`${this.basePath}/dashboard/createVisit`,
            newVisitRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Opportunity Created
     * 
     * @param token User Access Token
     * @param newOpportunityRequest New Opportunity Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createOpportunity(token?: string, newOpportunityRequest?: NewOpportunityRequest, observe?: 'body', reportProgress?: boolean): Observable<Opportunity>;
    public createOpportunity(token?: string, newOpportunityRequest?: NewOpportunityRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Opportunity>>;
    public createOpportunity(token?: string, newOpportunityRequest?: NewOpportunityRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Opportunity>>;
    public createOpportunity(token?: string, newOpportunityRequest?: NewOpportunityRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Opportunity>(`${this.basePath}/dashboard/createOpportunity`,
            newOpportunityRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create New Order
     * 
     * @param token User Access Token
     * @param newOrderRequest New Order Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createOrder(token?: string, newOrderRequest?: NewOrderRequest, observe?: 'body', reportProgress?: boolean): Observable<Order>;
    public createOrder(token?: string, newOrderRequest?: NewOrderRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Order>>;
    public createOrder(token?: string, newOrderRequest?: NewOrderRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Order>>;
    public createOrder(token?: string, newOrderRequest?: NewOrderRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Order>(`${this.basePath}/dashboard/createOrder`,
            newOrderRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create New Role
     * 
     * @param token User Access Token
     * @param newRoleRequest New Role Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createRole(token?: string, newRoleRequest?: NewRoleRequest, observe?: 'body', reportProgress?: boolean): Observable<Role>;
    public createRole(token?: string, newRoleRequest?: NewRoleRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Role>>;
    public createRole(token?: string, newRoleRequest?: NewRoleRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Role>>;
    public createRole(token?: string, newRoleRequest?: NewRoleRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Role>(`${this.basePath}/dashboard/createRole`,
            newRoleRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create Service
     * 
     * @param token User Access Token
     * @param createServiceRequest Create Service Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createService(token?: string, createServiceRequest?: CreateServiceRequest, observe?: 'body', reportProgress?: boolean): Observable<ServiceResponse>;
    public createService(token?: string, createServiceRequest?: CreateServiceRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ServiceResponse>>;
    public createService(token?: string, createServiceRequest?: CreateServiceRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ServiceResponse>>;
    public createService(token?: string, createServiceRequest?: CreateServiceRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ServiceResponse>(`${this.basePath}/dashboard/createService`,
            createServiceRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create SubCategory
     * 
     * @param token User Access Token
     * @param createSubCategoryRequest Create SubCategory Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createSubCategory(token?: string, createSubCategoryRequest?: CreateSubCategoryRequest, observe?: 'body', reportProgress?: boolean): Observable<SubCategory>;
    public createSubCategory(token?: string, createSubCategoryRequest?: CreateSubCategoryRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SubCategory>>;
    public createSubCategory(token?: string, createSubCategoryRequest?: CreateSubCategoryRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SubCategory>>;
    public createSubCategory(token?: string, createSubCategoryRequest?: CreateSubCategoryRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<SubCategory>(`${this.basePath}/dashboard/createSubCategory`,
            createSubCategoryRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create New Technician
     * 
     * @param token User Access Token
     * @param newTechnicianRequest New Technician Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createTechnician(token?: string, newTechnicianRequest?: NewTechnicianRequest, observe?: 'body', reportProgress?: boolean): Observable<Technician>;
    public createTechnician(token?: string, newTechnicianRequest?: NewTechnicianRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Technician>>;
    public createTechnician(token?: string, newTechnicianRequest?: NewTechnicianRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Technician>>;
    public createTechnician(token?: string, newTechnicianRequest?: NewTechnicianRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Technician>(`${this.basePath}/dashboard/createTechnician`,
            newTechnicianRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create New User
     * 
     * @param token User Access Token
     * @param newUserRequest New User Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createUser(token?: string, newUserRequest?: NewUserRequest, observe?: 'body', reportProgress?: boolean): Observable<User>;
    public createUser(token?: string, newUserRequest?: NewUserRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<User>>;
    public createUser(token?: string, newUserRequest?: NewUserRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<User>>;
    public createUser(token?: string, newUserRequest?: NewUserRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<User>(`${this.basePath}/dashboard/createUser`,
            newUserRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete Busy Slot By ID
     * 
     * @param busySlotId Busy Slot ID
     * @param token User Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteBusySlotById(busySlotId: string, token?: string, observe?: 'body', reportProgress?: boolean): Observable<SuccessResponse>;
    public deleteBusySlotById(busySlotId: string, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessResponse>>;
    public deleteBusySlotById(busySlotId: string, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessResponse>>;
    public deleteBusySlotById(busySlotId: string, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (busySlotId === null || busySlotId === undefined) {
            throw new Error('Required parameter busySlotId was null or undefined when calling deleteBusySlotById.');
        }


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<SuccessResponse>(`${this.basePath}/dashboard/busyslot/${encodeURIComponent(String(busySlotId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete Customer Address
     * 
     * @param customerId Customer ID
     * @param addressId Address ID
     * @param token User Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteCustomerAddressById(customerId: string, addressId: string, token?: string, observe?: 'body', reportProgress?: boolean): Observable<Customer>;
    public deleteCustomerAddressById(customerId: string, addressId: string, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Customer>>;
    public deleteCustomerAddressById(customerId: string, addressId: string, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Customer>>;
    public deleteCustomerAddressById(customerId: string, addressId: string, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling deleteCustomerAddressById.');
        }

        if (addressId === null || addressId === undefined) {
            throw new Error('Required parameter addressId was null or undefined when calling deleteCustomerAddressById.');
        }


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<Customer>(`${this.basePath}/dashboard/customer/address/${encodeURIComponent(String(customerId))}/${encodeURIComponent(String(addressId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete Role By ID
     * 
     * @param roleId Role ID
     * @param token User Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteRoleById(roleId: string, token?: string, observe?: 'body', reportProgress?: boolean): Observable<SuccessResponse>;
    public deleteRoleById(roleId: string, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessResponse>>;
    public deleteRoleById(roleId: string, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessResponse>>;
    public deleteRoleById(roleId: string, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (roleId === null || roleId === undefined) {
            throw new Error('Required parameter roleId was null or undefined when calling deleteRoleById.');
        }


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<SuccessResponse>(`${this.basePath}/dashboard/role/${encodeURIComponent(String(roleId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * User Finalize Order
     * 
     * @param token User Access Token
     * @param finalizeOrderRequest Finalize Order Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public finalizeOrderByTechnician(token?: string, finalizeOrderRequest?: FinalizeOrderRequest, observe?: 'body', reportProgress?: boolean): Observable<Order>;
    public finalizeOrderByTechnician(token?: string, finalizeOrderRequest?: FinalizeOrderRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Order>>;
    public finalizeOrderByTechnician(token?: string, finalizeOrderRequest?: FinalizeOrderRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Order>>;
    public finalizeOrderByTechnician(token?: string, finalizeOrderRequest?: FinalizeOrderRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Order>(`${this.basePath}/dashboard/finalizeOrder`,
            finalizeOrderRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Force Update Order
     * 
     * @param token User Access Token
     * @param forceUpdateOrderServicesRequest Order Force Update Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public forceUpdateOrderServices(token?: string, forceUpdateOrderServicesRequest?: ForceUpdateOrderServicesRequest, observe?: 'body', reportProgress?: boolean): Observable<Order>;
    public forceUpdateOrderServices(token?: string, forceUpdateOrderServicesRequest?: ForceUpdateOrderServicesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Order>>;
    public forceUpdateOrderServices(token?: string, forceUpdateOrderServicesRequest?: ForceUpdateOrderServicesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Order>>;
    public forceUpdateOrderServices(token?: string, forceUpdateOrderServicesRequest?: ForceUpdateOrderServicesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Order>(`${this.basePath}/dashboard/forceUpdateOrderServices`,
            forceUpdateOrderServicesRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Force Update Order Visit
     * 
     * @param token User Access Token
     * @param forceUpdateOrderVisitRequest Order Force Update Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public forceUpdateOrderVisit(token?: string, forceUpdateOrderVisitRequest?: ForceUpdateOrderVisitRequest, observe?: 'body', reportProgress?: boolean): Observable<Order>;
    public forceUpdateOrderVisit(token?: string, forceUpdateOrderVisitRequest?: ForceUpdateOrderVisitRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Order>>;
    public forceUpdateOrderVisit(token?: string, forceUpdateOrderVisitRequest?: ForceUpdateOrderVisitRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Order>>;
    public forceUpdateOrderVisit(token?: string, forceUpdateOrderVisitRequest?: ForceUpdateOrderVisitRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Order>(`${this.basePath}/dashboard/forceUpdateOrderVisit`,
            forceUpdateOrderVisitRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve Active Coupons
     * 
     * @param customerId Customer ID
     * @param categoryId Category ID
     * @param areaId Area ID
     * @param date Order Date
     * @param token User Access Token
     * @param subareaId Subarea ID
     * @param orderId Order ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getActiveCoupons(customerId: string, categoryId: string, areaId: string, date: string, token?: string, subareaId?: string, orderId?: string, observe?: 'body', reportProgress?: boolean): Observable<CouponsResponse>;
    public getActiveCoupons(customerId: string, categoryId: string, areaId: string, date: string, token?: string, subareaId?: string, orderId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CouponsResponse>>;
    public getActiveCoupons(customerId: string, categoryId: string, areaId: string, date: string, token?: string, subareaId?: string, orderId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CouponsResponse>>;
    public getActiveCoupons(customerId: string, categoryId: string, areaId: string, date: string, token?: string, subareaId?: string, orderId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling getActiveCoupons.');
        }

        if (categoryId === null || categoryId === undefined) {
            throw new Error('Required parameter categoryId was null or undefined when calling getActiveCoupons.');
        }

        if (areaId === null || areaId === undefined) {
            throw new Error('Required parameter areaId was null or undefined when calling getActiveCoupons.');
        }

        if (date === null || date === undefined) {
            throw new Error('Required parameter date was null or undefined when calling getActiveCoupons.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (customerId !== undefined && customerId !== null) {
            queryParameters = queryParameters.set('customerId', <any>customerId);
        }
        if (categoryId !== undefined && categoryId !== null) {
            queryParameters = queryParameters.set('categoryId', <any>categoryId);
        }
        if (areaId !== undefined && areaId !== null) {
            queryParameters = queryParameters.set('areaId', <any>areaId);
        }
        if (subareaId !== undefined && subareaId !== null) {
            queryParameters = queryParameters.set('subareaId', <any>subareaId);
        }
        if (date !== undefined && date !== null) {
            queryParameters = queryParameters.set('date', <any>date);
        }
        if (orderId !== undefined && orderId !== null) {
            queryParameters = queryParameters.set('orderId', <any>orderId);
        }

        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CouponsResponse>(`${this.basePath}/dashboard/listActiveCoupons`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve Areas
     * 
     * @param token User Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllAreas(token?: string, observe?: 'body', reportProgress?: boolean): Observable<AreasResponse>;
    public getAllAreas(token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AreasResponse>>;
    public getAllAreas(token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AreasResponse>>;
    public getAllAreas(token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<AreasResponse>(`${this.basePath}/dashboard/listAllAreas`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve Categories
     * 
     * @param token User Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllCategories(token?: string, observe?: 'body', reportProgress?: boolean): Observable<CategoriesResponse>;
    public getAllCategories(token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CategoriesResponse>>;
    public getAllCategories(token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CategoriesResponse>>;
    public getAllCategories(token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CategoriesResponse>(`${this.basePath}/dashboard/listAllCategories`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve Customers ( Sorted )
     * 
     * @param sort Sorted by Column
     * @param dir Sorting Direction
     * @param take Number of Technicians Retrieved ( Pagination )
     * @param skip Skipped Technicians ( Pagination )
     * @param token User Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllCustomers(sort: string, dir: string, take: number, skip: number, token?: string, observe?: 'body', reportProgress?: boolean): Observable<CustomersResponse>;
    public getAllCustomers(sort: string, dir: string, take: number, skip: number, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomersResponse>>;
    public getAllCustomers(sort: string, dir: string, take: number, skip: number, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomersResponse>>;
    public getAllCustomers(sort: string, dir: string, take: number, skip: number, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sort === null || sort === undefined) {
            throw new Error('Required parameter sort was null or undefined when calling getAllCustomers.');
        }

        if (dir === null || dir === undefined) {
            throw new Error('Required parameter dir was null or undefined when calling getAllCustomers.');
        }

        if (take === null || take === undefined) {
            throw new Error('Required parameter take was null or undefined when calling getAllCustomers.');
        }

        if (skip === null || skip === undefined) {
            throw new Error('Required parameter skip was null or undefined when calling getAllCustomers.');
        }


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CustomersResponse>(`${this.basePath}/dashboard/listAllCustomers/${encodeURIComponent(String(sort))}/${encodeURIComponent(String(dir))}/${encodeURIComponent(String(take))}/${encodeURIComponent(String(skip))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve Orders ( Sorted )
     * 
     * @param sort Sorted by Column
     * @param dir Sorting Direction
     * @param take Number of Orders Retrieved ( Pagination )
     * @param skip Skipped Orders ( Pagination )
     * @param token User Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllOrders(sort: string, dir: string, take: number, skip: number, token?: string, observe?: 'body', reportProgress?: boolean): Observable<OrdersResponse>;
    public getAllOrders(sort: string, dir: string, take: number, skip: number, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrdersResponse>>;
    public getAllOrders(sort: string, dir: string, take: number, skip: number, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrdersResponse>>;
    public getAllOrders(sort: string, dir: string, take: number, skip: number, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sort === null || sort === undefined) {
            throw new Error('Required parameter sort was null or undefined when calling getAllOrders.');
        }

        if (dir === null || dir === undefined) {
            throw new Error('Required parameter dir was null or undefined when calling getAllOrders.');
        }

        if (take === null || take === undefined) {
            throw new Error('Required parameter take was null or undefined when calling getAllOrders.');
        }

        if (skip === null || skip === undefined) {
            throw new Error('Required parameter skip was null or undefined when calling getAllOrders.');
        }


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<OrdersResponse>(`${this.basePath}/dashboard/listAllOrders/${encodeURIComponent(String(sort))}/${encodeURIComponent(String(dir))}/${encodeURIComponent(String(take))}/${encodeURIComponent(String(skip))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve permissions
     * 
     * @param token User Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllPermissions(token?: string, observe?: 'body', reportProgress?: boolean): Observable<PermissionsResponse>;
    public getAllPermissions(token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PermissionsResponse>>;
    public getAllPermissions(token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PermissionsResponse>>;
    public getAllPermissions(token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<PermissionsResponse>(`${this.basePath}/dashboard/listAllPermissions`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve Subcategories
     * 
     * @param token User Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllSubCategories(token?: string, observe?: 'body', reportProgress?: boolean): Observable<CategoriesResponse>;
    public getAllSubCategories(token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CategoriesResponse>>;
    public getAllSubCategories(token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CategoriesResponse>>;
    public getAllSubCategories(token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CategoriesResponse>(`${this.basePath}/dashboard/listAllSubCategories`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve Technicians ( Sorted )
     * 
     * @param sort Sorted by Column
     * @param dir Sorting Direction
     * @param take Number of Technicians Retrieved ( Pagination )
     * @param skip Skipped Technicians ( Pagination )
     * @param token User Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllTechnicians(sort: string, dir: string, take: number, skip: number, token?: string, observe?: 'body', reportProgress?: boolean): Observable<TechniciansResponse>;
    public getAllTechnicians(sort: string, dir: string, take: number, skip: number, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TechniciansResponse>>;
    public getAllTechnicians(sort: string, dir: string, take: number, skip: number, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TechniciansResponse>>;
    public getAllTechnicians(sort: string, dir: string, take: number, skip: number, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sort === null || sort === undefined) {
            throw new Error('Required parameter sort was null or undefined when calling getAllTechnicians.');
        }

        if (dir === null || dir === undefined) {
            throw new Error('Required parameter dir was null or undefined when calling getAllTechnicians.');
        }

        if (take === null || take === undefined) {
            throw new Error('Required parameter take was null or undefined when calling getAllTechnicians.');
        }

        if (skip === null || skip === undefined) {
            throw new Error('Required parameter skip was null or undefined when calling getAllTechnicians.');
        }


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<TechniciansResponse>(`${this.basePath}/dashboard/listAllTechnicians/${encodeURIComponent(String(sort))}/${encodeURIComponent(String(dir))}/${encodeURIComponent(String(take))}/${encodeURIComponent(String(skip))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve Users ( Sorted )
     * 
     * @param sort Sorted by Column
     * @param dir Sorting Direction
     * @param take Number of Users Retrieved ( Pagination )
     * @param skip Skipped Users ( Pagination )
     * @param token User Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllUsers(sort: string, dir: string, take: number, skip: number, token?: string, observe?: 'body', reportProgress?: boolean): Observable<UsersResponse>;
    public getAllUsers(sort: string, dir: string, take: number, skip: number, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UsersResponse>>;
    public getAllUsers(sort: string, dir: string, take: number, skip: number, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UsersResponse>>;
    public getAllUsers(sort: string, dir: string, take: number, skip: number, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sort === null || sort === undefined) {
            throw new Error('Required parameter sort was null or undefined when calling getAllUsers.');
        }

        if (dir === null || dir === undefined) {
            throw new Error('Required parameter dir was null or undefined when calling getAllUsers.');
        }

        if (take === null || take === undefined) {
            throw new Error('Required parameter take was null or undefined when calling getAllUsers.');
        }

        if (skip === null || skip === undefined) {
            throw new Error('Required parameter skip was null or undefined when calling getAllUsers.');
        }


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<UsersResponse>(`${this.basePath}/dashboard/listAllUsers/${encodeURIComponent(String(sort))}/${encodeURIComponent(String(dir))}/${encodeURIComponent(String(take))}/${encodeURIComponent(String(skip))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Branch By ID
     * 
     * @param businessId Business ID
     * @param branchId Branch ID
     * @param token User Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBranchByIdAndBusinessId(businessId: string, branchId: string, token?: string, observe?: 'body', reportProgress?: boolean): Observable<BusinessBranch>;
    public getBranchByIdAndBusinessId(businessId: string, branchId: string, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BusinessBranch>>;
    public getBranchByIdAndBusinessId(businessId: string, branchId: string, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BusinessBranch>>;
    public getBranchByIdAndBusinessId(businessId: string, branchId: string, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (businessId === null || businessId === undefined) {
            throw new Error('Required parameter businessId was null or undefined when calling getBranchByIdAndBusinessId.');
        }

        if (branchId === null || branchId === undefined) {
            throw new Error('Required parameter branchId was null or undefined when calling getBranchByIdAndBusinessId.');
        }


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<BusinessBranch>(`${this.basePath}/dashboard/branch/${encodeURIComponent(String(businessId))}/${encodeURIComponent(String(branchId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Business By ID
     * 
     * @param businessId Business ID
     * @param token User Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBusinessById(businessId: string, token?: string, observe?: 'body', reportProgress?: boolean): Observable<Business>;
    public getBusinessById(businessId: string, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Business>>;
    public getBusinessById(businessId: string, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Business>>;
    public getBusinessById(businessId: string, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (businessId === null || businessId === undefined) {
            throw new Error('Required parameter businessId was null or undefined when calling getBusinessById.');
        }


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Business>(`${this.basePath}/dashboard/business/${encodeURIComponent(String(businessId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve Businesses
     * 
     * @param keyword 
     * @param token User Access Token
     * @param take Number of Businesses Retrieved ( Pagination )
     * @param skip Skipped Businesses ( Pagination )
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBusinesses(keyword: string, token?: string, take?: number, skip?: number, observe?: 'body', reportProgress?: boolean): Observable<BusinessesResponse>;
    public getBusinesses(keyword: string, token?: string, take?: number, skip?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BusinessesResponse>>;
    public getBusinesses(keyword: string, token?: string, take?: number, skip?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BusinessesResponse>>;
    public getBusinesses(keyword: string, token?: string, take?: number, skip?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (keyword === null || keyword === undefined) {
            throw new Error('Required parameter keyword was null or undefined when calling getBusinesses.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (keyword !== undefined && keyword !== null) {
            queryParameters = queryParameters.set('keyword', <any>keyword);
        }
        if (take !== undefined && take !== null) {
            queryParameters = queryParameters.set('take', <any>take);
        }
        if (skip !== undefined && skip !== null) {
            queryParameters = queryParameters.set('skip', <any>skip);
        }

        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<BusinessesResponse>(`${this.basePath}/dashboard/listBusinesses`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve all active category services
     * 
     * @param categoryId Category ID
     * @param token User Access Token
     * @param orderId Order ID
     * @param areaId Area ID
     * @param subareaId Subarea ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCategoryServices(categoryId: string, token?: string, orderId?: string, areaId?: string, subareaId?: string, observe?: 'body', reportProgress?: boolean): Observable<ServicesResponse>;
    public getCategoryServices(categoryId: string, token?: string, orderId?: string, areaId?: string, subareaId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ServicesResponse>>;
    public getCategoryServices(categoryId: string, token?: string, orderId?: string, areaId?: string, subareaId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ServicesResponse>>;
    public getCategoryServices(categoryId: string, token?: string, orderId?: string, areaId?: string, subareaId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (categoryId === null || categoryId === undefined) {
            throw new Error('Required parameter categoryId was null or undefined when calling getCategoryServices.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (categoryId !== undefined && categoryId !== null) {
            queryParameters = queryParameters.set('categoryId', <any>categoryId);
        }
        if (orderId !== undefined && orderId !== null) {
            queryParameters = queryParameters.set('orderId', <any>orderId);
        }
        if (areaId !== undefined && areaId !== null) {
            queryParameters = queryParameters.set('areaId', <any>areaId);
        }
        if (subareaId !== undefined && subareaId !== null) {
            queryParameters = queryParameters.set('subareaId', <any>subareaId);
        }

        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ServicesResponse>(`${this.basePath}/dashboard/list-category-services`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Contract By ID
     * 
     * @param businessId Business ID
     * @param contractId Contract ID
     * @param token User Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getContractByIdAndBusinessId(businessId: string, contractId: string, token?: string, observe?: 'body', reportProgress?: boolean): Observable<BusinessContract>;
    public getContractByIdAndBusinessId(businessId: string, contractId: string, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BusinessContract>>;
    public getContractByIdAndBusinessId(businessId: string, contractId: string, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BusinessContract>>;
    public getContractByIdAndBusinessId(businessId: string, contractId: string, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (businessId === null || businessId === undefined) {
            throw new Error('Required parameter businessId was null or undefined when calling getContractByIdAndBusinessId.');
        }

        if (contractId === null || contractId === undefined) {
            throw new Error('Required parameter contractId was null or undefined when calling getContractByIdAndBusinessId.');
        }


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<BusinessContract>(`${this.basePath}/dashboard/contract/${encodeURIComponent(String(businessId))}/${encodeURIComponent(String(contractId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve Coupons ( Sorted )
     * 
     * @param take Number of Technicians Retrieved ( Pagination )
     * @param skip Skipped Technicians ( Pagination )
     * @param token User Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCoupons(take: number, skip: number, token?: string, observe?: 'body', reportProgress?: boolean): Observable<GetCouponsResponse>;
    public getCoupons(take: number, skip: number, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetCouponsResponse>>;
    public getCoupons(take: number, skip: number, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetCouponsResponse>>;
    public getCoupons(take: number, skip: number, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (take === null || take === undefined) {
            throw new Error('Required parameter take was null or undefined when calling getCoupons.');
        }

        if (skip === null || skip === undefined) {
            throw new Error('Required parameter skip was null or undefined when calling getCoupons.');
        }


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<GetCouponsResponse>(`${this.basePath}/dashboard/coupons/${encodeURIComponent(String(take))}/${encodeURIComponent(String(skip))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Customer Address
     * 
     * @param customerId Customer ID
     * @param addressId Address ID
     * @param token User Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCustomerAddressById(customerId: string, addressId: string, token?: string, observe?: 'body', reportProgress?: boolean): Observable<CustomerAddress>;
    public getCustomerAddressById(customerId: string, addressId: string, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomerAddress>>;
    public getCustomerAddressById(customerId: string, addressId: string, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomerAddress>>;
    public getCustomerAddressById(customerId: string, addressId: string, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling getCustomerAddressById.');
        }

        if (addressId === null || addressId === undefined) {
            throw new Error('Required parameter addressId was null or undefined when calling getCustomerAddressById.');
        }


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CustomerAddress>(`${this.basePath}/dashboard/customer/address/${encodeURIComponent(String(customerId))}/${encodeURIComponent(String(addressId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve Customer Info By Id
     * 
     * @param customerId Customer ID
     * @param token User Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCustomerById(customerId: string, token?: string, observe?: 'body', reportProgress?: boolean): Observable<Customer>;
    public getCustomerById(customerId: string, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Customer>>;
    public getCustomerById(customerId: string, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Customer>>;
    public getCustomerById(customerId: string, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling getCustomerById.');
        }


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Customer>(`${this.basePath}/dashboard/customer/${encodeURIComponent(String(customerId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve Customer
     * 
     * @param mobile Mobile
     * @param token User Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCustomerByMobile(mobile: string, token?: string, observe?: 'body', reportProgress?: boolean): Observable<CustomerCartResponse>;
    public getCustomerByMobile(mobile: string, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomerCartResponse>>;
    public getCustomerByMobile(mobile: string, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomerCartResponse>>;
    public getCustomerByMobile(mobile: string, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (mobile === null || mobile === undefined) {
            throw new Error('Required parameter mobile was null or undefined when calling getCustomerByMobile.');
        }


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CustomerCartResponse>(`${this.basePath}/dashboard/customer/mobile/${encodeURIComponent(String(mobile))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve Customer Info By Id
     * 
     * @param customerId Customer ID
     * @param take Number of Tickets Retrieved ( Pagination )
     * @param page Skipped Tickets ( Pagination )
     * @param token User Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCustomerTicketsById(customerId: string, take: number, page: number, token?: string, observe?: 'body', reportProgress?: boolean): Observable<CustomerTicketsResponse>;
    public getCustomerTicketsById(customerId: string, take: number, page: number, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomerTicketsResponse>>;
    public getCustomerTicketsById(customerId: string, take: number, page: number, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomerTicketsResponse>>;
    public getCustomerTicketsById(customerId: string, take: number, page: number, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling getCustomerTicketsById.');
        }

        if (take === null || take === undefined) {
            throw new Error('Required parameter take was null or undefined when calling getCustomerTicketsById.');
        }

        if (page === null || page === undefined) {
            throw new Error('Required parameter page was null or undefined when calling getCustomerTicketsById.');
        }


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CustomerTicketsResponse>(`${this.basePath}/dashboard/customerTickets/${encodeURIComponent(String(customerId))}/${encodeURIComponent(String(take))}/${encodeURIComponent(String(page))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve Customers ( Sorted )
     * 
     * @param keyword Keyword
     * @param token User Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCustomersByKeyword(keyword: string, token?: string, observe?: 'body', reportProgress?: boolean): Observable<CustomersResponse>;
    public getCustomersByKeyword(keyword: string, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomersResponse>>;
    public getCustomersByKeyword(keyword: string, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomersResponse>>;
    public getCustomersByKeyword(keyword: string, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (keyword === null || keyword === undefined) {
            throw new Error('Required parameter keyword was null or undefined when calling getCustomersByKeyword.');
        }


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CustomersResponse>(`${this.basePath}/dashboard/listCustomersByKeyword/${encodeURIComponent(String(keyword))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve Filtered Log
     * 
     * @param token User Access Token
     * @param filterLogRequest Filter Log Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFilteredLog(token?: string, filterLogRequest?: FilterLogRequest, observe?: 'body', reportProgress?: boolean): Observable<UserLogResponse>;
    public getFilteredLog(token?: string, filterLogRequest?: FilterLogRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserLogResponse>>;
    public getFilteredLog(token?: string, filterLogRequest?: FilterLogRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserLogResponse>>;
    public getFilteredLog(token?: string, filterLogRequest?: FilterLogRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<UserLogResponse>(`${this.basePath}/dashboard/getFilteredLog`,
            filterLogRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve Filtered Orders
     * 
     * @param token User Access Token
     * @param filterOrdersRequest Filter Orders Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFilteredOrders(token?: string, filterOrdersRequest?: FilterOrdersRequest, observe?: 'body', reportProgress?: boolean): Observable<OrdersResponse>;
    public getFilteredOrders(token?: string, filterOrdersRequest?: FilterOrdersRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrdersResponse>>;
    public getFilteredOrders(token?: string, filterOrdersRequest?: FilterOrdersRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrdersResponse>>;
    public getFilteredOrders(token?: string, filterOrdersRequest?: FilterOrdersRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<OrdersResponse>(`${this.basePath}/dashboard/getFilteredOrders`,
            filterOrdersRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve Filtered Technicians
     * 
     * @param token User Access Token
     * @param filterTechniciansRequest Filter Technicians Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFilteredTechnicians(token?: string, filterTechniciansRequest?: FilterTechniciansRequest, observe?: 'body', reportProgress?: boolean): Observable<TechniciansResponse>;
    public getFilteredTechnicians(token?: string, filterTechniciansRequest?: FilterTechniciansRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TechniciansResponse>>;
    public getFilteredTechnicians(token?: string, filterTechniciansRequest?: FilterTechniciansRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TechniciansResponse>>;
    public getFilteredTechnicians(token?: string, filterTechniciansRequest?: FilterTechniciansRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<TechniciansResponse>(`${this.basePath}/dashboard/getFilteredTechnicians`,
            filterTechniciansRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve Opportunities
     * 
     * @param token User Access Token
     * @param listOpportunitiesRequest List Opportunities Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOpportunities(token?: string, listOpportunitiesRequest?: ListOpportunitiesRequest, observe?: 'body', reportProgress?: boolean): Observable<OpportunitiesResponse>;
    public getOpportunities(token?: string, listOpportunitiesRequest?: ListOpportunitiesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OpportunitiesResponse>>;
    public getOpportunities(token?: string, listOpportunitiesRequest?: ListOpportunitiesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OpportunitiesResponse>>;
    public getOpportunities(token?: string, listOpportunitiesRequest?: ListOpportunitiesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<OpportunitiesResponse>(`${this.basePath}/dashboard/listOpportunities`,
            listOpportunitiesRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve Opportunity Info By Id
     * 
     * @param opportunityId Opportunity ID
     * @param token User Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOpportunityById(opportunityId: string, token?: string, observe?: 'body', reportProgress?: boolean): Observable<Opportunity>;
    public getOpportunityById(opportunityId: string, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Opportunity>>;
    public getOpportunityById(opportunityId: string, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Opportunity>>;
    public getOpportunityById(opportunityId: string, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (opportunityId === null || opportunityId === undefined) {
            throw new Error('Required parameter opportunityId was null or undefined when calling getOpportunityById.');
        }


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Opportunity>(`${this.basePath}/dashboard/opportunity/${encodeURIComponent(String(opportunityId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve Opportunity Types
     * 
     * @param token User Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOpportunityTypes(token?: string, observe?: 'body', reportProgress?: boolean): Observable<OpportunityTypesResponse>;
    public getOpportunityTypes(token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OpportunityTypesResponse>>;
    public getOpportunityTypes(token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OpportunityTypesResponse>>;
    public getOpportunityTypes(token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<OpportunityTypesResponse>(`${this.basePath}/dashboard/listOpportunityTypes`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * List Order Suggested Technicians
     * 
     * @param orderId Order ID
     * @param type Technician Type
     * @param keyword Keyword to search for technicians
     * @param take Number of technicians retrieved ( Pagination )
     * @param skip Technicians to skip ( Pagination )
     * @param token User Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrderSuggestedTechnicians(orderId: string, type: string, keyword: string, take: number, skip: number, token?: string, observe?: 'body', reportProgress?: boolean): Observable<SuggestedTechniciansResponse>;
    public getOrderSuggestedTechnicians(orderId: string, type: string, keyword: string, take: number, skip: number, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuggestedTechniciansResponse>>;
    public getOrderSuggestedTechnicians(orderId: string, type: string, keyword: string, take: number, skip: number, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuggestedTechniciansResponse>>;
    public getOrderSuggestedTechnicians(orderId: string, type: string, keyword: string, take: number, skip: number, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling getOrderSuggestedTechnicians.');
        }

        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling getOrderSuggestedTechnicians.');
        }

        if (keyword === null || keyword === undefined) {
            throw new Error('Required parameter keyword was null or undefined when calling getOrderSuggestedTechnicians.');
        }

        if (take === null || take === undefined) {
            throw new Error('Required parameter take was null or undefined when calling getOrderSuggestedTechnicians.');
        }

        if (skip === null || skip === undefined) {
            throw new Error('Required parameter skip was null or undefined when calling getOrderSuggestedTechnicians.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (orderId !== undefined && orderId !== null) {
            queryParameters = queryParameters.set('orderId', <any>orderId);
        }
        if (type !== undefined && type !== null) {
            queryParameters = queryParameters.set('type', <any>type);
        }
        if (keyword !== undefined && keyword !== null) {
            queryParameters = queryParameters.set('keyword', <any>keyword);
        }
        if (take !== undefined && take !== null) {
            queryParameters = queryParameters.set('take', <any>take);
        }
        if (skip !== undefined && skip !== null) {
            queryParameters = queryParameters.set('skip', <any>skip);
        }

        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<SuggestedTechniciansResponse>(`${this.basePath}/dashboard/listOrderSuggestedTechnicians`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve Orders Status Report
     * 
     * @param dateFrom Date From
     * @param dateTo Date To
     * @param token User Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrdersStatusReport(dateFrom: string, dateTo: string, token?: string, observe?: 'body', reportProgress?: boolean): Observable<OrdersStatusReportResponse>;
    public getOrdersStatusReport(dateFrom: string, dateTo: string, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrdersStatusReportResponse>>;
    public getOrdersStatusReport(dateFrom: string, dateTo: string, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrdersStatusReportResponse>>;
    public getOrdersStatusReport(dateFrom: string, dateTo: string, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (dateFrom === null || dateFrom === undefined) {
            throw new Error('Required parameter dateFrom was null or undefined when calling getOrdersStatusReport.');
        }

        if (dateTo === null || dateTo === undefined) {
            throw new Error('Required parameter dateTo was null or undefined when calling getOrdersStatusReport.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (dateFrom !== undefined && dateFrom !== null) {
            queryParameters = queryParameters.set('dateFrom', <any>dateFrom);
        }
        if (dateTo !== undefined && dateTo !== null) {
            queryParameters = queryParameters.set('dateTo', <any>dateTo);
        }

        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<OrdersStatusReportResponse>(`${this.basePath}/dashboard/getOrdersStatusReport`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * List Category Services in tree format
     * 
     * @param categoryId Category ID
     * @param token Customer Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getParentChilds(categoryId: string, token?: string, observe?: 'body', reportProgress?: boolean): Observable<CategoryChildsResponse>;
    public getParentChilds(categoryId: string, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CategoryChildsResponse>>;
    public getParentChilds(categoryId: string, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CategoryChildsResponse>>;
    public getParentChilds(categoryId: string, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (categoryId === null || categoryId === undefined) {
            throw new Error('Required parameter categoryId was null or undefined when calling getParentChilds.');
        }


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CategoryChildsResponse>(`${this.basePath}/dashboard/listServicesByParent/${encodeURIComponent(String(categoryId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve Rate Factors
     * 
     * @param token User Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRateFactors(token?: string, observe?: 'body', reportProgress?: boolean): Observable<RateFactorsResponse>;
    public getRateFactors(token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RateFactorsResponse>>;
    public getRateFactors(token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RateFactorsResponse>>;
    public getRateFactors(token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<RateFactorsResponse>(`${this.basePath}/dashboard/listRateFactors`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Role By ID
     * 
     * @param roleId Role ID
     * @param token User Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRoleById(roleId: string, token?: string, observe?: 'body', reportProgress?: boolean): Observable<Role>;
    public getRoleById(roleId: string, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Role>>;
    public getRoleById(roleId: string, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Role>>;
    public getRoleById(roleId: string, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (roleId === null || roleId === undefined) {
            throw new Error('Required parameter roleId was null or undefined when calling getRoleById.');
        }


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Role>(`${this.basePath}/dashboard/role/${encodeURIComponent(String(roleId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve Roles
     * 
     * @param keyword 
     * @param take Number of Roles Retrieved ( Pagination )
     * @param skip Skipped Roles ( Pagination )
     * @param token User Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRoles(keyword: string, take: number, skip: number, token?: string, observe?: 'body', reportProgress?: boolean): Observable<RolesResponse>;
    public getRoles(keyword: string, take: number, skip: number, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RolesResponse>>;
    public getRoles(keyword: string, take: number, skip: number, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RolesResponse>>;
    public getRoles(keyword: string, take: number, skip: number, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (keyword === null || keyword === undefined) {
            throw new Error('Required parameter keyword was null or undefined when calling getRoles.');
        }

        if (take === null || take === undefined) {
            throw new Error('Required parameter take was null or undefined when calling getRoles.');
        }

        if (skip === null || skip === undefined) {
            throw new Error('Required parameter skip was null or undefined when calling getRoles.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (keyword !== undefined && keyword !== null) {
            queryParameters = queryParameters.set('keyword', <any>keyword);
        }
        if (take !== undefined && take !== null) {
            queryParameters = queryParameters.set('take', <any>take);
        }
        if (skip !== undefined && skip !== null) {
            queryParameters = queryParameters.set('skip', <any>skip);
        }

        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<RolesResponse>(`${this.basePath}/dashboard/listRoles`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * List Category Services in tree format
     * 
     * @param categoryId Category ID
     * @param token User Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getServicesV2(categoryId: string, token?: string, observe?: 'body', reportProgress?: boolean): Observable<CategoryChildsResponseV2>;
    public getServicesV2(categoryId: string, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CategoryChildsResponseV2>>;
    public getServicesV2(categoryId: string, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CategoryChildsResponseV2>>;
    public getServicesV2(categoryId: string, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (categoryId === null || categoryId === undefined) {
            throw new Error('Required parameter categoryId was null or undefined when calling getServicesV2.');
        }


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CategoryChildsResponseV2>(`${this.basePath}/dashboard/services/${encodeURIComponent(String(categoryId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * List all subCategories that accepting new Services
     * 
     * @param categoryId Category ID
     * @param token Customer Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSubCategoriesAcceptingServices(categoryId: string, token?: string, observe?: 'body', reportProgress?: boolean): Observable<SubCategoriesResponse>;
    public getSubCategoriesAcceptingServices(categoryId: string, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SubCategoriesResponse>>;
    public getSubCategoriesAcceptingServices(categoryId: string, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SubCategoriesResponse>>;
    public getSubCategoriesAcceptingServices(categoryId: string, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (categoryId === null || categoryId === undefined) {
            throw new Error('Required parameter categoryId was null or undefined when calling getSubCategoriesAcceptingServices.');
        }


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<SubCategoriesResponse>(`${this.basePath}/dashboard/getSubCategoriesAcceptingServices/${encodeURIComponent(String(categoryId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve Tags
     * 
     * @param token User Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTags(token?: string, observe?: 'body', reportProgress?: boolean): Observable<TagsResponse>;
    public getTags(token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TagsResponse>>;
    public getTags(token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TagsResponse>>;
    public getTags(token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<TagsResponse>(`${this.basePath}/dashboard/listTags`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve Technician Busy Slots By Id
     * 
     * @param technicianId Technician ID
     * @param dateFrom Date From
     * @param dateTo Date To
     * @param token User Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTechnicianBusySlots(technicianId: string, dateFrom: string, dateTo: string, token?: string, observe?: 'body', reportProgress?: boolean): Observable<TechnicianBusySlotsResponse>;
    public getTechnicianBusySlots(technicianId: string, dateFrom: string, dateTo: string, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TechnicianBusySlotsResponse>>;
    public getTechnicianBusySlots(technicianId: string, dateFrom: string, dateTo: string, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TechnicianBusySlotsResponse>>;
    public getTechnicianBusySlots(technicianId: string, dateFrom: string, dateTo: string, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (technicianId === null || technicianId === undefined) {
            throw new Error('Required parameter technicianId was null or undefined when calling getTechnicianBusySlots.');
        }

        if (dateFrom === null || dateFrom === undefined) {
            throw new Error('Required parameter dateFrom was null or undefined when calling getTechnicianBusySlots.');
        }

        if (dateTo === null || dateTo === undefined) {
            throw new Error('Required parameter dateTo was null or undefined when calling getTechnicianBusySlots.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (technicianId !== undefined && technicianId !== null) {
            queryParameters = queryParameters.set('technicianId', <any>technicianId);
        }
        if (dateFrom !== undefined && dateFrom !== null) {
            queryParameters = queryParameters.set('dateFrom', <any>dateFrom);
        }
        if (dateTo !== undefined && dateTo !== null) {
            queryParameters = queryParameters.set('dateTo', <any>dateTo);
        }

        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<TechnicianBusySlotsResponse>(`${this.basePath}/dashboard/technicianBusySlots`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve Technician Info By Id
     * 
     * @param technicianId Technician ID
     * @param token User Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTechnicianById(technicianId: string, token?: string, observe?: 'body', reportProgress?: boolean): Observable<Technician>;
    public getTechnicianById(technicianId: string, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Technician>>;
    public getTechnicianById(technicianId: string, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Technician>>;
    public getTechnicianById(technicianId: string, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (technicianId === null || technicianId === undefined) {
            throw new Error('Required parameter technicianId was null or undefined when calling getTechnicianById.');
        }


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Technician>(`${this.basePath}/dashboard/technician/${encodeURIComponent(String(technicianId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve Technician History By Id
     * 
     * @param technicianId Technician ID
     * @param take Number of Records Retrieved ( Pagination )
     * @param skip Skipped Records ( Pagination )
     * @param token User Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTechnicianHistory(technicianId: string, take: number, skip: number, token?: string, observe?: 'body', reportProgress?: boolean): Observable<UserLogResponse>;
    public getTechnicianHistory(technicianId: string, take: number, skip: number, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserLogResponse>>;
    public getTechnicianHistory(technicianId: string, take: number, skip: number, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserLogResponse>>;
    public getTechnicianHistory(technicianId: string, take: number, skip: number, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (technicianId === null || technicianId === undefined) {
            throw new Error('Required parameter technicianId was null or undefined when calling getTechnicianHistory.');
        }

        if (take === null || take === undefined) {
            throw new Error('Required parameter take was null or undefined when calling getTechnicianHistory.');
        }

        if (skip === null || skip === undefined) {
            throw new Error('Required parameter skip was null or undefined when calling getTechnicianHistory.');
        }


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<UserLogResponse>(`${this.basePath}/dashboard/technician/history/${encodeURIComponent(String(technicianId))}/${encodeURIComponent(String(take))}/${encodeURIComponent(String(skip))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve Order Info By Id
     * 
     * @param orderId Order ID
     * @param technicianId Technician ID
     * @param token User Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTechnicianOrderFinancialDetails(orderId: string, technicianId: string, token?: string, observe?: 'body', reportProgress?: boolean): Observable<FinancialOrder>;
    public getTechnicianOrderFinancialDetails(orderId: string, technicianId: string, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FinancialOrder>>;
    public getTechnicianOrderFinancialDetails(orderId: string, technicianId: string, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FinancialOrder>>;
    public getTechnicianOrderFinancialDetails(orderId: string, technicianId: string, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling getTechnicianOrderFinancialDetails.');
        }

        if (technicianId === null || technicianId === undefined) {
            throw new Error('Required parameter technicianId was null or undefined when calling getTechnicianOrderFinancialDetails.');
        }


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<FinancialOrder>(`${this.basePath}/dashboard/orderFinancialDetails/${encodeURIComponent(String(orderId))}/${encodeURIComponent(String(technicianId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve Technician Unsettled Orders
     * 
     * @param technicianId Technician ID
     * @param token User Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTechnicianUnsettledOrders(technicianId: string, token?: string, observe?: 'body', reportProgress?: boolean): Observable<TechnicianUnsettledOrdersResponse>;
    public getTechnicianUnsettledOrders(technicianId: string, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TechnicianUnsettledOrdersResponse>>;
    public getTechnicianUnsettledOrders(technicianId: string, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TechnicianUnsettledOrdersResponse>>;
    public getTechnicianUnsettledOrders(technicianId: string, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (technicianId === null || technicianId === undefined) {
            throw new Error('Required parameter technicianId was null or undefined when calling getTechnicianUnsettledOrders.');
        }


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<TechnicianUnsettledOrdersResponse>(`${this.basePath}/dashboard/technicianUnsettledOrders/${encodeURIComponent(String(technicianId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve Technicians Requests Report
     * 
     * @param dateFrom Date From
     * @param dateTo Date To
     * @param take Take
     * @param skip Skip
     * @param token User Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTechniciansRequestsReport(dateFrom: string, dateTo: string, take: number, skip: number, token?: string, observe?: 'body', reportProgress?: boolean): Observable<TechniciansRequestsReportResponse>;
    public getTechniciansRequestsReport(dateFrom: string, dateTo: string, take: number, skip: number, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TechniciansRequestsReportResponse>>;
    public getTechniciansRequestsReport(dateFrom: string, dateTo: string, take: number, skip: number, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TechniciansRequestsReportResponse>>;
    public getTechniciansRequestsReport(dateFrom: string, dateTo: string, take: number, skip: number, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (dateFrom === null || dateFrom === undefined) {
            throw new Error('Required parameter dateFrom was null or undefined when calling getTechniciansRequestsReport.');
        }

        if (dateTo === null || dateTo === undefined) {
            throw new Error('Required parameter dateTo was null or undefined when calling getTechniciansRequestsReport.');
        }

        if (take === null || take === undefined) {
            throw new Error('Required parameter take was null or undefined when calling getTechniciansRequestsReport.');
        }

        if (skip === null || skip === undefined) {
            throw new Error('Required parameter skip was null or undefined when calling getTechniciansRequestsReport.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (dateFrom !== undefined && dateFrom !== null) {
            queryParameters = queryParameters.set('dateFrom', <any>dateFrom);
        }
        if (dateTo !== undefined && dateTo !== null) {
            queryParameters = queryParameters.set('dateTo', <any>dateTo);
        }
        if (take !== undefined && take !== null) {
            queryParameters = queryParameters.set('take', <any>take);
        }
        if (skip !== undefined && skip !== null) {
            queryParameters = queryParameters.set('skip', <any>skip);
        }

        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<TechniciansRequestsReportResponse>(`${this.basePath}/dashboard/getTechniciansRequestsReport`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve User Info By Id
     * 
     * @param userId User ID
     * @param token User Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUserById(userId: string, token?: string, observe?: 'body', reportProgress?: boolean): Observable<User>;
    public getUserById(userId: string, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<User>>;
    public getUserById(userId: string, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<User>>;
    public getUserById(userId: string, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling getUserById.');
        }


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<User>(`${this.basePath}/dashboard/user/${encodeURIComponent(String(userId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve Visit Verification Info By Order Id
     * 
     * @param orderId Order ID
     * @param token User Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVisitVerificationByOrderId(orderId: string, token?: string, observe?: 'body', reportProgress?: boolean): Observable<VisitVerification>;
    public getVisitVerificationByOrderId(orderId: string, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VisitVerification>>;
    public getVisitVerificationByOrderId(orderId: string, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VisitVerification>>;
    public getVisitVerificationByOrderId(orderId: string, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling getVisitVerificationByOrderId.');
        }


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<VisitVerification>(`${this.basePath}/dashboard/orderVerification/${encodeURIComponent(String(orderId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Import Reconciled Transactions
     * 
     * @param token User Access Token
     * @param sheet Reconciliation Sheet
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public importReconciledTransactions(token?: string, sheet?: Blob, observe?: 'body', reportProgress?: boolean): Observable<SuccessResponse>;
    public importReconciledTransactions(token?: string, sheet?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessResponse>>;
    public importReconciledTransactions(token?: string, sheet?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessResponse>>;
    public importReconciledTransactions(token?: string, sheet?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any); };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (sheet !== undefined) {
            formParams = formParams.append('sheet', <any>sheet) || formParams;
        }

        return this.httpClient.post<SuccessResponse>(`${this.basePath}/dashboard/fawry/cashin/import`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Check if user is authenticated
     * 
     * @param token User Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public isAuthenticated(token?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public isAuthenticated(token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public isAuthenticated(token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public isAuthenticated(token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.basePath}/dashboard/isAuthenticated`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get All Materials
     * 
     * @param token User Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listAllMaterials(token?: string, observe?: 'body', reportProgress?: boolean): Observable<MaterialsResponse>;
    public listAllMaterials(token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MaterialsResponse>>;
    public listAllMaterials(token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MaterialsResponse>>;
    public listAllMaterials(token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<MaterialsResponse>(`${this.basePath}/dashboard/listAllMaterials`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get All Technician Tools
     * 
     * @param token User Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listAllTechnicianTools(token?: string, observe?: 'body', reportProgress?: boolean): Observable<TechnicianToolsResponse>;
    public listAllTechnicianTools(token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TechnicianToolsResponse>>;
    public listAllTechnicianTools(token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TechnicianToolsResponse>>;
    public listAllTechnicianTools(token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<TechnicianToolsResponse>(`${this.basePath}/dashboard/listAllTechnicianTools`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Move Services
     * 
     * @param token User Access Token
     * @param moveServicesRequest Move Services Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public moveServices(token?: string, moveServicesRequest?: MoveServicesRequest, observe?: 'body', reportProgress?: boolean): Observable<ServicesResponse>;
    public moveServices(token?: string, moveServicesRequest?: MoveServicesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ServicesResponse>>;
    public moveServices(token?: string, moveServicesRequest?: MoveServicesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ServicesResponse>>;
    public moveServices(token?: string, moveServicesRequest?: MoveServicesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ServicesResponse>(`${this.basePath}/dashboard/moveServices`,
            moveServicesRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Prepare orders recommendation
     * 
     * @param token User Access Token
     * @param recommendationPreparingRequest Recommendation Preparing Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public prepareRecommendation(token?: string, recommendationPreparingRequest?: RecommendationPreparingRequest, observe?: 'body', reportProgress?: boolean): Observable<TechniciansCalendarSlotsResponse>;
    public prepareRecommendation(token?: string, recommendationPreparingRequest?: RecommendationPreparingRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TechniciansCalendarSlotsResponse>>;
    public prepareRecommendation(token?: string, recommendationPreparingRequest?: RecommendationPreparingRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TechniciansCalendarSlotsResponse>>;
    public prepareRecommendation(token?: string, recommendationPreparingRequest?: RecommendationPreparingRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<TechniciansCalendarSlotsResponse>(`${this.basePath}/dashboard/prepareRecommendation`,
            recommendationPreparingRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * rateOrderByCustomer
     * 
     * @param token User Access Token
     * @param rateOrderByCustomerRequest Rate Order By Customer Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public rateOrderByCustomer(token?: string, rateOrderByCustomerRequest?: RateOrderByCustomerRequest, observe?: 'body', reportProgress?: boolean): Observable<Order>;
    public rateOrderByCustomer(token?: string, rateOrderByCustomerRequest?: RateOrderByCustomerRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Order>>;
    public rateOrderByCustomer(token?: string, rateOrderByCustomerRequest?: RateOrderByCustomerRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Order>>;
    public rateOrderByCustomer(token?: string, rateOrderByCustomerRequest?: RateOrderByCustomerRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Order>(`${this.basePath}/dashboard/rateOrderByCustomer`,
            rateOrderByCustomerRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Rematch Order
     * 
     * @param token Technician Access Token
     * @param rematchOrderRequest Rematch Order Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public rematchOrder(token?: string, rematchOrderRequest?: RematchOrderRequest, observe?: 'body', reportProgress?: boolean): Observable<Order>;
    public rematchOrder(token?: string, rematchOrderRequest?: RematchOrderRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Order>>;
    public rematchOrder(token?: string, rematchOrderRequest?: RematchOrderRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Order>>;
    public rematchOrder(token?: string, rematchOrderRequest?: RematchOrderRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Order>(`${this.basePath}/dashboard/rematchOrder`,
            rematchOrderRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * User Reschedule Order
     * 
     * @param token User Access Token
     * @param rescheduleOrderRequest Reschedule Order Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public rescheduleAnOrder(token?: string, rescheduleOrderRequest?: RescheduleOrderRequest, observe?: 'body', reportProgress?: boolean): Observable<Order>;
    public rescheduleAnOrder(token?: string, rescheduleOrderRequest?: RescheduleOrderRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Order>>;
    public rescheduleAnOrder(token?: string, rescheduleOrderRequest?: RescheduleOrderRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Order>>;
    public rescheduleAnOrder(token?: string, rescheduleOrderRequest?: RescheduleOrderRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Order>(`${this.basePath}/dashboard/rescheduleOrder`,
            rescheduleOrderRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Reschedule Order From Completed
     * 
     * @param token User Access Token
     * @param forceUpdateOrderServicesRequest Order Force Update Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public rescheduleOrderFromCompleted(token?: string, forceUpdateOrderServicesRequest?: ForceUpdateOrderServicesRequest, observe?: 'body', reportProgress?: boolean): Observable<Order>;
    public rescheduleOrderFromCompleted(token?: string, forceUpdateOrderServicesRequest?: ForceUpdateOrderServicesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Order>>;
    public rescheduleOrderFromCompleted(token?: string, forceUpdateOrderServicesRequest?: ForceUpdateOrderServicesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Order>>;
    public rescheduleOrderFromCompleted(token?: string, forceUpdateOrderServicesRequest?: ForceUpdateOrderServicesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Order>(`${this.basePath}/dashboard/rescheduleOrderFromCompleted`,
            forceUpdateOrderServicesRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Set Order Accounting Acknowledged
     * 
     * @param orderId Order ID
     * @param token User Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setOrderAccountingAcknowledged(orderId: string, token?: string, observe?: 'body', reportProgress?: boolean): Observable<Order>;
    public setOrderAccountingAcknowledged(orderId: string, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Order>>;
    public setOrderAccountingAcknowledged(orderId: string, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Order>>;
    public setOrderAccountingAcknowledged(orderId: string, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling setOrderAccountingAcknowledged.');
        }


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<Order>(`${this.basePath}/dashboard/order/${encodeURIComponent(String(orderId))}/accountingAcknowledged`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Set Order Deal (Preventive/Emergency/Extra Visits)
     * 
     * @param token User Access Token
     * @param setBusinessOrderDealRequest Set Order Deal Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setOrderDeal(token?: string, setBusinessOrderDealRequest?: SetBusinessOrderDealRequest, observe?: 'body', reportProgress?: boolean): Observable<Order>;
    public setOrderDeal(token?: string, setBusinessOrderDealRequest?: SetBusinessOrderDealRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Order>>;
    public setOrderDeal(token?: string, setBusinessOrderDealRequest?: SetBusinessOrderDealRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Order>>;
    public setOrderDeal(token?: string, setBusinessOrderDealRequest?: SetBusinessOrderDealRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Order>(`${this.basePath}/dashboard/business/order/deal`,
            setBusinessOrderDealRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * User Settle Customer
     * 
     * @param token User Access Token
     * @param settleCustomerRequest Settle Customer Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public settleCustomer(token?: string, settleCustomerRequest?: SettleCustomerRequest, observe?: 'body', reportProgress?: boolean): Observable<SuccessResponse>;
    public settleCustomer(token?: string, settleCustomerRequest?: SettleCustomerRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessResponse>>;
    public settleCustomer(token?: string, settleCustomerRequest?: SettleCustomerRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessResponse>>;
    public settleCustomer(token?: string, settleCustomerRequest?: SettleCustomerRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<SuccessResponse>(`${this.basePath}/dashboard/settleCustomer`,
            settleCustomerRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * User Settle Technician
     * 
     * @param token User Access Token
     * @param settleTechnicianRequest Settle Technician Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public settleTechnician(token?: string, settleTechnicianRequest?: SettleTechnicianRequest, observe?: 'body', reportProgress?: boolean): Observable<SuccessResponse>;
    public settleTechnician(token?: string, settleTechnicianRequest?: SettleTechnicianRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessResponse>>;
    public settleTechnician(token?: string, settleTechnicianRequest?: SettleTechnicianRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessResponse>>;
    public settleTechnician(token?: string, settleTechnicianRequest?: SettleTechnicianRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<SuccessResponse>(`${this.basePath}/dashboard/settleTechnician`,
            settleTechnicianRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Settle Technician Orders
     * 
     * @param token User Access Token
     * @param settleTechnicianOrdersRequest Settle Technician Orders Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public settleTechnicianOrders(token?: string, settleTechnicianOrdersRequest?: SettleTechnicianOrdersRequest, observe?: 'body', reportProgress?: boolean): Observable<TechnicianUnsettledOrdersResponse>;
    public settleTechnicianOrders(token?: string, settleTechnicianOrdersRequest?: SettleTechnicianOrdersRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TechnicianUnsettledOrdersResponse>>;
    public settleTechnicianOrders(token?: string, settleTechnicianOrdersRequest?: SettleTechnicianOrdersRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TechnicianUnsettledOrdersResponse>>;
    public settleTechnicianOrders(token?: string, settleTechnicianOrdersRequest?: SettleTechnicianOrdersRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<TechnicianUnsettledOrdersResponse>(`${this.basePath}/dashboard/settleTechnicianOrders`,
            settleTechnicianOrdersRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Technician Start Order
     * 
     * @param token User Access Token
     * @param startOrderRequest Start Order Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public startOrderByTechnician(token?: string, startOrderRequest?: StartOrderRequest1, observe?: 'body', reportProgress?: boolean): Observable<SuccessResponse>;
    public startOrderByTechnician(token?: string, startOrderRequest?: StartOrderRequest1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuccessResponse>>;
    public startOrderByTechnician(token?: string, startOrderRequest?: StartOrderRequest1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuccessResponse>>;
    public startOrderByTechnician(token?: string, startOrderRequest?: StartOrderRequest1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<SuccessResponse>(`${this.basePath}/dashboard/startOrder`,
            startOrderRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Suspend Technician
     * 
     * @param token User Access Token
     * @param suspendTechnicianRequest Suspend Technician Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public suspendTechnician(token?: string, suspendTechnicianRequest?: SuspendTechnicianRequest, observe?: 'body', reportProgress?: boolean): Observable<Technician>;
    public suspendTechnician(token?: string, suspendTechnicianRequest?: SuspendTechnicianRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Technician>>;
    public suspendTechnician(token?: string, suspendTechnicianRequest?: SuspendTechnicianRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Technician>>;
    public suspendTechnician(token?: string, suspendTechnicianRequest?: SuspendTechnicianRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Technician>(`${this.basePath}/dashboard/suspendTechnician`,
            suspendTechnicianRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * swapOrders
     * 
     * @param token User Access Token
     * @param swappingOrdersRequest Swapping Orders Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public swapOrders(token?: string, swappingOrdersRequest?: SwappingOrdersRequest, observe?: 'body', reportProgress?: boolean): Observable<TechniciansCalendarSlotsResponse>;
    public swapOrders(token?: string, swappingOrdersRequest?: SwappingOrdersRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TechniciansCalendarSlotsResponse>>;
    public swapOrders(token?: string, swappingOrdersRequest?: SwappingOrdersRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TechniciansCalendarSlotsResponse>>;
    public swapOrders(token?: string, swappingOrdersRequest?: SwappingOrdersRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<TechniciansCalendarSlotsResponse>(`${this.basePath}/dashboard/orders/swap`,
            swappingOrdersRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Branch Info
     * 
     * @param token User Access Token
     * @param updateBusinessBranchRequest Update Branch Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateBranch(token?: string, updateBusinessBranchRequest?: UpdateBusinessBranchRequest, observe?: 'body', reportProgress?: boolean): Observable<BusinessBranch>;
    public updateBranch(token?: string, updateBusinessBranchRequest?: UpdateBusinessBranchRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BusinessBranch>>;
    public updateBranch(token?: string, updateBusinessBranchRequest?: UpdateBusinessBranchRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BusinessBranch>>;
    public updateBranch(token?: string, updateBusinessBranchRequest?: UpdateBusinessBranchRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<BusinessBranch>(`${this.basePath}/dashboard/updateBranch`,
            updateBusinessBranchRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Business Info
     * 
     * @param token User Access Token
     * @param updateBusinessRequest Update Business Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateBusiness(token?: string, updateBusinessRequest?: UpdateBusinessRequest, observe?: 'body', reportProgress?: boolean): Observable<Business>;
    public updateBusiness(token?: string, updateBusinessRequest?: UpdateBusinessRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Business>>;
    public updateBusiness(token?: string, updateBusinessRequest?: UpdateBusinessRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Business>>;
    public updateBusiness(token?: string, updateBusinessRequest?: UpdateBusinessRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Business>(`${this.basePath}/dashboard/updateBusiness`,
            updateBusinessRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Root Category
     * 
     * @param token User Access Token
     * @param updateRootCategoryRequest Update Root Category Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCategory(token?: string, updateRootCategoryRequest?: UpdateRootCategoryRequest, observe?: 'body', reportProgress?: boolean): Observable<Category>;
    public updateCategory(token?: string, updateRootCategoryRequest?: UpdateRootCategoryRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Category>>;
    public updateCategory(token?: string, updateRootCategoryRequest?: UpdateRootCategoryRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Category>>;
    public updateCategory(token?: string, updateRootCategoryRequest?: UpdateRootCategoryRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Category>(`${this.basePath}/dashboard/updateCategory`,
            updateRootCategoryRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Contract Info
     * 
     * @param token User Access Token
     * @param updateBusinessContractRequest Update Contract Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateContract(token?: string, updateBusinessContractRequest?: UpdateBusinessContractRequest, observe?: 'body', reportProgress?: boolean): Observable<BusinessContract>;
    public updateContract(token?: string, updateBusinessContractRequest?: UpdateBusinessContractRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BusinessContract>>;
    public updateContract(token?: string, updateBusinessContractRequest?: UpdateBusinessContractRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BusinessContract>>;
    public updateContract(token?: string, updateBusinessContractRequest?: UpdateBusinessContractRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<BusinessContract>(`${this.basePath}/dashboard/updateContract`,
            updateBusinessContractRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * update Coupon
     * 
     * @param token User Access Token
     * @param updateCouponRequest Update Coupon Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCoupon(token?: string, updateCouponRequest?: UpdateCouponRequest, observe?: 'body', reportProgress?: boolean): Observable<CouponResponse>;
    public updateCoupon(token?: string, updateCouponRequest?: UpdateCouponRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CouponResponse>>;
    public updateCoupon(token?: string, updateCouponRequest?: UpdateCouponRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CouponResponse>>;
    public updateCoupon(token?: string, updateCouponRequest?: UpdateCouponRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CouponResponse>(`${this.basePath}/dashboard/coupons`,
            updateCouponRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Customer Info
     * 
     * @param token User Access Token
     * @param updateCustomerRequest Update Customer Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCustomer(token?: string, updateCustomerRequest?: UpdateCustomerRequest, observe?: 'body', reportProgress?: boolean): Observable<Customer>;
    public updateCustomer(token?: string, updateCustomerRequest?: UpdateCustomerRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Customer>>;
    public updateCustomer(token?: string, updateCustomerRequest?: UpdateCustomerRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Customer>>;
    public updateCustomer(token?: string, updateCustomerRequest?: UpdateCustomerRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Customer>(`${this.basePath}/dashboard/updateCustomer`,
            updateCustomerRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Customer Address
     * 
     * @param token User Access Token
     * @param updateCustomerAddressRequest Update Customer Address Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCustomerAddress(token?: string, updateCustomerAddressRequest?: UpdateCustomerDetailedAddressRequest, observe?: 'body', reportProgress?: boolean): Observable<Customer>;
    public updateCustomerAddress(token?: string, updateCustomerAddressRequest?: UpdateCustomerDetailedAddressRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Customer>>;
    public updateCustomerAddress(token?: string, updateCustomerAddressRequest?: UpdateCustomerDetailedAddressRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Customer>>;
    public updateCustomerAddress(token?: string, updateCustomerAddressRequest?: UpdateCustomerDetailedAddressRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Customer>(`${this.basePath}/dashboard/customer/updateAddress`,
            updateCustomerAddressRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Customer Notes
     * 
     * @param token User Access Token
     * @param updateCustomerNotesRequest Update Customer Notes Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCustomerNotes(token?: string, updateCustomerNotesRequest?: UpdateCustomerNotesRequest, observe?: 'body', reportProgress?: boolean): Observable<Order>;
    public updateCustomerNotes(token?: string, updateCustomerNotesRequest?: UpdateCustomerNotesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Order>>;
    public updateCustomerNotes(token?: string, updateCustomerNotesRequest?: UpdateCustomerNotesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Order>>;
    public updateCustomerNotes(token?: string, updateCustomerNotesRequest?: UpdateCustomerNotesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Order>(`${this.basePath}/dashboard/updateCustomerNotes`,
            updateCustomerNotesRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Guarantee Services
     * 
     * @param token User Access Token
     * @param updateGuaranteeServicesRequest Update Guarantee Services Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateGuaranteeServices(token?: string, updateGuaranteeServicesRequest?: UpdateGuaranteeServicesRequest, observe?: 'body', reportProgress?: boolean): Observable<Order>;
    public updateGuaranteeServices(token?: string, updateGuaranteeServicesRequest?: UpdateGuaranteeServicesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Order>>;
    public updateGuaranteeServices(token?: string, updateGuaranteeServicesRequest?: UpdateGuaranteeServicesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Order>>;
    public updateGuaranteeServices(token?: string, updateGuaranteeServicesRequest?: UpdateGuaranteeServicesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Order>(`${this.basePath}/dashboard/updateGuaranteeServices`,
            updateGuaranteeServicesRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Opportunity
     * 
     * @param token User Access Token
     * @param updateOpportunityRequest Update Opportunity Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateOpportunity(token?: string, updateOpportunityRequest?: UpdateOpportunityRequest, observe?: 'body', reportProgress?: boolean): Observable<Opportunity>;
    public updateOpportunity(token?: string, updateOpportunityRequest?: UpdateOpportunityRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Opportunity>>;
    public updateOpportunity(token?: string, updateOpportunityRequest?: UpdateOpportunityRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Opportunity>>;
    public updateOpportunity(token?: string, updateOpportunityRequest?: UpdateOpportunityRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Opportunity>(`${this.basePath}/dashboard/updateOpportunity`,
            updateOpportunityRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateOrderDiscount
     * 
     * @param token User Access Token
     * @param updateOrderDiscountRequest Update Order Discount Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateOrderDiscount(token?: string, updateOrderDiscountRequest?: UpdateOrderDiscountRequest, observe?: 'body', reportProgress?: boolean): Observable<Order>;
    public updateOrderDiscount(token?: string, updateOrderDiscountRequest?: UpdateOrderDiscountRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Order>>;
    public updateOrderDiscount(token?: string, updateOrderDiscountRequest?: UpdateOrderDiscountRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Order>>;
    public updateOrderDiscount(token?: string, updateOrderDiscountRequest?: UpdateOrderDiscountRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Order>(`${this.basePath}/dashboard/updateOrderDiscount`,
            updateOrderDiscountRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Order Tags
     * 
     * @param token User Access Token
     * @param updateOrderTagsRequest Update Order Tags Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateOrderTags(token?: string, updateOrderTagsRequest?: UpdateOrderTagsRequest, observe?: 'body', reportProgress?: boolean): Observable<Order>;
    public updateOrderTags(token?: string, updateOrderTagsRequest?: UpdateOrderTagsRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Order>>;
    public updateOrderTags(token?: string, updateOrderTagsRequest?: UpdateOrderTagsRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Order>>;
    public updateOrderTags(token?: string, updateOrderTagsRequest?: UpdateOrderTagsRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Order>(`${this.basePath}/dashboard/updateOrderTags`,
            updateOrderTagsRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Role Info
     * 
     * @param token User Access Token
     * @param updateRoleRequest Update Role Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateRole(token?: string, updateRoleRequest?: UpdateRoleRequest, observe?: 'body', reportProgress?: boolean): Observable<Role>;
    public updateRole(token?: string, updateRoleRequest?: UpdateRoleRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Role>>;
    public updateRole(token?: string, updateRoleRequest?: UpdateRoleRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Role>>;
    public updateRole(token?: string, updateRoleRequest?: UpdateRoleRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Role>(`${this.basePath}/dashboard/updateRole`,
            updateRoleRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Service
     * 
     * @param token User Access Token
     * @param updateServiceRequest Update Service Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateService(token?: string, updateServiceRequest?: UpdateServiceRequest, observe?: 'body', reportProgress?: boolean): Observable<ServiceResponse>;
    public updateService(token?: string, updateServiceRequest?: UpdateServiceRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ServiceResponse>>;
    public updateService(token?: string, updateServiceRequest?: UpdateServiceRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ServiceResponse>>;
    public updateService(token?: string, updateServiceRequest?: UpdateServiceRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ServiceResponse>(`${this.basePath}/dashboard/updateService`,
            updateServiceRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update SubCategory
     * 
     * @param token User Access Token
     * @param updateSubCategoryRequest Update SubCategory Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateSubCategory(token?: string, updateSubCategoryRequest?: UpdateSubCategoryRequest, observe?: 'body', reportProgress?: boolean): Observable<SubCategory>;
    public updateSubCategory(token?: string, updateSubCategoryRequest?: UpdateSubCategoryRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SubCategory>>;
    public updateSubCategory(token?: string, updateSubCategoryRequest?: UpdateSubCategoryRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SubCategory>>;
    public updateSubCategory(token?: string, updateSubCategoryRequest?: UpdateSubCategoryRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<SubCategory>(`${this.basePath}/dashboard/updateSubCategory`,
            updateSubCategoryRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Technician Info
     * 
     * @param token User Access Token
     * @param updateTechnicianRequest Update Technician Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateTechnician(token?: string, updateTechnicianRequest?: UpdateTechnicianRequest, observe?: 'body', reportProgress?: boolean): Observable<Technician>;
    public updateTechnician(token?: string, updateTechnicianRequest?: UpdateTechnicianRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Technician>>;
    public updateTechnician(token?: string, updateTechnicianRequest?: UpdateTechnicianRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Technician>>;
    public updateTechnician(token?: string, updateTechnicianRequest?: UpdateTechnicianRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Technician>(`${this.basePath}/dashboard/updateTechnician`,
            updateTechnicianRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Technician Comment
     * 
     * @param token User Access Token
     * @param updateTechnicianCommentRequest Update Technician Comment Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateTechnicianComment(token?: string, updateTechnicianCommentRequest?: UpdateTechnicianCommentRequest, observe?: 'body', reportProgress?: boolean): Observable<Order>;
    public updateTechnicianComment(token?: string, updateTechnicianCommentRequest?: UpdateTechnicianCommentRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Order>>;
    public updateTechnicianComment(token?: string, updateTechnicianCommentRequest?: UpdateTechnicianCommentRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Order>>;
    public updateTechnicianComment(token?: string, updateTechnicianCommentRequest?: UpdateTechnicianCommentRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Order>(`${this.basePath}/dashboard/updateTechnicianComment`,
            updateTechnicianCommentRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update User Info
     * 
     * @param token User Access Token
     * @param updateUserRequest Update User Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateUser(token?: string, updateUserRequest?: UpdateUserRequest, observe?: 'body', reportProgress?: boolean): Observable<User>;
    public updateUser(token?: string, updateUserRequest?: UpdateUserRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<User>>;
    public updateUser(token?: string, updateUserRequest?: UpdateUserRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<User>>;
    public updateUser(token?: string, updateUserRequest?: UpdateUserRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<User>(`${this.basePath}/dashboard/updateUser`,
            updateUserRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Image
     * 
     * @param token User Access Token
     * @param type Image Type
     * @param image Image
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uploadImage(token?: string, type?: 'technician-profile' | 'categories' | 'services', image?: Blob, observe?: 'body', reportProgress?: boolean): Observable<UploadSuccessResponse>;
    public uploadImage(token?: string, type?: 'technician-profile' | 'categories' | 'services', image?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UploadSuccessResponse>>;
    public uploadImage(token?: string, type?: 'technician-profile' | 'categories' | 'services', image?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UploadSuccessResponse>>;
    public uploadImage(token?: string, type?: 'technician-profile' | 'categories' | 'services', image?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }
        if (type !== undefined && type !== null) {
            headers = headers.set('type', String(type));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (image !== undefined) {
            // formParams = formParams.append('image', <any>image) || formParams;
        }

        return this.httpClient.post<UploadSuccessResponse>(`${this.basePath}/dashboard/image/upload`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * User get calculated expected paid amount for current visit
     * 
     * @param token User Access Token
     * @param calculateVisitExpectedPaidAmountRequest Calculate Visit Expected Paid Amount Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userCalculateVisitExpectedPaidAmount(token?: string, calculateVisitExpectedPaidAmountRequest?: CalculateVisitExpectedPaidAmountRequest, observe?: 'body', reportProgress?: boolean): Observable<CalculateVisitExpectedPaidAmountResponse>;
    public userCalculateVisitExpectedPaidAmount(token?: string, calculateVisitExpectedPaidAmountRequest?: CalculateVisitExpectedPaidAmountRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CalculateVisitExpectedPaidAmountResponse>>;
    public userCalculateVisitExpectedPaidAmount(token?: string, calculateVisitExpectedPaidAmountRequest?: CalculateVisitExpectedPaidAmountRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CalculateVisitExpectedPaidAmountResponse>>;
    public userCalculateVisitExpectedPaidAmount(token?: string, calculateVisitExpectedPaidAmountRequest?: CalculateVisitExpectedPaidAmountRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CalculateVisitExpectedPaidAmountResponse>(`${this.basePath}/dashboard/calculateExpectedPaidAmount`,
            calculateVisitExpectedPaidAmountRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Logs user into dashboard
     * 
     * @param userCredentials User Credentials
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userLogin(userCredentials?: UserCredentials, observe?: 'body', reportProgress?: boolean): Observable<UserLoginResponse>;
    public userLogin(userCredentials?: UserCredentials, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserLoginResponse>>;
    public userLogin(userCredentials?: UserCredentials, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserLoginResponse>>;
    public userLogin(userCredentials?: UserCredentials, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<UserLoginResponse>(`${this.basePath}/dashboard/login`,
            userCredentials,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * User Update Order Services
     * 
     * @param token User Access Token
     * @param updateOrderServicesRequest User Update Order Services Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userUpdateOrderServices(token?: string, updateOrderServicesRequest?: UpdateOrderServicesRequest, observe?: 'body', reportProgress?: boolean): Observable<Order>;
    public userUpdateOrderServices(token?: string, updateOrderServicesRequest?: UpdateOrderServicesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Order>>;
    public userUpdateOrderServices(token?: string, updateOrderServicesRequest?: UpdateOrderServicesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Order>>;
    public userUpdateOrderServices(token?: string, updateOrderServicesRequest?: UpdateOrderServicesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Order>(`${this.basePath}/dashboard/updateOrderServices`,
            updateOrderServicesRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Verify Customer
     * 
     * @param mobile Mobile
     * @param token User Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public verifyCustomerByMobile(mobile: string, token?: string, observe?: 'body', reportProgress?: boolean): Observable<Customer>;
    public verifyCustomerByMobile(mobile: string, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Customer>>;
    public verifyCustomerByMobile(mobile: string, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Customer>>;
    public verifyCustomerByMobile(mobile: string, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (mobile === null || mobile === undefined) {
            throw new Error('Required parameter mobile was null or undefined when calling verifyCustomerByMobile.');
        }


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<Customer>(`${this.basePath}/dashboard/customer/verify/${encodeURIComponent(String(mobile))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve Calendar View
     * 
     * @param token User Access Token
     * @param filterCalendarRequest Filter Calendar Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public viewCalendar(token?: string, filterCalendarRequest?: FilterCalendarRequest, observe?: 'body', reportProgress?: boolean): Observable<TechniciansCalendarSlotsResponse>;
    public viewCalendar(token?: string, filterCalendarRequest?: FilterCalendarRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TechniciansCalendarSlotsResponse>>;
    public viewCalendar(token?: string, filterCalendarRequest?: FilterCalendarRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TechniciansCalendarSlotsResponse>>;
    public viewCalendar(token?: string, filterCalendarRequest?: FilterCalendarRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<TechniciansCalendarSlotsResponse>(`${this.basePath}/dashboard/calendar`,
            filterCalendarRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve Order Info By Id
     * 
     * @param orderId Order ID
     * @param token User Access Token
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public viewOrderById(orderId: string, token?: string, observe?: 'body', reportProgress?: boolean): Observable<Order>;
    public viewOrderById(orderId: string, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Order>>;
    public viewOrderById(orderId: string, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Order>>;
    public viewOrderById(orderId: string, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling viewOrderById.');
        }


        let headers = this.defaultHeaders;
        if (token !== undefined && token !== null) {
            headers = headers.set('token', String(token));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Order>(`${this.basePath}/dashboard/order/${encodeURIComponent(String(orderId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
