import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';


@Pipe({ name: 'fkdDate' })
export class DatePipe implements PipeTransform {
  transform(value: string, format: string): string {
    moment.locale(localStorage.getItem('Filkhedma-LocalStore.key.user-language'));
    if (value.length !== 0) {
      if (moment.tz(value, 'UTC').tz('Africa/Cairo').format(format) !== 'Invalid date') {
        return moment.tz(value, 'UTC').tz('Africa/Cairo').format(format);
      } else {
        return '-';
      }
    } else {
      return '-';
    }
  }
}

@Pipe({ name: 'fkdDateAdd' })
export class DateAddPipe implements PipeTransform {
  transform(value: string, format: string, by: string, add: Number): string {
    moment.locale(localStorage.getItem('Filkhedma-LocalStore.key.user-language'));
    if (value.length !== 0) {
      if (moment.tz(value, 'UTC').tz('Africa/Cairo').format(format) !== 'Invalid date') {
        return moment.tz(value, 'UTC').tz('Africa/Cairo').add(by, add).format(format);
      } else {
        return '-';
      }
    } else {
      return '-';
    }
  }
}

