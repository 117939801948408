import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';



if (environment.envName === "prod") {
  // for google analytics prod
  // document.write('<script async src="https://www.googletagmanager.com/gtag/js?id=UA-55043718-1"></script>');
  const googleAnalyticsScript = document.createElement('script');
  googleAnalyticsScript.innerHTML = `
      // prod
      (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
        (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
        })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
        
        // ga('create', 'UA-120632985-2', 'auto');
        // ga('send', 'pageview');
  `;

  

  document.head.appendChild(googleAnalyticsScript);
  enableProdMode();

} else {
  // for google analytics dev
  // document.write('<script async src="https://www.googletagmanager.com/gtag/js?id=UA-120632985-2"></script>');
  const googleAnalyticsScript = document.createElement('script');
  googleAnalyticsScript.innerHTML = `
      // dev
      (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
        (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
        })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
        
        // ga('create', 'UA-120632985-2', 'auto');
        // ga('send', 'pageview');
  `;
  document.head.appendChild(googleAnalyticsScript);

  // for mixpanel dev
  // const mixpanelScript = document.createElement('script');
  // mixpanelScript.innerHTML = `
  //     // start Mixpanel
  //     (function(e,a){if(!a.__SV){var b=window;try{var c,l,i,j=b.location,g=j.hash;c=function(a,b){return(l=a.match(RegExp(b+"=([^&]*)")))?l[1]:null};g&&c(g,"state")&&(i=JSON.parse(decodeURIComponent(c(g,"state"))),"mpeditor"===i.action&&(b.sessionStorage.setItem("_mpcehash",g),history.replaceState(i.desiredHash||"",e.title,j.pathname+j.search)))}catch(m){}var k,h;window.mixpanel=a;a._i=[];a.init=function(b,c,f){function e(b,a){var c=a.split(".");2==c.length&&(b=b[c[0]],a=c[1]);b[a]=function(){b.push([a].concat(Array.prototype.slice.call(arguments,
  //       0)))}}var d=a;"undefined"!==typeof f?d=a[f]=[]:f="mixpanel";d.people=d.people||[];d.toString=function(b){var a="mixpanel";"mixpanel"!==f&&(a+="."+f);b||(a+=" (stub)");return a};d.people.toString=function(){return d.toString(1)+".people (stub)"};k="disable time_event track track_pageview track_links track_forms register register_once alias unregister identify name_tag set_config reset opt_in_tracking opt_out_tracking has_opted_in_tracking has_opted_out_tracking clear_opt_in_out_tracking people.set people.set_once people.unset people.increment people.append people.union people.track_charge people.clear_charges people.delete_user".split(" ");
  //       for(h=0;h<k.length;h++)e(d,k[h]);a._i.push([b,c,f])};a.__SV=1.2;b=e.createElement("script");b.type="text/javascript";b.async=!0;b.src="undefined"!==typeof MIXPANEL_CUSTOM_LIB_URL?MIXPANEL_CUSTOM_LIB_URL:"file:"===e.location.protocol&&"//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js".match(/^\/\//)?"https://cdn.mxpnl.com/libs/mixpanel-2-latest.min.js":"//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js";c=e.getElementsByTagName("script")[0];c.parentNode.insertBefore(b,c)}})(document,window.mixpanel||[]);
  //       mixpanel.init("5f17d7ee03a0f1d9f0215665972a4f0a");
  //     // end Mixpanel
  // `;
  // document.body.appendChild(mixpanelScript);
}

var gtmKey = environment.google_gtm_key;
var gtmAuth = environment.google_gtm_auth;
var gtmEnv = environment.google_gtm_env;
const gtmHeadScript = document.createElement('script');
const gtmBodyScript = document.createElement('noscript');

gtmHeadScript.innerHTML = `
  <!-- Google Tag Manager -->
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=`+gtmAuth+`&gtm_preview=`+gtmEnv+`&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','`+gtmKey+`');
<!-- End Google Tag Manager -->
  `;
gtmBodyScript.innerHTML = `
  <!-- Google Tag Manager (noscript) -->
<iframe src="https://www.googletagmanager.com/ns.html?id=`+gtmKey+`&gtm_auth=`+gtmAuth+`&gtm_preview=`+gtmEnv+`&gtm_cookies_win=x"
height="0" width="0" style="display:none;visibility:hidden"></iframe>
<!-- End Google Tag Manager (noscript) -->
  `;
  
document.head.appendChild(gtmHeadScript);
document.body.appendChild(gtmBodyScript);

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch();
