/**
 * Filkhedma API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Technician } from './technician';
import { User } from './user';
import { VisitDoneService } from './visitDoneService';
import { VisitPricing } from './visitPricing';


export interface VisitVerification { 
    verificationId?: string;
    orderId?: string;
    visitId?: string;
    technician?: Technician;
    doneServices?: Array<VisitDoneService>;
    pricing?: VisitPricing;
    visitType?: VisitVerification.VisitTypeEnum;
    postAction?: VisitVerification.PostActionEnum;
    usedMaterials?: string;
    technicianComment?: string;
    isVerified?: boolean;
    verifiedBy?: User;
    changes?: VisitVerification;
    createdAt?: string;
    updatedAt?: string;
}
export namespace VisitVerification {
    export type VisitTypeEnum = 'inspection' | 'maintenance';
    export const VisitTypeEnum = {
        Inspection: 'inspection' as VisitTypeEnum,
        Maintenance: 'maintenance' as VisitTypeEnum
    };
    export type PostActionEnum = 'finalize' | 'reschedule';
    export const PostActionEnum = {
        Finalize: 'finalize' as PostActionEnum,
        Reschedule: 'reschedule' as PostActionEnum
    };
}
