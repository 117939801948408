import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { routeConfig } from '../../../shared/utilities/pages-config';

@Component({
  selector: 'app-edit-profile-page',
  templateUrl: './edit-profile-page.component.html',
  styleUrls: ['./edit-profile-page.component.css']
})
export class EditProfilePageComponent implements OnInit {

  constructor(
    private location: Location,
    private router: Router,
  ) { }

  ngOnInit() {
  }

  close(event) {
    this.router.navigate([routeConfig.home.route]);
  }

  openOtherPage(event) {
    this.router.navigate([routeConfig.verify_mobile.route]);
  }

}
