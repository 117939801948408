/** default date and time format for end points */
export const GLOBAL_DATE_TIME_FORMATE = 'YYYY-MM-DD HH:mm:ss';

/** default date format for end points */
export const GLOBAL_DATE_FORMATE = 'YYYY-MM-DD';

/** default time format for end points */
export const GLOBAL_TIME_FORMATE_HOUR = 'HH';

/** default Date Month format */
export const GLOBAL_DAY_MONTH_FORMATE = 'MM-DD';

/** default Date minute format */
export const GLOBAL_TIME_FORMATE_MINUTE = 'mm';
