/**
 * Filkhedma API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { OrderPricing } from './orderPricing';
import { OrderService } from './orderService';
import { User } from './user';
import { Visit } from './visit';


export interface NewOrderUpdateRecordRequest { 
    type?: NewOrderUpdateRecordRequest.TypeEnum;
    orderId?: string;
    actor?: User;
    responsible?: NewOrderUpdateRecordRequest.ResponsibleEnum;
    reason?: string;
    oldServices?: Array<OrderService>;
    newServices?: Array<OrderService>;
    oldPricing?: OrderPricing;
    newPricing?: OrderPricing;
    oldVisit?: Visit;
    newVisit?: Visit;
    oldStatus?: string;
    newStatus?: string;
}
export namespace NewOrderUpdateRecordRequest {
    export type TypeEnum = 'visit' | 'order_services';
    export const TypeEnum = {
        Visit: 'visit' as TypeEnum,
        OrderServices: 'order_services' as TypeEnum
    };
    export type ResponsibleEnum = 'operations' | 'customer_care' | 'technician';
    export const ResponsibleEnum = {
        Operations: 'operations' as ResponsibleEnum,
        CustomerCare: 'customer_care' as ResponsibleEnum,
        Technician: 'technician' as ResponsibleEnum
    };
}
