import { Injectable, Optional, Inject, InjectionToken } from "@angular/core";
import { Subject } from "rxjs";

declare const ga: any;

export const GA_TOKEN = new InjectionToken("angular-ga TrackingId");
export const GA_OPTIONS = new InjectionToken("angular-ga Tracking Options");

export interface Event {
  category: string;
  action: string;
  label?: string;
  value?: number;
}

export interface PageView {
  page: string;
  title?: string;
}

export interface TrackingOptions {
  name?: string;
  storage?: string;
  clientId?: string;
  sampleRate?: number;
  siteSpeedSampleRate?: number;
  alwaysSendReferrer?: boolean;
  allowAnchor?: boolean;
  cookieName?: string;
  cookieDomain?: string;
  cookieExpires?: number;
  legacyCookieDomain?: string;
  legacyHistoryImport?: boolean;
  allowLinker?: boolean;
}

@Injectable()
export class GoogoleAnalyticsService {
  private event = new Subject<Event>();
  private pageView = new Subject<PageView>();
  constructor(
    @Optional()
    @Inject(GA_TOKEN)
    trackingId: string,
    @Optional()
    @Inject(GA_OPTIONS)
    options: TrackingOptions
  ) {
    if (trackingId) {
      if (options) {
        ga("create", trackingId, options);
      } else {
        ga("create", trackingId, "auto");
      }
      // ga("send", "pageview");
    }
    this.event.subscribe(event => {
      this._sendEvent(event);
    });
    this.pageView.subscribe(pageView => {
      this._sendPageView(pageView);
    });
  }

  sendEvent(event: Event) {
    this.event.next(event);
  }

  private _sendEvent(event: Event): any {
    ga("send", "event", event.category, event.action, event.label, event.value);
  }

  sendPageView(pageView: PageView) {
    this.pageView.next(pageView);
  }

  private _sendPageView(pageView: PageView): any {
    const fieldsObject: any = {};

    if (pageView.title) {
      fieldsObject.title = pageView.title;
    }

    ga("send", "pageview", pageView.page, pageView.title);
  }
}
