/**
 * Filkhedma API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ForceUpdateOrderService } from './forceUpdateOrderService';


export interface ForceUpdateOrderServicesRequest { 
    orderId?: string;
    servicesIds?: Array<ForceUpdateOrderService>;
    responsible?: ForceUpdateOrderServicesRequest.ResponsibleEnum;
    reason?: string;
    type?: string;
    servicesChecksum?: string;
}
export namespace ForceUpdateOrderServicesRequest {
    export type ResponsibleEnum = 'operations' | 'customer_care' | 'technician' | 'customer';
    export const ResponsibleEnum = {
        Operations: 'operations' as ResponsibleEnum,
        CustomerCare: 'customer_care' as ResponsibleEnum,
        Technician: 'technician' as ResponsibleEnum,
        Customer: 'customer' as ResponsibleEnum
    };
}
