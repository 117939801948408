import { Component, OnInit, forwardRef, Input, EventEmitter, Output } from '@angular/core';
import {
  FormControl, AbstractControl, ValidationErrors, NG_VALUE_ACCESSOR,
  NG_VALIDATORS, ControlValueAccessor, Validator
} from '@angular/forms';

@Component({
  selector: 'fkd-checkbox',
  templateUrl: './fkd-checkbox.component.html',
  styleUrls: ['./fkd-checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FkdCheckboxComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FkdCheckboxComponent),
      multi: true
    }

  ]
})
export class FkdCheckboxComponent implements OnInit, ControlValueAccessor, Validator {

  /** Input indicator if checkbox is required or not */
  @Input() required: boolean;

  /** Input Id */
  @Input() id = '';

  /** Input Name */
  @Input() name = '';

  /** Emit on Checkbox change */
  @Output() changed = new EventEmitter<boolean>();

  /** True if item is Checked */
  @Input() isChecked: boolean;

  /** is disabled */
  @Input() disabled = false;

  /** True if item is Checked */
  @Input()
  set setCheck(v: boolean) {
    this.value = v;
    this.isChecked = v;
  }
  /** to hold the inner value for the input */
  innerValue: boolean;

  /** is checkbox has circular style */
  @Input() circular: boolean = false;

  /** function template */
  private onTouchedCallback: () => {};

  /** function template */
  private onChangeCallback = (_: any) => { };

  /** get accessor including */
  get value(): any {
    return this.innerValue;
  }

  /** set accessor including call the onChange callback */
  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  /** function template */
  public validateFn: any = () => { };

  /**
   * first function will fire in the component life cycle
   * @param helperService Responsible for any functions for utilities purpose
   */
  constructor() { }

  checkboxChange() {
    this.changed.emit(this.value);
  }

  ngOnInit() {
    this.value = this.isChecked;
    this.validateFn = (c: FormControl) => {
      if (this.required) {
        this.value = c.value ? c.value : null;
        return c.value === null ? false : true;
      } else {
        return false;
      }
    }
  }


  //#region implementing ControlValueAccessor

  /**
   * this function form implementing ControlValueAccessor
   * responsible for Writes a new value to the element.
   * @param obj
   */
  writeValue(obj: any): void {
    this.innerValue = obj;
  }

  /**
   * this function form implementing ControlValueAccessor
   * @param fn
   */
  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  /**
   * this function form implementing ControlValueAccessor
   * @param fn
   */
  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  //#endregion

  //#region implementing ControlValueAccessor

  /**
   * this function form implementing Validator
   * to validate the input based on our criteria
   * @param c
   */
  validate(c: AbstractControl): ValidationErrors {
    return this.validateFn(c);
  }

  //#endregion
}
