import { AuthenticationService } from './../../shared/services/authenication/authentication.service';
import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { ToastrConfig } from "ngx-toastr";

@Injectable()
export class AnonymousGuard implements CanActivate {
  toastConfig: Partial<ToastrConfig> = {
    timeOut: 3000
  };

  constructor(private authenticationService: AuthenticationService) {}

  canActivate(): boolean {
    return this.checkAnonymous();
  }

  checkAnonymous(): boolean {
    if (this.authenticationService.isAuthenticated) {
      return false;
    }
    return true;
  }
}
