/**
 * Filkhedma API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { UpdateOrderService } from './updateOrderService';


export interface NewOrder { 
    type?: NewOrder.TypeEnum;
    customerId?: string;
    categoryId?: string;
    areaId?: string;
    subareaId?: string;
    serviceIds?: Array<UpdateOrderService>;
    addressId?: string;
    tagsIds?: Array<string>;
    orderTime?: string;
    /**
     * Order Discount Percentage
     */
    discountPercentage?: number;
    /**
     * Order Discount Up To Amount
     */
    discountUpto?: number;
    /**
     * Order Coupon ID
     */
    couponId?: string;
    customerNotes?: string;
}
export namespace NewOrder {
    export type TypeEnum = 'individual' | 'business';
    export const TypeEnum = {
        Individual: 'individual' as TypeEnum,
        Business: 'business' as TypeEnum
    };
}
