/**
 * Filkhedma API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ServiceCost } from './serviceCost';


export interface CategoryChildsResponse { 
    /**
     * Category / Service ID
     */
    id?: string;
    /**
     * Root Category ID
     */
    rootCategoryId?: string;
    /**
     * Category / Sub Category ID
     */
    categoryId?: string;
    /**
     * Service ID
     */
    serviceId?: string;
    /**
     * Type ( Service / Category )
     */
    type?: CategoryChildsResponse.TypeEnum;
    /**
     * Service English Name
     */
    nameEn?: string;
    /**
     * Service Arabic Name
     */
    nameAr?: string;
    /**
     * Service Customer English Name
     */
    customerNameEn?: string;
    /**
     * Service Customer Arabic Name
     */
    customerNameAr?: string;
    /**
     * DescriptionAr
     */
    descriptionAr?: string;
    /**
     * DescriptionEn
     */
    descriptionEn?: string;
    /**
     * Active Image
     */
    activeImage?: string;
    /**
     * Inactive Image
     */
    inactiveImage?: string;
    /**
     * Service Cost
     */
    cost?: ServiceCost;
    /**
     * Sub Category Childs
     */
    childs?: Array<CategoryChildsResponse>;
}
export namespace CategoryChildsResponse {
    export type TypeEnum = 'service' | 'category';
    export const TypeEnum = {
        Service: 'service' as TypeEnum,
        Category: 'category' as TypeEnum
    };
}
