import { CustomerService } from './../../swagger/api/customer.service';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { VerifyMobileRequest } from '../../swagger/model/verifyMobileRequest';
import { Customer } from '../../swagger';

export interface VerificatinDataModel {
    mobile?: string;
    verificationId?: string;
    type?: string;
}
@Injectable()
export class UserService {

    private verificationData: VerificatinDataModel;
    forgetPasswordStep = 'first';
    forgetPasswordData: VerifyMobileRequest;
    private messageSubject = new Subject<any>();
    customer: Customer;
    registerByFaceBook = false;

    constructor(
        private customerService: CustomerService,
    ) {
    }

    setCustomer(customer: Customer) {
        this.customer = customer;
    }

    resetCustomer() {
        delete this.customer;
    }


    // message
    sendMessage(message) {
        this.messageSubject.next({ text: message });
    }

    clearMessage() {
        this.messageSubject.next();
    }

    getMessage(): Observable<any> {
        return this.messageSubject.asObservable();
    }


    // data
    setVerificationData(data: VerificatinDataModel) {
        this.verificationData = data;
    }

    getVerificationData(): VerificatinDataModel {
        return this.verificationData;
    }

    removeVerificationData() {
        delete this.verificationData;
    }

    // for forgetPassword
    setForgetPasswordData(data: VerifyMobileRequest) {
        this.forgetPasswordData = data;
    }

    getForgetPasswordData(): VerifyMobileRequest {
        return this.forgetPasswordData;
    }

    removeForgetPasswordData() {
        delete this.forgetPasswordData;
    }

}
