/**
 * Filkhedma API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface NewBusinessOrderRequest { 
    branchId?: string;
    businessId?: string;
    categoryId?: string;
    orderTime?: string;
    /**
     * Order Deal ( Emergency | Preventive | Extra )
     */
    orderDeal?: NewBusinessOrderRequest.OrderDealEnum;
}
export namespace NewBusinessOrderRequest {
    export type OrderDealEnum = 'preventive' | 'emergency' | 'extra';
    export const OrderDealEnum = {
        Preventive: 'preventive' as OrderDealEnum,
        Emergency: 'emergency' as OrderDealEnum,
        Extra: 'extra' as OrderDealEnum
    };
}
