/**
 * Filkhedma API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface FilterTechniciansRequest { 
    keyword?: string;
    type?: FilterTechniciansRequest.TypeEnum;
    areaIds?: Array<string>;
    categoryIds?: Array<string>;
    statuses?: Array<string>;
    accountStatuses?: Array<string>;
    sort?: string;
    dir?: string;
    take?: number;
    skip?: number;
}
export namespace FilterTechniciansRequest {
    export type TypeEnum = 'inhouse' | 'marketplace';
    export const TypeEnum = {
        Inhouse: 'inhouse' as TypeEnum,
        Marketplace: 'marketplace' as TypeEnum
    };
}
