import { Injectable, } from '@angular/core';
import { Subject, Observable } from 'rxjs';


export class EventModel {
    eventName: string;
    eventData: any;

    constructor(eventName: string, eventData: any) {
        this.eventName = eventName;
        this.eventData = eventData;
    }
}

export enum EventType {
    LoggedIn = 'CustomerLoggedIn',
    Registered = 'CustomerRegistered',
    Contact = 'CustomerSentContactMessage',
    Checkout = 'CustomerCheckout',
    APIRequest = 'API Request'
}

@Injectable()
export class EventsService {
    private messageSubject = new Subject<EventModel>();
    // message
    publish(eventType: EventType, eventData: any) {
        const eventModel = new EventModel(eventType.toString(), eventData);
        this.messageSubject.next(eventModel);
    }

    clearMessage() {
        this.messageSubject.next();
    }

    getObservable(): Observable<EventModel> {
        return this.messageSubject.asObservable();
    }
}
