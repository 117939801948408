import { AuthenticationService } from './../../../shared/services/authenication/authentication.service';
import { Router } from '@angular/router';
import { USER_LANGUAGE_DIRECTION, TOKEN } from './../../../shared/utilities/defines/index';
import { StorageService } from './../../../shared/services/storage/storage.service';
import { RemoveOtherMobileRequest } from './../../../swagger/model/removeOtherMobileRequest';
import { Subscription } from 'rxjs';
import { UserService } from './../../services/user.service';
import { MobileVerificationRequest } from './../../../swagger/model/mobileVerificationRequest';
import { ChangePasswordRequest } from './../../../swagger/model/changePasswordRequest';
import { UpdateCustomerProfileRequest } from './../../../swagger/model/updateCustomerProfileRequest';
import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrConfig, ToastrService } from 'ngx-toastr';
import { CustomerService } from '../../../swagger/api/customer.service';
import { Customer } from '../../../swagger/model/customer';
import { MixpanelService } from '../../../shared/services/mixpanel/mixpanel.service';
import { TranslateService } from '@ngx-translate/core';
import { ERROR_TYPES } from '../../../shared/utilities/defines/app-types';
import { routeConfig } from '../../../shared/utilities/pages-config';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';

interface UpdateProfileFormModel {
  firstName?: string;
  lastName?: string;
  gender?: boolean;
  email?: string;
}

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit, OnDestroy {

  @Output() done = new EventEmitter();
  @Output() openOtherPage = new EventEmitter();
  hasPassword = true;
  basicProfileErrorMessage: string;
  changePasswordErrorMessage: string;
  changeMobileErrorMessage: string;
  exstraMobileErrorMessaga: string;
  canEditBasicData = true;
  canChangeMobile = true;
  canChangePassword = true;
  canAddOtherMobile = true;
  updateProfileForm: FormGroup;
  updateMobileForm: FormGroup;
  changePasswordForm: FormGroup;
  extraMobilesForm: FormGroup;
  firstName: FormControl;
  lastName: FormControl;
  email: FormControl;
  gender: FormControl;
  mobile: FormControl;
  oldPassword: FormControl;
  newPassword: FormControl;
  reNewPassword: FormControl;
  extraMobile: FormControl;
  customer: Customer;
  subscription: Subscription;
  canVerifyEmail = true;

  toastConfig: Partial<ToastrConfig> = {
    timeOut: 3000,
  };
  dir: string;
  constructor(
    private customerService: CustomerService,
    private toastr: ToastrService,
    private userService: UserService,
    private mixpanelService: MixpanelService,
    private storageService: StorageService,
    private translationService: TranslateService,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
    this.mixpanelService.trackEvent('Customer Open Edit Profile Page', { pageName: 'Edit Profile' }).subscribe((params) => { });
  }

  ngOnInit() {
    this.getProfileData();
    this.dir = this.storageService.getStorage(USER_LANGUAGE_DIRECTION);
    this.firstName = new FormControl('',
      Validators.compose([Validators.required, Validators.minLength(2),
      // tslint:disable-next-line:quotemark
      Validators.pattern("[^\\`\\~\\!\\@\\#\\$\\%\\^\\&\\*\\(\\)\\+\\=\\[\\{\\]\\}\\|\\\\\\'\\<\\>\\?\\/\\''\\;\\:]{2,30}")]));
    this.lastName = new FormControl('',
      Validators.compose([Validators.required, Validators.minLength(2),
      // tslint:disable-next-line:quotemark
      Validators.pattern("[^\\`\\~\\!\\@\\#\\$\\%\\^\\&\\*\\(\\)\\+\\=\\[\\{\\]\\}\\|\\\\\\'\\<\\>\\?\\/\\''\\;\\:]{2,30}")]));
    this.email = new FormControl('',
      Validators.compose([Validators.required, Validators.pattern("^(?:[^!~@#%\\s\\$\\^&\\*\\(\\)\\\/\\\\\\{\\}\\[\\]\\+:;,<>]{1,50})([\\.-]?[^!~@#%\\s\\$\\^&\\*\\(\\)\\\/\\\\\\{\\}\\[\\]\\+:;,<>]{1,50})?([\\+]{1}?[^!~@#%\\s\\$\\^&\\*\\(\\)\\\/\\\\\\{\\}\\[\\]\\+:;,<>]{1,50})?@[^!~@#%\\s\\$\\^&\\*\\(\\)\\\/\\\\\\{\\}\\[\\]\\+:;,<>]([\\.-]?[^!~@#%\\s\\$\\^&\\*\\(\\)\\\/\\\\\\{\\}\\[\\]\\+:;,<>]{1,50})(\\.[^!~@#%\\s\\$\\^&\\*\\(\\)\\\/\\\\\\{\\}\\[\\]\\+:;,<>]{2,3})$")]));
    this.gender = new FormControl(false);

    this.updateProfileForm = new FormGroup({
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      gender: this.gender,
    });

    this.mobile = new FormControl('',
      [Validators.required, Validators.pattern('^01([0-9]{9})')]);

    this.updateMobileForm = new FormGroup({
      mobile: this.mobile,
    });

    this.oldPassword = new FormControl('',
      [Validators.required, Validators.minLength(8)]);
    this.newPassword = new FormControl('',
      [Validators.required, Validators.minLength(8)]);
    this.reNewPassword = new FormControl('',
      [Validators.required, Validators.minLength(8)]);

    this.changePasswordForm = new FormGroup({
      oldPassword: this.oldPassword,
      newPassword: this.newPassword,
      reNewPassword: this.reNewPassword,
    });

    this.extraMobile = new FormControl('',
      [Validators.required, Validators.pattern('^01([0-9]{9})')]);

    this.extraMobilesForm = new FormGroup({
      extraMobile: this.extraMobile,
    });


    this.subscription = this.userService.getMessage().subscribe(
      message => {
        this.setCustomer(message.text);
      }
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  setCustomer(customer: Customer) {
    this.customer = customer;
  }

  close() {
    this.done.emit('done');
  }

  setForm(userData: Customer) {
    this.firstName.setValue(userData.firstName);
    this.lastName.setValue(userData.lastName);
    this.email.setValue(userData.email);
    this.gender.setValue(userData.gender === UpdateCustomerProfileRequest.GenderEnum.Female ? true : false);
    this.mobile.setValue(userData.mobile);
  }

  getProfileData() {
    const token = this.storageService.getStorage(TOKEN);
    this.customerService.getCustomerProfile(token).subscribe(
      res => {
        this.setForm(res);
        this.customer = res;
        this.hasPassword = res.hasPassword;
      },
      err => {
      },
    );
  }

  updateProfileData(formData: UpdateProfileFormModel) {
    this.canEditBasicData = false;
    this.basicProfileErrorMessage = undefined;
    const token = this.storageService.getStorage(TOKEN);
    const updateCustomerProfileReq: UpdateCustomerProfileRequest = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      gender: formData.gender ? UpdateCustomerProfileRequest.GenderEnum.Female : UpdateCustomerProfileRequest.GenderEnum.Male,
      email: formData.email,
    };
    this.customerService.updateProfile(token, updateCustomerProfileReq).subscribe(
      res => {
        this.customer = res;
        this.updateProfileForm.markAsPristine();
        this.toastr.success(this.translationService.instant('date_has_been_successfully_modified'), '', this.toastConfig);
        this.canEditBasicData = true;
      },
      err => {
        this.canEditBasicData = true;
        if (err.error.errors) {
          err.error.errors.forEach(element => {
            if (element.field === 'mobile' && element.error === ERROR_TYPES.ALREADY_EXISTS) {
              this.basicProfileErrorMessage = this.translationService.instant('phone_number_is_registar');
            } else if (element.field === 'email' && element.error === ERROR_TYPES.ALREADY_EXISTS) {
              this.basicProfileErrorMessage = this.translationService.instant('email_is_registar');
            }
          });
        } else if (err.error.msg) {
          if (err.error.msg === ERROR_TYPES.MOBILE_ALREADY_EXISTED) {
            this.basicProfileErrorMessage = this.translationService.instant('phone_number_is_registar');
          } else if (err.error.msg === ERROR_TYPES.EMIAL_ALREADY_EXISTED) {
            this.basicProfileErrorMessage = this.translationService.instant('email_is_registar');
          }
        } else {
          this.toastr.error(this.translationService.instant('try_again_later'),
            this.translationService.instant('an_error_occurred'), this.toastConfig);
        }
      }
    );
  }



  changePassword(formData) {
    this.changePasswordErrorMessage = undefined;
    this.canChangePassword = false;
    const token = this.storageService.getStorage(TOKEN);
    const changePasswordReq: ChangePasswordRequest = {
      oldPassword: formData.oldPassword,
      newPassword: formData.newPassword,
      confirmPassword: formData.reNewPassword,
    };
    this.customerService.changePassword(token, changePasswordReq).subscribe(
      res => {
        this.canChangePassword = true;
        this.toastr.success(this.translationService.instant('changePasswordByMailSuccess'), '', this.toastConfig);
        this.changePasswordForm.reset();
        this.close();
        this.router.navigate([routeConfig.home.route]);
      },
      err => {
        this.canChangePassword = true;
        if (err.error.msg === ERROR_TYPES.INVALID_AUTH) {
          this.changePasswordErrorMessage = this.translationService.instant('old_password_is_wrong');
        }
      },
    );
  }

  handleChangePasswordError(error) {

  }

  upadteMobile(formData) {
    this.changeMobileErrorMessage = undefined;
    this.canChangeMobile = false;
    const token = this.storageService.getStorage(TOKEN);
    const mobileVerificationReq: MobileVerificationRequest = {
      mobile: formData.mobile,
    };
    this.customerService.requestMobileVerificationForChange(token, mobileVerificationReq).subscribe(
      res => {
        this.canChangeMobile = true;
        this.userService.setVerificationData({ mobile: formData.mobile, verificationId: res.verificationId, type: 'mobile_to_change' });
        this.close();
        this.router.navigate([routeConfig.verify_mobile.route]);

      },
      err => {
        this.canChangeMobile = true;
        if (err.error.errors) {
          err.error.errors.forEach(element => {
            if (element.field === 'mobile' && element.error === ERROR_TYPES.ALREADY_EXISTS) {
              this.changeMobileErrorMessage = this.translationService.instant('phone_number_is_registar');
            } else if (element.field === 'email' && element.error === ERROR_TYPES.ALREADY_EXISTS) {
              this.changeMobileErrorMessage = this.translationService.instant('email_is_registar');
            }
          });
        } else if (err.error.msg) {
          if (err.error.msg === ERROR_TYPES.MOBILE_ALREADY_EXISTED) {
            this.changeMobileErrorMessage = this.translationService.instant('phone_number_is_registar');
          } else if (err.error.msg === ERROR_TYPES.EMIAL_ALREADY_EXISTED) {
            this.changeMobileErrorMessage = this.translationService.instant('email_is_registar');
          }
        }
      },
    );
  }

  addExstraMobile(formData) {
    this.canAddOtherMobile = false;
    this.exstraMobileErrorMessaga = undefined;
    const token = this.storageService.getStorage(TOKEN);
    const mobileVerificationReq: MobileVerificationRequest = {
      mobile: formData.extraMobile,
    };
    this.customerService.requestMobileVerificationForOtherMobile(token, mobileVerificationReq).subscribe(
      res => {
        this.canAddOtherMobile = true;
        this.userService.setVerificationData({ mobile: formData.extraMobile, verificationId: res.verificationId, type: 'other_mobile' });
        this.close();
        this.router.navigate([routeConfig.verify_mobile.route]);
      },
      err => {
        this.canAddOtherMobile = true;
        if (err.errors) {
          err.errors.forEach(element => {
            if (element.field === 'mobile' && element.error === ERROR_TYPES.ALREADY_EXISTS) {
              this.exstraMobileErrorMessaga = this.translationService.instant('phone_number_is_registar');
            } else if (element.field === 'email' && element.error === ERROR_TYPES.ALREADY_EXISTS) {
              this.exstraMobileErrorMessaga = this.translationService.instant('email_is_registar');
            }
          });
        } else if (err.error.msg) {
          if (err.error.msg === ERROR_TYPES.MOBILE_ALREADY_EXISTED) {
            this.exstraMobileErrorMessaga = this.translationService.instant('phone_number_is_registar');
          } else if (err.error.msg === ERROR_TYPES.EMIAL_ALREADY_EXISTED) {
            this.exstraMobileErrorMessaga = this.translationService.instant('email_is_registar');
          }
        } else {
          this.toastr.error(this.translationService.instant('try_again_later'),
            this.translationService.instant('an_error_occurred'), this.toastConfig);
        }
      },
    );
  }


  removeFromOtherMobiles(mobile) {
    const token = this.storageService.getStorage(TOKEN);
    const removeOtherMobileReq: RemoveOtherMobileRequest = {
      mobile: mobile,
    };
    this.customerService.removeOtherMobile(token, removeOtherMobileReq).subscribe(
      res => {
        this.customer = res;
      },
      err => {
        this.toastr.error(this.translationService.instant('try_again_later'),
          this.translationService.instant('an_error_occurred'), this.toastConfig);
      },
    );
  }

  requestEmailVerification() {
    this.canVerifyEmail = false;
    const toastConfig: Partial<ToastrConfig> = {
      timeOut: 6000,
    };
    const token = this.storageService.getStorage(TOKEN);
    this.customerService.requestEmailVerification(token).subscribe(
      res => {
        this.toastr.success(this.translationService.instant('activation_email_sent'));
        this.canVerifyEmail = true;
      },
      err => {
        this.canVerifyEmail = true;
        this.toastr.error(this.translationService.instant('try_again_later'),
          this.translationService.instant('an_error_occurred'), this.toastConfig);
      }
    );
  }

}
