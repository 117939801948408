import { AuthenticationService } from './../../shared/services/authenication/authentication.service';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ToastrConfig } from 'ngx-toastr';
import { routeConfig } from '../../shared/utilities/pages-config';

@Injectable()
export class LandingPageGuard implements CanActivate {
  toastConfig: Partial<ToastrConfig> = {
    timeOut: 3000,
  };

  constructor (
    private authenticationService: AuthenticationService,
    private router: Router,
    ) {}

  canActivate() {
    return this.checkAnonymous();
  }

  checkAnonymous () {
    if (this.authenticationService.isAuthenticated) {
        return true;
      } else {
        this.router.navigate([routeConfig.services.route]);
        return false;
    }
  }
}
