import { Input, Component } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent {

  @Input() fontSize;
  @Input() color;
  @Input() btnSize;

  getFontSize() {
    if (this.fontSize) {
      return `${this.fontSize}px`;
    } else {
      return '';
    }
  }

  getColor() {
    if (this.color) {
      return this.color;
    } else {
      return '';
    }
  }

  getBtnSize() {
    if (this.btnSize) {
      return `btn-loader-${this.btnSize}`;
    } else {
      return '';
    }
  }

}
