/**
 * Filkhedma API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface IgnoreOrderRequest { 
    /**
     * Order ID
     */
    orderId: string;
    /**
     * Matching Type
     */
    matchingType?: IgnoreOrderRequest.MatchingTypeEnum;
    /**
     * Matching Cycle ID
     */
    matchingCycleId: string;
    /**
     * Ignored By
     */
    ignoredBy: IgnoreOrderRequest.IgnoredByEnum;
}
export namespace IgnoreOrderRequest {
    export type MatchingTypeEnum = 'online' | 'offline';
    export const MatchingTypeEnum = {
        Online: 'online' as MatchingTypeEnum,
        Offline: 'offline' as MatchingTypeEnum
    };
    export type IgnoredByEnum = 'technician' | 'queue' | 'delayed_reject' | 'delayed_accept';
    export const IgnoredByEnum = {
        Technician: 'technician' as IgnoredByEnum,
        Queue: 'queue' as IgnoredByEnum,
        DelayedReject: 'delayed_reject' as IgnoredByEnum,
        DelayedAccept: 'delayed_accept' as IgnoredByEnum
    };
}
