import { environment } from './../../../../environments/environment.prod';
import { Injectable } from '@angular/core';
import mixpanel from 'mixpanel-browser/src/loader-module';
import { Subject } from 'rxjs';


@Injectable()
export class MixpanelService {

    constructor() {
        if (environment.envName === 'prod') {
            mixpanel.init('1c4387252a78484bf6b6e98afe284d75');
        } else {
            mixpanel.init('11f3d2672e24ac0ee560d6375a977f25');
        }
    }

    trackEvent(eventName: string, props?: any) {
        const res = new Subject();
        mixpanel.track(eventName, props, (...params) => {
            res.next(params);
        });
        return res.asObservable();
    }

    identify(customerId: string) {
        mixpanel.identify(customerId);
        localStorage.setItem('mixpanel-is-identified', "true");
    }

    alias(customerId: string) {
        if (this.isIdentified()) {
            this.reset();
        }
        mixpanel.alias(customerId);
        localStorage.setItem('mixpanel-is-identified', "true");
    }

    reset() {
        mixpanel.reset();
        localStorage.setItem('mixpanel-is-identified', "false");
    }

    isIdentified() {
        return (localStorage.getItem('mixpanel-is-identified') === "true");
    }
}
