/**
 * Filkhedma API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Actor } from './actor';
import { OrderHistoryItemData } from './orderHistoryItemData';
import { Technician } from './technician';


export interface OrderHistory { 
    /**
     * Technician
     */
    technician?: Technician;
    /**
     * Order Status
     */
    status?: string;
    /**
     * Order Status
     */
    matchingType?: OrderHistory.MatchingTypeEnum;
    /**
     * Order Action Time
     */
    time?: string;
    /**
     * Order Visit ID
     */
    visitId?: string;
    data?: OrderHistoryItemData;
    /**
     * Actor Type
     */
    actorType?: OrderHistory.ActorTypeEnum;
    /**
     * Actor
     */
    actor?: Actor;
}
export namespace OrderHistory {
    export type MatchingTypeEnum = 'online' | 'offline';
    export const MatchingTypeEnum = {
        Online: 'online' as MatchingTypeEnum,
        Offline: 'offline' as MatchingTypeEnum
    };
    export type ActorTypeEnum = 'technician' | 'customer' | 'user' | 'system';
    export const ActorTypeEnum = {
        Technician: 'technician' as ActorTypeEnum,
        Customer: 'customer' as ActorTypeEnum,
        User: 'user' as ActorTypeEnum,
        System: 'system' as ActorTypeEnum
    };
}
