import { LanguageService } from './../../../helpers/translate service/language.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { routeConfig } from '../../../shared/utilities/pages-config';

@Component({
  selector: 'aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.css']
})
export class AboutusComponent implements OnInit {

  constructor(
    public languageService: LanguageService,
    private route: Router) { }

  ngOnInit() {
  }
  navigateToService() {
    this.route.navigate([routeConfig.services.route]);
  }
  navigateToContactUs() {
    this.route.navigate([routeConfig.contact_us.route]);
  }
}
