import { TranslateService } from '@ngx-translate/core';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fkdPluralTranslate'
})
export class PluralTranslatePipe implements PipeTransform {

  constructor(private translateService: TranslateService) { }

  transform(value: number, singularKey: string, pluralKey: string): string {
    if (value > 1) {
      return this.translateService.instant(`${pluralKey}`);
    } else {
      return this.translateService.instant(`${singularKey}`);
    }
  }
}
