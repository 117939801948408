import { Router } from '@angular/router';
import { UserService } from './../../user/services/user.service';
import { StorageService } from './../../shared/services/storage/storage.service';
import { TOKEN } from './../../shared/utilities/defines/index';
import { LanguageService } from './../../helpers/translate service/language.service';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ToastrConfig, ToastrService } from 'ngx-toastr';
import { CustomerService } from '../../swagger/api/customer.service';
import { MobileVerificationRequest } from '../../swagger/model/mobileVerificationRequest';
import { ResetPasswordRequest } from '../../swagger/model/resetPasswordRequest';
import { ForgotPasswordByEmailRequest } from '../../swagger/model/forgotPasswordByEmailRequest';
import { TranslateService } from '@ngx-translate/core';
import { AUTH_TYPE, ERROR_TYPES } from '../../shared/utilities/defines/app-types';
import { AuthenticationService } from '../../shared/services/authenication/authentication.service';
import { routeConfig } from '../../shared/utilities/pages-config';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
@Component({
  selector: 'forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {
  public verificationId: string;
  public form: FormGroup;
  public changePasswordForm: FormGroup;
  public toastConfig: Partial<ToastrConfig> = {
    timeOut: 3000,
  };
  public isLoading = false;
  public errorMessage: string;
  public newPasswordSent = false;
  public forgetPasswordErrorMessage: string;
  public anonymousToken: string;
  constructor(
    public userService: UserService,
    private toastr: ToastrService,
    private customerService: CustomerService,
    private storageService: StorageService,
    private authenticationService: AuthenticationService,
    private translationService: TranslateService,
    public languageService: LanguageService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.anonymousToken = this.storageService.getStorage(TOKEN);
    this.loadForgetPasswordForm();
    this.loadChangePasswordForm();
  }

  loadForgetPasswordForm() {
    this.form = this.formBuilder.group({
      emailOrMobile: '',
    });
  }
  loadChangePasswordForm() {
    this.changePasswordForm = this.formBuilder.group({
      password: '',
      rePassword: ['', this.rePassword]
    });
  }

  rePassword(c: FormControl) {
    if (c === undefined || c.parent === undefined || c.value === '') { return; }
    const lastPassword = c.parent.controls['rePassword'].value;
    if (c.value !== '' && c.parent.controls['password'].value === lastPassword) {
      return null;
    } else {
      return { lastPassword: true };
    }
  }
  validateFormValue(formData) {
    if ((/^01([0-9]{9})/).test(this.form.controls['emailOrMobile'].value)) {
      this.sendMobile();
    } else {
      this.sendEmail();
    }
  }
  sendMobile() {
    this.isLoading = true;
    const mobileVerificationReq: MobileVerificationRequest = {
      mobile: this.form.controls['emailOrMobile'].value,
    };
    this.customerService.requestMobileVerificationForResetPassword(mobileVerificationReq).subscribe(
      res => {
        this.isLoading = false;
        this.verificationId = res.verificationId;
        this.userService.setVerificationData({
          mobile: this.form.controls['emailOrMobile'].value,
          verificationId: res.verificationId, type: 'mobile'
        });
        this.router.navigate([routeConfig.verify_mobile.route]);
      },
      err => {
        this.isLoading = false;
        if (err.error.msg === ERROR_TYPES.CUSTOMER_NOT_FOUND) {
          this.forgetPasswordErrorMessage = this.translationService.instant('phone_not_registar', this.toastConfig);
        }
      },
    );
  }

  sendEmail() {
    this.isLoading = true;
    const forgotPasswordByEmailReq: ForgotPasswordByEmailRequest = {
      email: this.form.controls['emailOrMobile'].value,
    };
    this.customerService.forgotPasswordByEmail(this.anonymousToken, forgotPasswordByEmailReq).subscribe(
      res => {
        this.toastr.success(this.translationService.instant('the_email_has_been_send'), '', this.toastConfig);

        this.forgetPasswordErrorMessage = '';
        this.router.navigate([routeConfig.home.route]);
        this.isLoading = false;
      },
      err => {
        this.isLoading = false;
        if (err.error.msg === ERROR_TYPES.CUSTOMER_NOT_FOUND) {
          this.forgetPasswordErrorMessage = this.translationService.instant('email_not_registar');
        } else if (err.error.msg === ERROR_TYPES.EMAIL_NOT_VERIFIED) {
          this.forgetPasswordErrorMessage = this.translationService.instant('email_not_activated');
        }
      },
    );
  }

  changePassword() {
    this.isLoading = true;
    const verifiedData = this.userService.getForgetPasswordData();
    const resetPasswordReq: ResetPasswordRequest = {
      verificationId: verifiedData.verificationId,
      verificationCode: verifiedData.verificationCode,
      newPassword: this.changePasswordForm.controls['password'].value,
      confirmPassword: this.changePasswordForm.controls['rePassword'].value,
    };
    this.customerService.resetPassword(this.anonymousToken, resetPasswordReq).subscribe(
      res => {
        this.isLoading = false;
        this.toastr.success(this.translationService.instant('changePasswordByMailSuccess'),
          this.translationService.instant('done_successfully'), this.toastConfig);
        this.userService.forgetPasswordStep = 'first';
        this.authenticationService.login(res, AUTH_TYPE.Login);
        this.toastr.success(this.translationService.instant('registration_done_successfully'),
          `${this.translationService.instant('hello')} ${res.customerFirstName}`, this.toastConfig);
      },
      err => {
        this.isLoading = false;
        if (err.error.msg === ERROR_TYPES.MOBILE_VERFICATION_EXPIRED) {
          this.errorMessage = this.translationService.instant('code_has_expired');
          this.userService.forgetPasswordStep = 'first';
        }
      }
    );
  }
}
